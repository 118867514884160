import { IBusinessData } from 'domains/reports/types';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as messageListActions from 'store/actions/messageList';
import * as reportActions from 'store/actions/report';
import * as toolbarActions from 'store/actions/toolbar';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import * as toolbarSelectors from 'store/selectors/toolbar';
import * as domainSelectors from 'store/selectors/domains';
import State from 'types/state';
import { AttributionQueryBuilder } from './AttributionQueryBuilder.component';
import { IConnectedState } from './types';

const mapStateToProps = (state: State): IConnectedState => ({
  availableDimensions: businessDataSelectors.getAvailableDimensions(state),
  availableRulesDimensions:
    businessDataSelectors.getAvailableRulesDimensions(state),
  availableTargets:
    businessDataSelectors.getAttributionReportAvailableTargets(state),
  businessData: get('businessData', state) as IBusinessData, // cambialo coojones
  exposureFlightDates: reportSelectors.getExposureAdsFlightDates(state),
  eqMetric: reportSelectors.getEqMetric(state),
  outcomeViewershipDates: reportSelectors.getOutcomeViewershipDates(state),
  report: reportSelectors.getReport(state),
  subType: reportSelectors.getAttributionReportSubType(state),
  toolbar: toolbarSelectors.getToolbar(state),
  messageList: messageListSelectors.getMessageList(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  domainsDateRanges: domainSelectors.getDomainsDateRanges(state),
});

const mapDispatchToProps = {
  ...toolbarActions,
  setExposureAdFiltersAction: reportActions.setExposureAdFiltersAction,
  addExposureAdFiltersAction: reportActions.addExposureAdFiltersAction,
  replaceExposureAdFiltersAction: reportActions.replaceExposureAdFiltersAction,
  deleteExposureAdFiltersAction: reportActions.deleteExposureAdFiltersAction,
  setQueryModeAction: reportActions.setQueryModeAction,
  setQueryTargets: reportActions.setQueryTargets,
  setReportQueryDatasetsAction: reportActions.setReportQueryDatasetsAction,
  addOutcomeViewershipFiltersAction:
    reportActions.addOutcomeViewershipFiltersAction,
  replaceOutcomeViewershipFiltersAction:
    reportActions.replaceOutcomeViewershipFiltersAction,
  deleteOutcomeViewershipFiltersAction:
    reportActions.deleteOutcomeViewershipFiltersAction,
  addOutcomeBrandLiftFiltersAction:
    reportActions.addOutcomeBrandLiftFiltersAction,
  replaceOutcomeBrandLiftFiltersAction:
    reportActions.replaceOutcomeBrandLiftFiltersAction,
  deleteOutcomeBrandLiftFiltersAction:
    reportActions.deleteOutcomeBrandLiftFiltersAction,
  handleAttrReportsAdFlightDateChange:
    reportActions.handleAttrReportsAdFlightDateChange,
  handleAttrReportsViewershipDetailsDateChange:
    reportActions.handleAttrReportsViewershipDetailsDateChange,
  handleChangeScheduleDataset: reportActions.handleChangeScheduleDataset,
  handleChangeGenericEventsDataset:
    reportActions.handleChangeGenericEventsDataset,
  handleQueryBuilderEqMetricChange:
    reportActions.handleQueryBuilderEqMetricChange,
  handleQueryBuilderTargetChange: reportActions.handleQueryBuilderTargetChange,
  addWarningMessage: messageListActions.addMessage,
  deleteWarningMessage: messageListActions.deleteMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttributionQueryBuilder);
