import {
  getDay,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  isLeapYear,
  addMonths,
  sub,
  startOfWeek,
  endOfWeek,
  getMonth,
  addYears,
  subDays,
} from 'date-fns';
import { IButtonMetadata } from './types';

export const getBroadcastWeek: IButtonMetadata['getRange'] = (date: Date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });

  return { start, end };
};

export const getBroadcastMonth: IButtonMetadata['getRange'] = (date: Date) => {
  const startMonth = startOfMonth(date);
  const endMonth = endOfMonth(date);

  const { start } = getBroadcastWeek(startMonth);
  const end = sub(endMonth, { days: getDay(endMonth) });

  return { start, end };
};

export const getBroadcastQuarter: IButtonMetadata['getRange'] = (
  date: Date,
) => {
  const { end: endweek } = getBroadcastWeek(date);
  const { start } = getBroadcastWeek(startOfQuarter(endweek));
  const { end } = isLeapYear(date)
    ? getBroadcastMonth(subDays(addMonths(start, 3), 1))
    : getBroadcastMonth(addMonths(start, 3));

  return { start, end };
};

export const getBroadcastSeason: IButtonMetadata['getRange'] = (date: Date) => {
  let { end: dateEndWeek } = getBroadcastWeek(date);
  const month = getMonth(dateEndWeek);

  const lastOctober = sub(dateEndWeek, {
    months: month + (month >= 9 ? -9 : 3),
  });

  const { start } = getBroadcastMonth(lastOctober);

  const nextSeptember = addYears(start, 1);

  const { end } = getBroadcastMonth(nextSeptember);

  return { start, end };
};
