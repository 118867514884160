import { IBusinessData } from 'domains/reports/types';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';
import { ReportSettingsForm } from './ReportSettingsForm.component';
import { IOwnProps } from './types';

const mapStateToProps = (
  state: State,
): Pick<IOwnProps, 'report' | 'settings' | 'businessData'> => ({
  report: reportSelectors.getReport(state),
  settings: reportSelectors.getSettings(state),
  businessData: get('businessData', state) as IBusinessData,
});

const mapDispatchToProps = reportActions;
export default connect(mapStateToProps, mapDispatchToProps)(ReportSettingsForm);
