import { sub, subQuarters } from 'date-fns';
import {
  getBroadcastMonth,
  getBroadcastSeason,
  getBroadcastWeek,
  getBroadcastQuarter,
} from './smart-selection';
import { IButtons } from './types';

export const BUTTONS: IButtons = {
  LAST_DAY_WITH_DATA: {
    text: 'Last Day with Data',
    getRange(date: Date) {
      return { start: date, end: date };
    },
  },
  LAST_FULL_BROADCAST_WEEK: {
    text: 'Last Full Broadcast Week',
    getRange(date: Date) {
      return getBroadcastWeek(sub(date, { weeks: 1 }));
    },
  },
  LAST_FULL_BROADCAST_MONTH: {
    text: 'Last Full Broadcast Month',
    getRange(date: Date) {
      return getBroadcastMonth(sub(date, { months: 1 }));
    },
  },
  LAST_FULL_BROADCAST_QUARTER: {
    text: 'Last Full Broadcast Quarter',
    getRange(date: Date) {
      return getBroadcastQuarter(subQuarters(date, 1));
    },
  },
  LAST_FULL_BROADCAST_YEAR: {
    text: 'Last Full Broadcast Season',
    getRange(date: Date) {
      return getBroadcastSeason(sub(date, { years: 1 }));
    },
  },
  BROADCAST_WEEK_TO_DATE: {
    text: 'Broadcast Week to Date',
    getRange(date: Date) {
      const { start } = getBroadcastWeek(date);
      return { start, end: date };
    },
  },
  BROADCAST_MONTH_TO_DATE: {
    text: 'Broadcast Month to Date',
    getRange(date: Date) {
      const { start } = getBroadcastMonth(date);
      return { start, end: date };
    },
  },
  BROADCAST_QUARTER_TO_DATE: {
    text: 'Broadcast Quarter to Date',
    getRange(date: Date) {
      const { start } = getBroadcastQuarter(date);
      return { start, end: date };
    },
  },
  BROADCAST_SEASON_TO_DATE: {
    text: 'Broadcast Season to Date',
    getRange(date: Date) {
      const { start } = getBroadcastSeason(date);
      return { start, end: date };
    },
  },
};
