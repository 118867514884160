enum ActionType {
  // Domains
  DOMAINS_INIT = 'DOMAINS_INIT',
  DOMAINS_SET_ATTRIBUTION_REPORTS = 'DOMAINS_SET_ATTRIBUTION_REPORTS',
  DOMAINS_SET_CLIENTS = 'DOMAINS_SET_CLIENTS',
  DOMAINS_SET_DASHBOARDS = 'DOMAINS_SET_DASHBOARDS',
  DOMAINS_SET_DATASETS = 'DOMAINS_SET_DATASETS',
  DOMAINS_SET_DIMENSIONS = 'DOMAINS_SET_DIMENSIONS',
  DOMAINS_SET_METRICS = 'DOMAINS_SET_METRICS',
  DOMAINS_SET_PERMISSIONS = 'DOMAINS_SET_PERMISSIONS',
  DOMAINS_SET_DATE_RANGES = 'DOMAINS_SET_DATE_RANGES',

  // Result
  RESULT_RETRIEVED = 'RESULT_RETRIEVED',
  RESULT_TARGET_RETRIEVED = 'RESULT_TARGET_RETRIEVED',

  // Report result
  RESULT_ATTRIBUTION_REPORT_RETRIEVED = 'RESULT_ATTRIBUTION_REPORT_RETRIEVED',
  RESULT_ATTRIBUTION_REPORT_DEMOGRAPHICS_RETRIEVED = 'RESULT_ATTRIBUTION_REPORT_DEMOGRAPHICS_RETRIEVED',
  RESULT_ATTRIBUTION_REPORT_MISC_RETRIEVED = 'RESULT_ATTRIBUTION_REPORT_MISC_RETRIEVED',
  RESULT_SET_ATTRIBUTION_REPORT_ERROR_MESSAGE = 'RESULT_SET_ATTRIBUTION_REPORT_ERROR_MESSAGE',
  RESULT_ATTRIBUTION_SET_VENDOR_SOURCE_NAME = 'RESULT_ATTRIBUTION_SET_VENDOR_SOURCE_NAME',
  RESULT_ATTRIBUTION_REPORT_SET_CONVERSION_TYPE = 'RESULT_ATTRIBUTION_REPORT_SET_CONVERSION_TYPE',
  RESULT_ATTRIBUTION_SEGMENTS_SAVE_STATUS_UPDATED = 'RESULT_ATTRIBUTION_SEGMENTS_SAVE_STATUS_UPDATED',
  RESULT_ATTRIBUTION_SEGMENTS_SAVE_SET_FILENAMES = 'RESULT_ATTRIBUTION_SEGMENTS_SAVE_SET_FILENAMES',
  RESULT_ATTRIBUTION_SEGMENTS_SAVE_RESET_FILENAMES = 'RESULT_ATTRIBUTION_SEGMENTS_SAVE_RESET_FILENAMES',
  RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_CHART = 'RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_CHART',
  RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET_OPTIONS = 'RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET_OPTIONS',
  RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET = 'RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET',
  RESULT_SET_DISPLAY_CHART_DATA = 'RESULT_SET_DISPLAY_CHART_DATA',
  RESULT_SET_SHOW_LROI = 'RESULT_SET_SHOW_LROI',
  RESULT_SET_DOWNLOAD_QUERY = 'RESULT_SET_DOWNLOAD_QUERY',
  RESULT_SET_FILE_UPLOAD = 'RESULT_SET_FILE_UPLOAD',
  RESULT_SET_DATE_RANGE = 'RESULT_SET_DATE_RANGE',
  RESULT_SET_SGM_CONFIG = 'RESULT_SET_SGM_CONFIG',
  RESULT_RESET_RESULT = 'RESULT_RESET_RESULT',
  RESULT_RESET_TARGET_RESULT = 'RESULT_RESET_TARGET_RESULT',
  RESULT_SET_TARGET_RESULT_EXPORT_CONFIG_OPTIONS = 'RESULT_SET_TARGET_RESULT_EXPORT_CONFIG_OPTIONS',
  RESULT_RESET_ATTRIBUTION_REPORT_RESULT = 'RESULT_RESET_ATTRIBUTION_REPORT_RESULT',
  RESULT_SET_EXECUTED_REPORT = 'RESULT_SET_EXECUTED_REPORT',
  RESULT_SET_EXECUTED_REPORT_CANCELLING = 'RESULT_SET_EXECUTED_REPORT_CANCELLING',
  RESULT_SET_EXECUTED_REPORT_STARTED = 'RESULT_SET_EXECUTED_REPORT_STARTED',
  RESULT_SET_EXECUTED_REPORT_FINISHED = 'RESULT_SET_EXECUTED_REPORT_FINISHED',
  RESULT_SET_EXECUTED_REPORT_CANCELLED = 'RESULT_SET_EXECUTED_REPORT_CANCELLED',
  RESULT_SET_ATTRIBUTION_OMISSION_MESSAGES = 'RESULT_SET_ATTRIBUTION_OMISSION_MESSAGES',
  RESULT_SET_ATTRIBUTION_REPORT_METRICS = 'RESULT_SET_ATTRIBUTION_REPORT_METRICS',
  RESULT_SET_BREAKOUT_LIST = 'RESULT_SET_BREAKOUT_LIST',
  RESULT_SET_DEMOGRAPHIC_BREAKOUT_LIST = 'RESULT_SET_DEMOGRAPHIC_BREAKOUT_LIST',

  // Root
  ROOT_CLONE_REPORT = 'ROOT_CLONE_REPORT',
  ROOT_CREATE_REPORT = 'ROOT_CREATE_REPORT',
  ROOT_DELETE_REPORT = 'ROOT_DELETE_REPORT',
  ROOT_EDIT_REPORT = 'ROOT_EDIT_REPORT',
  ROOT_SET_CONFIG = 'ROOT_SET_CONFIG',
  ROOT_SET_LOADED = 'ROOT_SET_LOADED',
  ROOT_SET_LOADING = 'ROOT_SET_LOADING',
  ROOT_SET_ATTRIBUTION_REPORT_LIST = 'ROOT_SET_ATTRIBUTION_REPORT_LIST',
  ROOT_SET_REPORT_LIST = 'ROOT_SET_REPORT_LIST',
  ROOT_SET_SELECTED_CLIENT = 'ROOT_SET_SELECTED_CLIENT',
  ROOT_SET_SELECTED_EXT_ID = 'ROOT_SET_SELECTED_EXT_ID',
  ROOT_SET_TARGET_LIST = 'ROOT_SET_TARGET_LIST',

  // Toolbar
  TOOLBAR_CANCEL = 'TOOLBAR_CANCEL',
  TOOLBAR_ENABLE_ALL = 'TOOLBAR_ENABLE_ALL',
  TOOLBAR_DISABLE_CANCEL = 'TOOLBAR_DISABLE_CANCEL',
  TOOLBAR_DISABLE_REFRESH = 'TOOLBAR_DISABLE_REFRESH',
  TOOLBAR_DISABLE_RUN = 'TOOLBAR_DISABLE_RUN',
  TOOLBAR_DISABLE_SAVE = 'TOOLBAR_DISABLE_SAVE',
  TOOLBAR_DISABLE_SAVE_AS_COPY = 'TOOLBAR_DISABLE_SAVE_AS_COPY',
  TOOLBAR_ENABLE_CANCEL = 'TOOLBAR_ENABLE_CANCEL',
  TOOLBAR_ENABLE_REFRESH = 'TOOLBAR_ENABLE_REFRESH',
  TOOLBAR_ENABLE_RUN = 'TOOLBAR_ENABLE_RUN',
  TOOLBAR_ENABLE_SAVE = 'TOOLBAR_ENABLE_SAVE',
  TOOLBAR_ENABLE_SAVE_AS_COPY = 'TOOLBAR_ENABLE_SAVE_AS_COPY',
  TOOLBAR_EXPORT = 'TOOLBAR_EXPORT',
  TOOLBAR_REFRESH = 'TOOLBAR_REFRESH',
  TOOLBAR_RUN = 'TOOLBAR_RUN',
  TOOLBAR_SAVE = 'TOOLBAR_SAVE',
  TOOLBAR_SAVE_AS_COPY = 'TOOLBAR_SAVE_AS_COPY',
  TOOLBAR_SET_LAST_CACHED_DATE = 'TOOLBAR_SET_LAST_CACHED_DATE',
  TOOLBAR_SET_IS_REFRESH_LAUNCHED = 'TOOLBAR_SET_IS_REFRESH_LAUNCHED',
  TOOLBAR_SET_IS_REFRESH_NOT_LAUNCHED = 'TOOLBAR_SET_IS_REFRESH_NOT_LAUNCHED',
  TOOLBAR_SET_IS_RUN_LAUNCHED = 'TOOLBAR_SET_IS_RUN_LAUNCHED',
  TOOLBAR_SET_IS_RUN_NOT_LAUNCHED = 'TOOLBAR_SET_IS_RUN_NOT_LAUNCHED',
  TOOLBAR_SET_PROGRESS = 'TOOLBAR_SET_PROGRESS',
  TOOLBAR_SET_QUERY_PROGRESS = 'TOOLBAR_SET_QUERY_PROGRESS',
  TOOLBAR_SET_REPORT_TYPE = 'TOOLBAR_SET_REPORT_TYPE',
  TOOLBAR_ENABLE_AUDIT_BUTTON = 'TOOLBAR_ENABLE_AUDIT_BUTTON',
  TOOLBAR_DISABLE_AUDIT_BUTTON = 'TOOLBAR_DISABLE_AUDIT_BUTTON',
  TOOLBAR_ENABLE_EXPORT_BUTTON = 'TOOLBAR_ENABLE_EXPORT_BUTTON',
  TOOLBAR_DISABLE_EXPORT_BUTTON = 'TOOLBAR_DISABLE_EXPORT_BUTTON',
  TOOLBAR_SET_EXPORT_RUNNING = 'TOOLBAR_SET_EXPORT_RUNNING',
  TOOLBAR_SET_EXPORT_NOT_RUNNING = 'TOOLBAR_SET_EXPORT_NOT_RUNNING',
  TOOLBAR_DISABLE_REPORT_PUBLIC_PRIVATE = 'TOOLBAR_DISABLE_REPORT_PUBLIC_PRIVATE',
  TOOLBAR_ENABLE_REPORT_PUBLIC_PRIVATE = 'TOOLBAR_ENABLE_REPORT_PUBLIC_PRIVATE',
  TOOLBAR_DISABLE_AUDIT = 'TOOLBAR_DISABLE_AUDIT',
  TOOLBAR_ENABLE_AUDIT = 'TOOLBAR_ENABLE_AUDIT',
  TOOLBAR_DISABLE_LINK = 'TOOLBAR_DISABLE_LINK',
  TOOLBAR_ENABLE_LINK = 'TOOLBAR_ENABLE_LINK',
  TOOLBAR_DISABLE_CANCEL_BUTTON = 'TOOLBAR_DISABLE_CANCEL_BUTTON',
  TOOLBAR_ENABLE_CANCEL_BUTTON = 'TOOLBAR_ENABLE_CANCEL_BUTTON',
  TOOLBAR_SET_READ_ONLY_LOAD_STARTED = 'TOOLBAR_SET_READ_ONLY_LOAD_STARTED',
  TOOLBAR_SET_READ_ONLY_LOAD_ENDED = 'TOOLBAR_SET_READ_ONLY_LOAD_ENDED',

  // Business data
  BUSINESS_DATA_ADD_BUSINESS_DATA = 'BUSINESS_DATA_ADD_BUSINESS_DATA',
  BUSINESS_DATA_SET_BUSINESS_DATA = 'BUSINESS_DATA_SET_BUSINESS_DATA',
  BUSINESS_DATA_SET_AVAILABLE_METRICS = 'BUSINESS_DATA_SET_AVAILABLE_METRICS',
  BUSINESS_DATA_SET_AVAILABLE_DIMENSIONS = 'BUSINESS_DATA_SET_AVAILABLE_DIMENSIONS',
  BUSINESS_DATA_SET_AVAILABLE_INTERVALS = 'BUSINESS_DATA_SET_AVAILABLE_INTERVALS',
  BUSINESS_DATA_SET_AVAILABLE_ORDER_FIELDS = 'BUSINESS_DATA_SET_AVAILABLE_ORDER_FIELDS',
  BUSINESS_DATA_SET_AVAILABLE_TARGETS = 'BUSINESS_DATA_SET_AVAILABLE_TARGETS',
  BUSINESS_DATA_RESET_AVAILABLE_DATA = 'BUSINESS_DATA_RESET_AVAILABLE_DATA',

  // Report
  REPORT_SET_HAS_CHANGED = 'REPORT_SET_HAS_CHANGED',
  REPORT_ACCEPT_CONFIRMATION_DIALOG = 'REPORT_ACCEPT_CONFIRMATION_DIALOG',
  REPORT_CANCEL_CONFIRMATION_DIALOG = 'REPORT_CANCEL_CONFIRMATION_DIALOG',
  REPORT_ACCEPT_MODAL_DIALOG = 'REPORT_ACCEPT_MODAL_DIALOG',
  REPORT_SET_CUMULATIVE_GROUP = 'REPORT_SET_CUMULATIVE_GROUP',
  REPORT_SET_DIMENSIONS = 'REPORT_SET_DIMENSIONS',
  REPORT_SET_INTERVAL = 'REPORT_SET_INTERVAL',
  REPORT_SET_LOADED = 'REPORT_SET_LOADED',
  REPORT_SET_LOADING = 'REPORT_SET_LOADING',
  REPORT_SET_METRICS = 'REPORT_SET_METRICS',
  REPORT_SET_QUERY_DATASETS = 'REPORT_SET_QUERY_DATASETS',
  REPORT_SET_QUERY_DAYPART_DATASET_ID = 'REPORT_SET_QUERY_DAYPART_DATASET_ID',
  REPORT_SET_QUERY_CROSSWALK_COMBINATION_ID = 'REPORT_SET_QUERY_CROSSWALK_COMBINATION_ID',
  REPORT_SET_QUERY_GENERIC_EVENTS_DATASETS = 'REPORT_SET_QUERY_GENERIC_EVENTS_DATASETS',
  REPORT_SET_QUERY_FILTER_END_DATE = 'REPORT_SET_QUERY_FILTER_END_DATE',
  REPORT_SET_QUERY_FILTER_START_DATE = 'REPORT_SET_QUERY_FILTER_START_DATE',
  REPORT_SET_QUERY_FILTER_DATE_OFFSET = 'REPORT_SET_QUERY_FILTER_DATE_OFFSET',
  REPORT_SET_QUERY_FILTERS = 'REPORT_SET_QUERY_FILTERS',
  REPORT_SET_QUERY_PERFORMANCE_METRIC_GROUPS = 'REPORT_SET_QUERY_PERFORMANCE_METRIC_GROUPS',
  REPORT_SET_BREAKOUT_CATEGORIES_FILTERS = 'REPORT_SET_BREAKOUT_CATEGORIES_FILTERS',
  REPORT_SET_EXPOSURE_FLIGHT_DATE_START = 'REPORT_SET_EXPOSURE_FLIGHT_DATE_START',
  REPORT_SET_EXPOSURE_FLIGHT_DATE_END = 'REPORT_SET_EXPOSURE_FLIGHT_DATE_END',
  REPORT_SET_EXPOSURE_FLIGHT_DATE_RELATIVE_OFFSET = 'REPORT_SET_EXPOSURE_FLIGHT_DATE_RELATIVE_OFFSET',
  REPORT_SET_OUTCOME_VIEWERSHIP_START_DATE = 'REPORT_SET_OUTCOME_VIEWERSHIP_START_DATE',
  REPORT_SET_OUTCOME_VIEWERSHIP_END_DATE = 'REPORT_SET_OUTCOME_VIEWERSHIP_END_DATE',
  REPORT_SET_OUTCOME_VIEWERSHIP_RELATIVE_DATE_OFFSET = 'REPORT_SET_OUTCOME_VIEWERSHIP_RELATIVE_DATE_OFFSET',
  REPORT_SET_QUERY_LEVEL = 'REPORT_SET_QUERY_LEVEL',
  REPORT_SET_QUERY_METRIC_MODE = 'REPORT_SET_QUERY_METRIC_MODE',
  REPORT_SET_QUERY_MODE = 'REPORT_SET_QUERY_MODE',
  REPORT_RESET_CUSTOM_EXPOSURE_SEGMENT_RULES = 'REPORT_RESET_CUSTOM_EXPOSURE_SEGMENT_RULES',
  REPORT_SET_QUERY_SAMPLE_FACTOR = 'REPORT_SET_QUERY_SAMPLE_FACTOR',
  REPORT_SET_QUERY_SELECT_METRICS = 'REPORT_SET_QUERY_SELECT_METRICS',
  REPORT_SET_QUERY_SELECT_GROUP_DIMENSIONS = 'REPORT_SET_QUERY_SELECT_GROUP_DIMENSIONS',
  REPORT_SET_QUERY_TARGETS = 'REPORT_SET_QUERY_TARGETS',
  REPORT_SET_QUERY_TIME_FRAME = 'REPORT_SET_QUERY_TIME_FRAME',
  REPORT_SET_NOT_PERSISTED_PROPS = 'REPORT_SET_NOT_PERSISTED_PROPS',
  REPORT_ADD_NOT_PERSISTED_PROPS = 'REPORT_ADD_NOT_PERSISTED_PROPS',
  REPORT_SET_QUERY_TIME_ZONE = 'REPORT_SET_QUERY_TIME_ZONE',
  REPORT_SET_QUERY_WEIGHT = 'REPORT_SET_QUERY_WEIGHT',
  REPORT_SET_REAL_QUERY_MODE = 'REPORT_SET_REAL_QUERY_MODE',
  REPORT_SET_REPORT = 'REPORT_SET_REPORT',
  REPORT_SET_REPORT_NAME = 'REPORT_SET_REPORT_NAME',
  REPORT_SET_ROW_LIMIT = 'REPORT_SET_ROW_LIMIT',
  REPORT_SET_RULESET_EVENTS = 'REPORT_SET_RULESET_EVENTS',
  REPORT_SET_SELECTED_CHARTS = 'REPORT_SET_SELECTED_CHARTS',
  REPORT_SET_SELECTED_METRICS = 'REPORT_SET_SELECTED_METRICS',
  REPORT_SET_SELECTED_TARGETS = 'REPORT_SET_SELECTED_TARGETS',
  REPORT_SET_SORTING = 'REPORT_SET_SORTING',
  REPORT_SET_SORT_ORDER = 'REPORT_SET_SORT_ORDER',
  REPORT_SET_SORT_FIELD = 'REPORT_SET_SORT_FIELD',
  REPORT_SET_QUERY_SORTING = 'REPORT_SET_QUERY_SORTING',
  REPORT_SET_IS_REPORT_INVALID = 'REPORT_SET_IS_REPORT_INVALID',
  REPORT_SET_UPDATED_REPORT = 'REPORT_SET_UPDATED_REPORT',
  REPORT_SET_SAVED_REPORT = 'REPORT_SET_SAVED_REPORT',
  REPORT_SET_LAST_VALID_REPORT = 'REPORT_SET_LAST_VALID_REPORT',
  REPORT_RESET_LAST_VALID_REPORT = 'REPORT_RESET_LAST_VALID_REPORT',
  REPORT_SET_IS_DOWNLOADING_PRESENTATION = 'REPORT_SET_IS_DOWNLOADING_PRESENTATION',
  REPORT_SET_PRESENTATION_DOWNLOADED = 'IMP4CT_PRESENTATION',
  REPORT_SHOW_CONFIRMATION_DIALOG = 'REPORT_SHOW_CONFIRMATION_DIALOG',
  REPORT_SHOW_MODAL_DIALOG = 'REPORT_SHOW_MODAL_DIALOG',
  REPORT_SET_RESULTS = 'REPORT_SET_RESULTS',
  REPORT_SET_REPORT_NOT_SAVED = 'REPORT_SET_REPORT_NOT_SAVED',
  REPORT_SET_REPORT_SAVED = 'REPORT_SET_REPORT_SAVED',
  REPORT_SET_AGGREGATION_METHOD = 'REPORT_SET_AGGREGATION_METHOD',
  REPORT_SET_FULLY_LOADED = 'REPORT_SET_FULLY_LOADED',
  REPORT_SET_DYNAMIC_BREAKOUTS = 'REPORT_SET_DYNAMIC_BREAKOUTS',
  REPORT_RESET_DYNAMIC_BREAKOUTS = 'REPORT_RESET_DYNAMIC_BREAKOUTS',
  REPORT_SET_PEFORMANCE_METRIC_GROUPS = 'REPORT_SET_PEFORMANCE_METRIC_GROUPS',
  REPORT_SET_CONVERSION_TYPE = 'REPORT_SET_CONVERSION_TYPE',
  REPORT_SET_CONVERSION_MIN_DATE = 'REPORT_SET_CONVERSION_MIN_DATE',
  REPORT_SET_CONVERSION_MAX_DATE = 'REPORT_SET_CONVERSION_MAX_DATE',
  REPORT_SET_QUERY_CONVERSION_TYPE = 'REPORT_SET_QUERY_CONVERSION_TYPE',
  REPORT_SET_EQ_METRIC = 'REPORT_SET_EQ_METRIC',
  REPORT_SET_ACCORDION_RESULTS_OPEN = 'REPORT_SET_ACCORDION_RESULTS_OPEN',
  REPORT_SET_ACCORDION_QUERY_OPEN = 'REPORT_SET_ACCORDION_QUERY_OPEN',
  REPORT_SET_ACCORDION_QUERY_CLOSED = 'REPORT_SET_ACCORDION_QUERY_CLOSED',
  REPORT_SET_ACCORDION_SETTINGS_OPEN = 'REPORT_SET_ACCORDION_SETTINGS_OPEN',
  REPORT_SET_ACCORDION_METHODOLOGY_OPEN = 'REPORT_SET_ACCORDION_METHODOLOGY_OPEN',
  REPORT_TOGGLE_MODAL = 'REPORT_TOGGLE_MODAL',
  REPORT_SET_TABLE_VISIBLE = 'REPORT_SET_TABLE_VISIBLE',
  REPORT_SET_CHART_VISIBLE = 'REPORT_SET_CHART_VISIBLE',
  REPORT_SET_PERSISTED_REPORT = 'REPORT_SET_PERSISTED_REPORT',

  REPORT_SET_QUERY_INCLUDE_BREAKDOWN = 'REPORT_SET_QUERY_INCLUDE_BREAKDOWN',

  // Result Selections
  RESULT_SELECTION_SET_RESULT_SELECTION = 'RESULT_SELECTION_SET_RESULT_SELECTION',

  // Events
  EVENTS_SET_EVENTS = 'EVENTS_SET_EVENTS',

  // Events
  MESSAGE_LIST_SET_MESSAGES = 'MESSAGE_LIST_SET_MESSAGES',

  // Result
  RESULT_EXPORT = 'RESULT_EXPORT',

  // User
  USER_LOGIN_FAIL = 'USER_LOGIN_FAIL',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_SET_ALL_CLIENTS = 'USER_SET_ALL_CLIENTS',
  USER_SET_CLIENTS = 'USER_SET_CLIENTS',
  USER_SET_EMAIL = 'USER_SET_EMAIL',
  USER_SET_IS_LOGGED = 'USER_SET_IS_LOGGED',
  USER_SET_LOADING = 'USER_SET_LOADING',
  USER_SET_PERMISSIONS = 'USER_SET_PERMISSIONS',
  USER_SET_SELECTED_CLIENT = 'USER_SET_SELECTED_CLIENT',
  USER_SET_SELECTED_USER = 'USER_SET_SELECTED_USER',
  USER_SET_TOKEN = 'USER_SET_TOKEN',
  USER_SET_MIN_DATE = 'USER_SET_MIN_DATE',
  USER_UPDATE = 'USER_UPDATE',

  // Admin
  ADMIN_USERS_REPORT_LOAD_STARTED = 'ADMIN_USERS_REPORT_LOAD_STARTED',
  ADMIN_USERS_REPORT_LOAD_FINISHED = 'ADMIN_USERS_REPORT_LOAD_FINISHED',
  ADMIN_USERS_SET_EDIT_MANY_PREVIEW_TABLE = 'ADMIN_USERS_SET_EDIT_MANY_PREVIEW_TABLE',
  ADMIN_USERS_RESET_EDIT_MANY_PREVIEW_TABLE = 'ADMIN_USERS_RESET_EDIT_MANY_PREVIEW_TABLE',

  // Download
  DOWNLOAD_STARTED = 'DOWNLOAD_STARTED',
  DOWNLOAD_ENDED = 'DOWNLOAD_ENDED',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',

  // Uploads
  UPLOADS_FORM_UPDATED = 'UPLOADS_FORM_UPDATED',
  UPLOADS_FORM_RESET = 'UPLOADS_FORM_RESET',
  UPLOADS_FORM_SET = 'UPLOADS_FORM_SET',
  UPLOADS_SET_VALIDATION_ERRORS = 'UPLOADS_SET_VALIDATION_ERRORS',
  UPLOADS_SET_ERROR_MESSAGE = 'UPLOADS_SET_ERROR_MESSAGE',
  UPLOADS_RESET_ERROR_MESSAGE = 'UPLOADS_RESET_ERROR_MESSAGE',
  UPLOADS_START_POLLING = 'UPLOADS_START_POLLING',
  UPLOADS_RUNNING_POLLING = 'UPLOADS_RUNNING_POLLING',
  UPLOADS_END_POLLING = 'UPLOADS_END_POLLING',
  UPLOADS_UPDATE_STARTED = 'UPLOADS_UPDATE_STARTED',
  UPLOADS_UPDATE_ENDED = 'UPLOADS_UPDATE_ENDED',
  UPLOADS_LOAD_STARTED = 'UPLOADS_LOAD_STARTED',
  UPLOADS_LOAD_ENDED = 'UPLOADS_LOAD_ENDED',
  UPLOADS_SET_CROSSWALK_SOURCES = 'UPLOADS_SET_CROSSWALK_SOURCES',
  UPLOADS_SET_USER_DIMENSION_DETAILS = 'UPLOADS_SET_USER_DIMENSION_DETAILS',
  UPLOADS_SET_CROSSWALK_COMBINATION_DETAILS = 'UPLOADS_SET_CROSSWALK_COMBINATION_DETAILS',

  // Processing
  PROCESSING_SET_STATUS = 'PROCESSING_SET_STATUS',

  // Dashboard
  DASHBOARD_REPORT_LOAD_STARTED = 'DASHBOARD_REPORT_LOAD_STARTED',
  DASHBOARD_REPORT_LOAD_ENDED = 'DASHBOARD_REPORT_LOAD_ENDED',
  DASHBOARD_REPORT_EXECUTION_STARTED = 'DASHBOARD_REPORT_EXECUTION_STARTED',
  DASHBOARD_REPORT_EXECUTION_UPDATED = 'DASHBOARD_REPORT_EXECUTION_UPDATED',
  DASHBOARD_REPORT_EXECUTION_ENDED = 'DASHBOARD_REPORT_EXECUTION_ENDED',
  DASHBOARD_REPORT_RESET = 'DASHBOARD_REPORT_RESET',
  DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_STARTED = 'DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_STARTED',
  DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_ENDED = 'DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_ENDED',
  DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_STARTED = 'DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_STARTED',
  DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_ENDED = 'DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_ENDED',
  DASHBOARD_REPORT_FORM_SET_METRICS_OPTIONS = 'DASHBOARD_REPORT_FORM_SET_METRICS_OPTIONS',
  DASHBOARD_REPORT_FORM_SET_FIELD_VALUE = 'DASHBOARD_REPORT_FORM_SET_FIELD_VALUE',
  DASHBOARD_SET_USER_DASHBOARD = 'DASHBOARD_SET_USER_DASHBOARD',
  DASHBOARD_REPORT_FORM_UPDATE_STARTED = 'DASHBOARD_REPORT_FORM_UPDATE_STARTED',
  DASHBOARD_REPORT_FORM_UPDATE_ENDED = 'DASHBOARD_REPORT_FORM_UPDATE_ENDED',
  DASHBOARD_REPORT_FORM_SET = 'DASHBOARD_REPORT_FORM_SET',
  DASHBOARD_REPORT_FORM_RESET = 'DASHBOARD_REPORT_FORM_RESET',

  // Config Options
  CONFIG_OPTIONS_DETAILS_LOAD_STARTED = 'CONFIG_OPTIONS_DETAILS_LOAD_STARTED',
  CONFIG_OPTIONS_DETAILS_LOAD_ENDED = 'CONFIG_OPTIONS_DETAILS_LOAD_ENDED',
  CONFIG_OPTIONS_DETAILS_FORM_SET = 'CONFIG_OPTIONS_DETAILS_FORM_SET',
  CONFIG_OPTIONS_DETAILS_FORM_ID_UPDATE = 'CONFIG_OPTIONS_DETAILS_FORM_ID_UPDATE',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_UPDATE = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_UPDATE',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_ADD = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_ADD',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_DELETE = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_DELETE',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_TOGGLE = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_TOGGLE',
  CONFIG_OPTIONS_DETAILS_SAVE_STARTED = 'CONFIG_OPTIONS_DETAILS_SAVE_STARTED',
  CONFIG_OPTIONS_DETAILS_SAVE_ENDED = 'CONFIG_OPTIONS_DETAILS_SAVE_ENDED',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_VALUE_ERRORS = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_VALUE_ERRORS',
  CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_TEXT_ERRORS = 'CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_TEXT_ERRORS',
  CONFIG_OPTIONS_DETAILS_FORM_SET_ERROR = 'CONFIG_OPTIONS_DETAILS_FORM_SET_ERROR',
  CONFIG_OPTIONS_DETAILS_FORM_RESET = 'CONFIG_OPTIONS_DETAILS_FORM_RESET',
}

export default ActionType;
