import IReport from 'domains/reports/types';
import useAttributionReports from 'hooks/useAttributionReports';
import useToast from 'hooks/useToast';
import { isNull } from 'lodash/fp';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as rootActions from 'store/actions/root';
import * as rootSelectors from 'store/selectors/root';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import IError from 'types/error';
import State from 'types/state';
import AttributionReportsListFeature from '../../list.feature';

interface IProps {
  clientExtID: string;
  attributionReportList: IReport[];
  setAttributionReportList: (
    arg0: IReport[] | null,
  ) => Action<IReport[] | null>;
  testId?: string;
  path: string;
}

const LOCALE_ATTRIBUTION_REPORT_LIST_REPORTS = 'Attribution Reports';

const AttributionReportsListContainer = (props: IProps): ReactElement => {
  const {
    clientExtID,
    attributionReportList,
    setAttributionReportList,
    testId,
    path,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const { doErrorToast } = useToast();
  const { fetchAttributionReports } = useAttributionReports();

  useEffect(() => {
    if (isNull(attributionReportList) || !loaded) {
      fetchAttributionReports({
        clientExtID,
        onSuccess: (res: IReport[]) => {
          setAttributionReportList(res);
          setLoaded(true);
        },
        onError: (error: IError) => {
          setAttributionReportList(null);
          setLoaded(true);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    }
  }, [attributionReportList]);

  return (
    <AttributionReportsListFeature
      title={LOCALE_ATTRIBUTION_REPORT_LIST_REPORTS}
      testId={testId}
      loading={isNull(attributionReportList) || !loaded}
      path={path}
    />
  );
};

const mapStateToProps = (
  state: State,
): Pick<IProps, 'attributionReportList' | 'clientExtID'> => ({
  attributionReportList: rootSelectors.getAttributionReportList(state),
  clientExtID: userSelectors.getSelectedClientExtId(state),
});

const mapDispatchToProps = {
  setAttributionReportList: rootActions.setAttributionReportList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttributionReportsListContainer);
