import Dropdown from 'components/Dropdown';
import Flex, { Horizontal } from 'components/Flex';
import { getTestId, getClass } from 'helpers/components';
import { toTextValuePairs } from 'helpers/types';
import React, { ReactElement } from 'react';
import {
  IReportSorting,
  reportSortingOrder,
  ReportSortingOrder,
} from 'types/query';
import ITextValue from 'types/textValue';

export const componentName = 'order-by';

interface IProps {
  disabled?: boolean;
  onChange: (sort: IReportSorting) => void;
  options: ITextValue[];
  sorting?: IReportSorting;
  testId?: string;
}

const blankSorting: IReportSorting = { field: '', order: 'ASC' };
const OrderBySimple = (props: IProps): ReactElement => {
  const {
    options,
    sorting: defaultSorting = blankSorting,
    onChange,
    testId,
    disabled,
  } = props;

  const componentTestId = getTestId(componentName, testId);
  const componentClass = getClass(componentName);

  return (
    <div data-testid={componentTestId} className={componentClass}>
      <Flex horizontal={Horizontal.left}>
        <div className="order-by-type">
          <Dropdown
            noPlaceholderValue
            ignoreGrouping
            onChange={(selectedField: string) => {
              onChange({
                field: selectedField,
                order: defaultSorting.order,
              });
            }}
            options={options}
            value={defaultSorting.field.toString()}
            disabled={disabled}
            testId="field"
            trackingId="order_by_field"
          />
        </div>
        <div className="order-by-value">
          <Dropdown
            noPlaceholderValue
            onChange={(selectedOrder: string) => {
              onChange({
                field: defaultSorting.field,
                order: selectedOrder as ReportSortingOrder,
              });
            }}
            options={toTextValuePairs(reportSortingOrder)}
            value={defaultSorting.order.toString()}
            disabled={!defaultSorting.field || disabled}
            testId="order"
            trackingId="order_by_order"
          />
        </div>
      </Flex>
    </div>
  );
};

export default OrderBySimple;
