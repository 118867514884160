import { IBusinessData } from 'domains/reports/types';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as messageListActions from 'store/actions/messageList';
import * as reportActions from 'store/actions/report';
import * as toolbarActions from 'store/actions/toolbar';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import * as toolbarSelectors from 'store/selectors/toolbar';
import * as domainSelectors from 'store/selectors/domains';
import State from 'types/state';
import { QueryBuilder } from './QueryBuilder.component';
import { IOwnProps } from './types';

const mapStateToProps = (
  state: State,
): Pick<
  IOwnProps,
  | 'availableDimensions'
  | 'availableIntervals'
  | 'availableMetrics'
  | 'availableOrderFields'
  | 'availableRulesDimensions'
  | 'availableTargets'
  | 'businessData'
  | 'cumulativeGroup'
  | 'dimensions'
  | 'maxDate'
  | 'metrics'
  | 'minDate'
  | 'report'
  | 'toolbar'
  | 'messageList'
  | 'isReportInvalid'
  | 'domainsDateRanges'
> => ({
  availableDimensions: businessDataSelectors.getAvailableDimensions(state),
  availableIntervals: businessDataSelectors.getAvailableIntervals(state),
  availableMetrics: businessDataSelectors.getAvailableMetrics(state),
  availableOrderFields: businessDataSelectors.getAvailableOrderFields(state),
  availableRulesDimensions:
    businessDataSelectors.getAvailableRulesDimensions(state),
  availableTargets: businessDataSelectors.getAvailableTargets(state),
  businessData: get('businessData', state) as IBusinessData,
  cumulativeGroup: reportSelectors.getCumulativeGroup(state),
  dimensions: reportSelectors.getDimensions(state),
  maxDate: reportSelectors.getReportMaxDate(state),
  metrics: reportSelectors.getMetrics(state),
  minDate: reportSelectors.getReportMinDate(state),
  report: reportSelectors.getReport(state),
  toolbar: toolbarSelectors.getToolbar(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  messageList: messageListSelectors.getMessageList(state),
  domainsDateRanges: domainSelectors.getDomainsDateRanges(state),
});

const mapDispatchToProps = {
  ...reportActions,
  ...toolbarActions,
  addWarningMessage: messageListActions.addMessage,
  deleteWarningMessage: messageListActions.deleteMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryBuilder);
