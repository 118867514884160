import H1 from 'components/H1';
import InlineAlert, {
  AlertTypes,
  BackgroundColors,
} from 'components/InlineAlert';
import { IClient } from 'domains/clients/types';
import useDashboards from 'domains/dashboard/useDashboards';
import { ClientForm } from 'features/clients/components/Form';
import { getTestId, getClass } from 'helpers/components';
import useClientErrors from 'hooks/useClientErrors';
import useClients from 'hooks/useClients';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import get from 'lodash/get';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as domainsActions from 'store/actions/domains';
import * as clientsSelector from 'store/selectors/clients';
import { Action } from 'types/action';
import IError from 'types/error';
import State from 'types/state';
import ITextValue from 'types/textValue';

export const editClientComponentName = 'edit-client';

const LOCALE_EDIT_TITLE = 'Edit Client';
const LOCALE_CREATE_TITLE = 'New Client';

type OwnState = {
  datasetsOptions: ITextValue[];
};

interface Props {
  setClients: (arg0: IClient[]) => Action<IClient[]>;
}

interface OwnProps extends Props, OwnState {}

const ClientEdit: FunctionComponent<OwnProps> = (props) => {
  const { setClients, datasetsOptions: datasets } = props;
  const navigate = useNavigate();
  const { clients = [] } = useClients();
  const { id } = useParams<{ id: string }>();
  const { doSuccessToast } = useToast();
  const { dashboardOptions } = useDashboards();
  const {
    handleError: handleClientError,
    extIDError,
    nameError,
    resetExtIDError,
    resetNameError,
  } = useClientErrors();
  const [bannerMessage, setBannerMessage] = useState('');

  const goToClients = async (): Promise<string> => {
    navigate('/clients');
    return id ? 'Client updated' : 'Client created';
  };

  const handleSuccess = (collection: IClient[]): void => {
    setClients(collection);

    goToClients().then(doSuccessToast);
  };

  const handleError = (err: IError): void => {
    setBannerMessage(err?.message ?? 'There has been an error.');
    handleClientError(err?.message);
  };

  const { doUpdate, doCreate, loading } = useItemAdmin<IClient>({
    endpoint: '/clients',
  });

  const client = clients.find((item: IClient) => item.id === id);

  const handleSubmit = async (updateClient: IClient): Promise<void> => {
    const resolvedDefaultDash = Array.isArray(updateClient.defaultDash)
      ? get(updateClient, 'defaultDash.0', '')
      : updateClient.defaultDash;
    if (id) {
      await doUpdate({
        item: {
          ...updateClient,
          id,
          defaultDash: resolvedDefaultDash,
        },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    } else {
      await doCreate({
        item: {
          ...updateClient,
          defaultDash: resolvedDefaultDash,
        },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    }
  };

  const editClientTestId = getTestId(editClientComponentName);
  const editClientDefaultClass = getClass(editClientComponentName);

  return (
    <section className={editClientDefaultClass} data-testid={editClientTestId}>
      <div className="banner-container">
        <div className="banner">
          {bannerMessage && (
            <InlineAlert
              message={bannerMessage}
              mode={AlertTypes.warn}
              backgroundColor={BackgroundColors.white}
              onClose={() => setBannerMessage('')}
            />
          )}
        </div>
      </div>
      <header className={`${editClientDefaultClass}-header`}>
        <H1>{client ? LOCALE_EDIT_TITLE : LOCALE_CREATE_TITLE}</H1>
      </header>
      <ClientForm
        client={client}
        onSubmit={handleSubmit}
        onCancel={goToClients}
        loading={loading}
        testId={editClientTestId}
        dashboardOptions={dashboardOptions}
        datasetsOptions={datasets}
        extIDError={extIDError}
        nameError={nameError}
        resetExtIDError={resetExtIDError}
        resetNameError={resetNameError}
      />
    </section>
  );
};

const mapStateToProps = (state: State): OwnState => ({
  datasetsOptions: clientsSelector.datasetsOptions(state),
});

const mapDispatchToProps = domainsActions;

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);
