import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as businessActions from 'store/actions/businessData';
import dashboarActions from 'store/actions/dashboard';
import * as featureReportsActions from 'store/actions/features/reports';
import * as messageListActions from 'store/actions/messageList';
import * as reportActions from 'store/actions/report';
import { handleUpdateName } from 'store/actions/report/handleUpdateName';
import { handleSaveReportModal } from 'store/actions/report/submitReport';
import * as rootActions from 'store/actions/root';
import * as toolbarActions from 'store/actions/toolbar';
import * as eventsSelectors from 'store/selectors/events';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import * as resultSelectors from 'store/selectors/reportResult';
import * as targetResultSelectors from 'store/selectors/targetResult';
import * as toolbarSelectors from 'store/selectors/toolbar';
import * as downloadSelectors from 'store/selectors/downloads';
import State from 'types/state';
import * as downloadActions from 'store/actions/downloads';
import * as reportResultActions from '../../../store/actions/reportResult';
import { ReportEditFeature } from './EditFeature.component';
import { ConnectedProps } from './types';

export const editReportComponentName = 'edit-report';

const mapStateToProps = (state: State): ConnectedProps => ({
  chartData: resultSelectors.getResultChartData(get('reportResult', state)),
  confirmationDialog: reportSelectors.getConfirmationDialog(state),
  modalDialog: reportSelectors.getModalDialog(state),
  isModalOpen: reportSelectors.isModalOpen(state),
  events: eventsSelectors.getEvents(state),
  messageList: messageListSelectors.getMessageList(state),
  invalidTargetList: reportSelectors.getInvalidTargetList(state),
  isAccordionQueryOpen: reportSelectors.isAccordionQueryOpen(state),
  isAccordionResultsOpen: reportSelectors.isAccordionResultsOpen(state),
  isAccordionSettingsOpen: reportSelectors.isAccordionSettingsOpen(state),
  isBaseReportRunning: reportSelectors.isBaseReportRunning(state),
  isBaseReportSaving: reportSelectors.isBaseReportSaving(state),
  isExportDisabled: toolbarSelectors.getExportIsDisabled(state),
  isNewTarget: reportSelectors.isNewTarget(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  isBaseReportNew: reportSelectors.getBaseReportNew(state),
  isReportNameDisabled: toolbarSelectors.getReportNameDisabled(state),
  lastValidReport: reportSelectors.getLastValidReport(state),
  report: reportSelectors.getReport(state),
  savedReport: reportSelectors.getSavedReport(state),
  targetData: targetResultSelectors.getTargetData(state),
  toolbar: toolbarSelectors.getToolbar(state),
  updatedReport: reportSelectors.getUpdatedReport(state),
  validTargetList: reportSelectors.getValidTargetList(state),
  errorMessage: resultSelectors.getErrorMessage(state),
  isLoadingReadOnlyToggle: toolbarSelectors.getIsLoadingReadOnlyToggle(state),
  isDownloading: downloadSelectors.getIsDownloading(state),
});

const mapDispatchToProps = {
  ...toolbarActions,
  ...reportActions,
  handleUpdateName,
  handleSaveReportModal,
  setReportList: rootActions.setReportList,
  addWarningMessage: messageListActions.addMessage,
  deleteWarningMessage: messageListActions.deleteMessage,
  setTargetList: rootActions.setTargetList,
  fetchTargetsAction: businessActions.fetchTargetsAction,
  handleSubmitPlatformForm: featureReportsActions.handleSubmitPlatformForm,
  cancelExecutionJobAction: reportResultActions.cancelExecutionJobAction,
  addNotPersistedProps: reportActions.addNotPersistedProps,
  setIsReportInvalid: reportActions.setIsReportInvalid,
  setErrorMessage: reportResultActions.setErrorMessage,
  resetReportResult: reportResultActions.resetReportResult,
  resetDashboardReport: dashboarActions.dashboardReportReset,
  handleToggleReadOnly: reportActions.handleToggleReadOnly,
  handleCloneReport: rootActions.handleCloneReport,
  handleDownload: downloadActions.handleDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportEditFeature);
