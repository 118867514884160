import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Table, { textOverflows, alignments } from 'components/Table';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Index, Actions, getUrl } from 'routes';
import * as domainsActions from 'store/actions/domains';
import { Action } from 'types/action';
import IError from 'types/error';
import { IClient } from '../../domains/clients/types';
import useSelectedClient from '../../domains/clients/useSelectedClient';

const CLIENT_LIST_PAGE_SIZE = 10;

export const clientsListComponentName = 'clients-list';

type ContentProps = {
  clients: IClient[];
  testId?: string;
};

interface OwnProps extends ContentProps {
  setClients: (arg0: IClient[]) => Action<IClient[]>;
}

const ClientsList: FunctionComponent<OwnProps> = (
  props: OwnProps,
): ReactElement => {
  const { clients, setClients, testId } = props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { selectedClient, setClientCookie } = useSelectedClient();
  const { doDelete, loading } = useItemAdmin<IClient>({
    endpoint: Index.SEGMENT_CLIENTS,
  });

  const gotoCreate = (): void => {
    navigate(Actions.SEGMENT_CREATE);
  };

  const handleUpdateClick = (client: IClient): void => {
    const updateUrl = getUrl([Actions.SEGMENT_EDIT, client.id ?? '']);
    navigate(updateUrl);
  };

  const handleDeleteClick = (client: IClient): void => {
    doDelete({
      item: client,
      onSuccess: (collection: IClient[]): void => {
        setClients(collection);
        doSuccessToast('Client deleted');
        if (client.id === selectedClient?.id) {
          setClientCookie(undefined);
        }
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const clientsListTestId = getTestId(clientsListComponentName, testId);
  const clientsListClass = getClass(clientsListComponentName);

  return (
    <div className={clientsListClass} data-testid={clientsListTestId}>
      <header className={`${clientsListClass}-header`}>
        <Flex>
          <H1>Clients</H1>
          {checkPermissions('clients::create') && (
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.small}
              onClick={gotoCreate}
            >
              New
            </Button>
          )}
        </Flex>
      </header>
      <section>
        <Table<IClient>
          filter
          initialState={{ sortBy: [{ id: 'name' }] }}
          filterPlaceholder="Search for clients"
          goToEditOnRowClick
          noResultsMessage="No results found"
          textOverflow={textOverflows.ellipsis}
          editItemSegment={getUrl([Actions.SEGMENT_EDIT])}
          alignment={[alignments.left, alignments.center, alignments.right]}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              width: '40%',
            },
            {
              Header: 'External ID',
              accessor: 'extID',
              width: '40%',
            },
            {
              Header: 'Actions',
              id: 'Actions',
              width: '20%',
              Cell: function ClientsListTableCell({
                row: { original: client },
              }: CellProps<IClient>): JSX.Element {
                return (
                  <ListActions<IClient>
                    loading={loading}
                    item={client}
                    canUpdate={checkPermissions('clients::update')}
                    canDelete={checkPermissions('clients::delete')}
                    onDeleteClick={(): void => handleDeleteClick(client)}
                    onUpdateClick={(): void => handleUpdateClick(client)}
                    testId={clientsListTestId}
                    id={client.id}
                  />
                );
              },
            },
          ]}
          data={clients}
          pageSize={CLIENT_LIST_PAGE_SIZE}
        />
      </section>
    </div>
  );
};

export default connect(null, domainsActions)(ClientsList);
