import { NewInputValue } from 'components/Input';
import {
  eventDropdownShouldClose,
  getDefaultTargetSpecificValues,
  getFiltersFromQueryFilters,
  getQueryFiltersWithNewRules,
} from 'components/QueryBuilder/adapters';
import {
  LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL,
  LOCALE_CONFIRMATION_DIALOG_TITLE,
  LOCALE_INVALID_VOD_METRICS_MESSAGE,
} from 'components/QueryBuilder/components/QueryBuilderType';
import * as businessDataSelectors from 'store/selectors/businessData';
import { getAvailableIntervals } from 'store/selectors/businessData';
import * as domainsSelectors from 'store/selectors/domains';
import * as userSelectors from 'store/selectors/user';
import { getId, getSelectedClient } from 'store/selectors/user';
import { Filter as ChartFilterType } from 'components/ReportChartFilter';
import { getAvailablePairs } from 'components/ReportResultsForm/utils';
import { IDataset } from 'domains/datasets/types';
import IEvent from 'domains/events/types';
import { Metric } from 'domains/metrics/types';
import { checkPermissionTree } from 'domains/permissions/helpers';
import { setQueryModeDecision } from 'domains/reports/adapters/datasets';
import {
  getAvailableOrderFields,
  getDaypartDatasets,
  getDimension,
  getStandartDaypartDatasetId,
  getTargetAvailableBreakouts,
  isCumulative,
  isReport,
  isTarget,
  reportFiltersHasVOD,
  setMetricTypeDecision,
} from 'domains/reports/adapters/general';
import { getMetricsFromQuery } from 'domains/reports/adapters/results';
import {
  DEFAULT_EXPERIAN_VALUES,
  newAttributionReport as getNewAttributionReport,
  newNotPersistedProps,
  newReport as getNewReport,
  newTarget as getNewTarget,
} from 'domains/reports/initializers';
import {
  confirmDialogType,
  LOCALE_CANCEL_REPORT_RUN,
  LOCALE_DELETE_STOP_QUERY_HEADER,
  LOCALE_DELETE_STOP_QUERY_TEXT,
  LOCALE_GO_BACK,
} from 'domains/reports/locales/general';

import { processRulesetWithRedux } from 'domains/reports/rulesets/baseReport';
import IReport, {
  AttributionFilterType,
  cacheMode,
  ChartType,
  DataRowGraph,
  DynamicBreakouts,
  IBusinessData,
  IConfirmDialog,
  IModalDialog,
  INotPersistedProps,
  ISetSorting,
  MetricConfig,
  MetricTypes,
  ReportType,
  reportTypeEquivalences,
  SubTypeKeys,
} from 'domains/reports/types';
import {
  getAttributionFiltersFromQueryFilters,
  updateAttributionFiltersFromQueryFilters,
  updateNonDateAttributionFiltersFromQueryFilters,
} from 'features/attributionReports/components/AttributionQueryBuilder/adapters';
import {
  LOCALE_BACK_TO_REPORT_LIST,
  LOCALE_NOTIFICATION_HEADER,
} from 'features/reports/EditFeature/locale';
import { fetchApi } from 'helpers/fetching';
import { removeEmptyTreeValues } from 'helpers/removeEmptyTreeValues';
import { filterNotIndex } from 'helpers/utils';
import {
  cloneDeep,
  compact,
  cond,
  difference,
  filter,
  find,
  findIndex,
  flow,
  get,
  getOr,
  intersection,
  isEqual,
  map,
  matchesProperty,
  noop,
  set,
} from 'lodash/fp';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Actions, Index } from 'routes';
import store from 'store';
import {
  deleteMessagesForID,
  initializeMessageList,
} from 'store/actions/messageList';
import * as resultActions from 'store/actions/reportResult';
import ActionType from 'store/actions/types';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import { getQueryDaypartDatasetId } from 'store/selectors/report';
import * as resultSelectors from 'store/selectors/reportResult';
import { Action, PayloadLessAction } from 'types/action';
import {
  FilterType,
  IChildrenBase,
  IQueryFilters,
  newFilter,
  Operator,
  RuleFilter,
} from 'types/filter';
import {
  IReportQueryInterval,
  IReportSorting,
  Mode,
  Modes,
  ReportSortingOrder,
  reportSortingOrder,
} from 'types/query';
import State from 'types/state';
import ITextValue, { IOption } from 'types/textValue';
import FetchMethod from 'types/fetchMethod';
import IIdName from 'types/idName';
import * as reportReducer from 'store/reducers/report';
import {
  addBusinessData,
  fetchTargetsAction,
  setAvailableDimensions,
  setAvailableIntervals,
  setAvailableMetrics,
  setAvailableOrderFields,
} from '../businessData';
import { setEvents } from '../events';
import {
  executionReportInitialize,
  executionReportReset,
  executionShowReportPayload,
  resetAttributionReportResult,
} from '../reportResult';
import {
  cancel as toolbarCancel,
  setReadOnlyReportLoadEnded,
  setReadOnlyReportLoadStarted,
} from '../toolbar';
import { setReportGenericEventsDatasetsAction } from './setReportGenericEventsDatasetsAction';
import { setReportQueryDatasetsAction } from './setReportQueryDatasetsAction';
import { isEmpty, partial, some } from 'lodash';
import { sanitizeExportFileNameWDate } from 'helpers/string';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import IDimension from 'domains/dimensions/types';
import { dimensionToFilter } from 'helpers/types';
import { trackDownloadPresentation } from 'helpers/mixpanel';

export * from './setReportQueryDatasetsAction';

export const setReport = (payload: IReport | null): Action<IReport | null> => ({
  type: ActionType.REPORT_SET_REPORT,
  payload,
});

export const setReportQueryDaypartDatasetId = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_DAYPART_DATASET_ID,
  payload,
});

export const setReportQueryCrosswalkCombination = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_CROSSWALK_COMBINATION_ID,
  payload,
});

export const setReportName = (
  payload: string | undefined,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_REPORT_NAME,
  payload,
});

export const setRowLimit = (payload: number): Action<number> => ({
  type: ActionType.REPORT_SET_ROW_LIMIT,
  payload,
});

export const setSelectedCharts = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_SELECTED_CHARTS,
  payload,
});

export const setSelectedMetrics = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_SELECTED_METRICS,
  payload,
});

export const setSelectedTargets = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_SELECTED_TARGETS,
  payload,
});

export const setReportHasChanged = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_HAS_CHANGED,
  payload,
});

export const setBaseReportNotSaved = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_REPORT_NOT_SAVED,
});

export const setBaseReportSaved = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_REPORT_SAVED,
});

export const setIsDownloadingPresentation = (
  payload: boolean,
): Action<boolean> => ({
  type: ActionType.REPORT_SET_IS_DOWNLOADING_PRESENTATION,
  payload,
});

export const handleDownloadPresentation =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const lastValidReport = reportSelectors.getLastValidReport(state);
    const cacheQueryId = resultSelectors.getLastExecutedCacheQueryId(state);
    const subType = reportSelectors.getAttributionReportSubType(state);

    const targetsObject =
      reportSelectors.getAttributionReportTargetObjects(state);
    let payload = {
      lastValidReport,
      cacheQueryId,
      targetsObject,
    } as {
      lastValidReport: IReport;
      cacheQueryId: string;
      targetsObject: IOption[];
    };
    trackDownloadPresentation(lastValidReport);
    dispatch(setIsDownloadingPresentation(true));
    dispatch(
      processRules({
        isBaseReportSaving: true,
      }),
    );

    if (subType === SubTypeKeys.BRAND_LIFT) {
      const dimensionName: string = flow(
        reportSelectors.getBrandliftFilterFromLastValidReport,
        partial(get, ['children', 0, 'id']),
        getDimension,
        partial(getOr, '', 'name'),
      )(state);

      if (!isEmpty(dimensionName)) {
        payload = set(
          [
            'lastValidReport',
            'query',
            'filters',
            'children',
            1,
            'children',
            0,
            'name',
          ],
          dimensionName,
          payload,
        );
      }
    }
    const apiResponse = await fetchApi({
      endpoint: `${Index.SEGMENT_PRESENTATION}/`,
      method: FetchMethod.POST,
      payload,
      isDownload: true,
    });

    dispatch(setIsDownloadingPresentation(false));
    dispatch(
      processRules({
        isBaseReportSaving: false,
      }),
    );
    if (apiResponse.error?.message) {
      showErrorToast('Failed to download presentation.');
      return;
    }
    const blob = new Blob(get('data', apiResponse) as unknown as BlobPart[], {
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    const fileName = sanitizeExportFileNameWDate(
      lastValidReport?.name ?? 'New PPT',
    );
    link.setAttribute('download', `${fileName}.pptx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showSuccessToast('Downloaded report presentation successfully.');
  };

export const handleRowLimitChange =
  (payload: NewInputValue) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const displayChartData = resultSelectors.getDisplayChartData(
      get('reportResult', getState()),
    );
    const rowLimit =
      Number(payload) === 0
        ? (get('data', displayChartData) || []).length
        : Number(payload);
    dispatch(setRowLimit(Number(rowLimit)));
    const currentSGM = get('SGMConfig', displayChartData);
    const newChartData = {
      ...displayChartData,
      SGMConfig: {
        ...currentSGM,
        meta: {
          ...displayChartData.SGMConfig.meta,
          rowLimit,
        },
      },
    };
    dispatch(resultActions.setDisplayChartData(newChartData));
  };

export const handleChangeFilters =
  (filters: ChartFilterType[]) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const state = getState();
    const selectedMetrics: Metric[] = filters.map(
      (chartFilter: ChartFilterType) => chartFilter.metric as Metric,
    );

    const selectedCharts: ChartType[] = filters.map(
      (chartFilter: ChartFilterType) => chartFilter.chart as ChartType,
    );

    dispatch(setSelectedCharts(selectedCharts));
    dispatch(setSelectedMetrics(selectedMetrics));

    const [availableCharts, availableMetrics] = getAvailablePairs(
      selectedMetrics,
      selectedCharts,
    );

    const SGMConfig = resultSelectors.getDisplaySGMConfig(
      get('reportResult', state),
    );
    const reportRowLimit = reportSelectors.getRowLimit(get('report', state));
    const displayChartData = resultSelectors.getDisplayChartData(
      get('reportResult', state),
    );
    const chartData = resultSelectors.getResultChartData(
      get('reportResult', state),
    );
    const isAllViewing = resultSelectors.getIsAllViewing(
      get('reportResult', state),
    );
    const newMeta = isAllViewing
      ? {
          ...get('meta', SGMConfig),
          rowLimit: reportRowLimit,
        }
      : {
          ...get('meta', SGMConfig),
        };
    const originalData: DataRowGraph[] = get('data', chartData);

    const newSGM = {
      ...SGMConfig,
      meta: { ...newMeta },
      metrics: getMetricsFromQuery(
        availableMetrics,
        availableCharts,
      ) as MetricConfig[],
    };

    const newChartData = {
      ...displayChartData,
      SGMConfig: newSGM,
    };

    dispatch(
      resultActions.setDisplayChartData({
        ...newChartData,
        data: originalData,
        SGMConfig: newSGM,
      }),
    );
  };

export const setReportAction =
  (payload: IReport) =>
  (dispatch: Dispatch): void => {
    dispatch(setAvailableDimensions(payload));
    if (isReport(payload)) {
      dispatch(setAvailableMetrics(payload));
      dispatch(setAvailableIntervals(payload));
      dispatch(setAvailableOrderFields(payload));
      dispatch(setBaseReportNotSaved());
    }

    dispatch(setReport(payload));
  };

export const addNotPersistedProps = (
  payload: INotPersistedProps,
): Action<INotPersistedProps> => ({
  type: ActionType.REPORT_ADD_NOT_PERSISTED_PROPS,
  payload,
});

export const processRules =
  (payload: INotPersistedProps) =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(addNotPersistedProps(payload));
    await processRulesetWithRedux(
      reportSelectors.getReport(getState()),
      dispatch,
    );
  };

export const setQueryTimeFrame = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_TIME_FRAME,
  payload,
});

export const setQueryWeight = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_WEIGHT,
  payload,
});

export const setQueryIncludeTargetBreakdown = (
  payload: boolean,
): Action<boolean> => ({
  type: ActionType.REPORT_SET_QUERY_INCLUDE_BREAKDOWN,
  payload,
});

export const setQueryLevel = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_LEVEL,
  payload,
});

export const setQueryTimeZone = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_TIME_ZONE,
  payload,
});

export const setQuerySampleFactor = (payload: number): Action<number> => ({
  type: ActionType.REPORT_SET_QUERY_SAMPLE_FACTOR,
  payload,
});

export const setQueryTargets = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_QUERY_TARGETS,
  payload,
});

export const setQueryMode = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_MODE,
  payload,
});

export const resetCustomExposureSegmentRules = (): PayloadLessAction => ({
  type: ActionType.REPORT_RESET_CUSTOM_EXPOSURE_SEGMENT_RULES,
});

export const setUpdatedReport = (payload: IReport): Action<IReport> => ({
  type: ActionType.REPORT_SET_UPDATED_REPORT,
  payload,
});

export const setSavedReport = (payload: IReport): Action<IReport> => ({
  type: ActionType.REPORT_SET_SAVED_REPORT,
  payload,
});

export const setLastValidReport = (payload: IReport): Action<IReport> => ({
  type: ActionType.REPORT_SET_LAST_VALID_REPORT,
  payload,
});

export const resetLastValidReport = (): PayloadLessAction => ({
  type: ActionType.REPORT_RESET_LAST_VALID_REPORT,
});

export const setShowConfirmationDialog = (
  payload: IConfirmDialog,
): Action<IConfirmDialog> => ({
  type: ActionType.REPORT_SHOW_CONFIRMATION_DIALOG,
  payload,
});

export const setShowDialogModal = (
  payload: IModalDialog,
): Action<IModalDialog> => ({
  type: ActionType.REPORT_SHOW_MODAL_DIALOG,
  payload,
});

export const setAcceptDialogModal = (): PayloadLessAction => ({
  type: ActionType.REPORT_ACCEPT_MODAL_DIALOG,
});

export const setRealQueryMode = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_REAL_QUERY_MODE,
  payload,
});

export const setQueryFilters = (
  payload: IQueryFilters,
): Action<IQueryFilters> => ({
  type: ActionType.REPORT_SET_QUERY_FILTERS,
  payload,
});

export const setBreakoutCategoriesFilters = (
  payload: RuleFilter[],
): Action<RuleFilter[]> => ({
  type: ActionType.REPORT_SET_BREAKOUT_CATEGORIES_FILTERS,
  payload,
});

export const setExposureFlightDateStartDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_START,
  payload,
});
export const setExposureFlightDateEndDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_END,
  payload,
});

export const setExposureFlightDateRelativeOffset = (
  payload?: string,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_RELATIVE_OFFSET,
  payload,
});

export const setOutcomeViewershipStartDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_START_DATE,
  payload,
});

export const setOutcomeViewershipEndDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_END_DATE,
  payload,
});

export const setOutcomeViewershipRelativeDateOffset = (
  payload?: string,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_RELATIVE_DATE_OFFSET,
  payload,
});

export const setAggregationMethod = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_AGGREGATION_METHOD,
  payload,
});

export const setReportQueryFilterStartDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_FILTER_START_DATE,
  payload,
});

export const setReportQueryFilterEndDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_QUERY_FILTER_END_DATE,
  payload,
});

export const setReportQueryFilterRelativeDateOffset = (
  payload?: string,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_QUERY_FILTER_DATE_OFFSET,
  payload,
});

export const setReportQueryDatasets = (
  payload: string[],
): Action<string[]> => ({
  type: ActionType.REPORT_SET_QUERY_DATASETS,
  payload,
});

export const setReportQueryGenericEventsDatasets = (
  payload: IDataset[],
): Action<IDataset[]> => ({
  type: ActionType.REPORT_SET_QUERY_GENERIC_EVENTS_DATASETS,
  payload,
});

export const toggleReportModal = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_TOGGLE_MODAL,
  payload,
});

export const setIsReportInvalid = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_IS_REPORT_INVALID,
  payload,
});

export const setReportDynamicBreakouts = (
  payload: DynamicBreakouts,
): Action<DynamicBreakouts> => ({
  type: ActionType.REPORT_SET_DYNAMIC_BREAKOUTS,
  payload,
});

export const resetReportDynamicBreakouts = (): PayloadLessAction => ({
  type: ActionType.REPORT_RESET_DYNAMIC_BREAKOUTS,
});

export const setReportPerformanceMetricGroups = (
  payload: string[],
): Action<string[]> => ({
  type: ActionType.REPORT_SET_PEFORMANCE_METRIC_GROUPS,
  payload,
});

export const setQueryPerformanceMetricGroups = (
  payload: string[],
): Action<string[]> => ({
  type: ActionType.REPORT_SET_QUERY_PERFORMANCE_METRIC_GROUPS,
  payload,
});

export const setReportConversionType = (payload: string): Action<string> => ({
  type: ActionType.REPORT_SET_CONVERSION_TYPE,
  payload,
});

export const setQueryConversionType = (
  payload?: string,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_QUERY_CONVERSION_TYPE,
  payload,
});

export const setGenericConversionsMinDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_CONVERSION_MIN_DATE,
  payload,
});

export const setGenericConversionsMaxDate = (
  payload: string,
): Action<string> => ({
  type: ActionType.REPORT_SET_CONVERSION_MAX_DATE,
  payload,
});

export const resetPerformanceMetricsAndConversionType =
  () => (dispatch: Dispatch) => {
    dispatch(setQueryPerformanceMetricGroups(['converters']));
    dispatch(setReportPerformanceMetricGroups(['converters']));
    dispatch(setQueryConversionType());
    dispatch(setReportConversionType(''));
    dispatch(setGenericConversionsMinDate(''));
    dispatch(setGenericConversionsMaxDate(''));
  };

export const setAccordionResultsOpen = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_ACCORDION_RESULTS_OPEN,
});

export const setAccordionQueryOpen = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_ACCORDION_QUERY_OPEN,
});

export const setAccordionQueryClosed = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_ACCORDION_QUERY_CLOSED,
});

export const setAccordionSettingsOpen = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_ACCORDION_SETTINGS_OPEN,
});

export const setAccordionMethodologyOpen = (): PayloadLessAction => ({
  type: ActionType.REPORT_SET_ACCORDION_METHODOLOGY_OPEN,
});

export const processRulesetThunk =
  (report: IReport) =>
  (dispatch: Dispatch): void => {
    if (!report) return;
    processRulesetWithRedux(report, dispatch).then(noop);
  };

export const setAggregationMethodAction =
  (aggregationMethod: string) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    dispatch(setAggregationMethod(aggregationMethod));
  };

export const setFilterAction =
  (childFilters: RuleFilter[], segment: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    let updatedChildren: IChildrenBase;
    const report = reportSelectors.getReport(getState());
    const { filters: currentFilters } = report.query;
    if (segment === AttributionFilterType.brandLift) {
      const brandLiftFilter = getAttributionFiltersFromQueryFilters(
        currentFilters.children,
        AttributionFilterType.brandLift,
      );
      if (
        !childFilters[0] ||
        (brandLiftFilter[0] &&
          childFilters[0] &&
          brandLiftFilter[0].id !== childFilters[0].id)
      ) {
        dispatch(resetPerformanceMetricsAndConversionType());
      }

      updatedChildren = updateAttributionFiltersFromQueryFilters(
        currentFilters.children as IQueryFilters<IChildrenBase>[],
        childFilters,
        [segment],
      );
    } else {
      updatedChildren = updateNonDateAttributionFiltersFromQueryFilters(
        currentFilters.children as IQueryFilters<IChildrenBase>[],
        childFilters,
        segment,
      );
    }
    const updatedReport = reportSelectors.getReport(getState());
    removeEmptyTreeValues(updatedChildren);
    const newReport = set(
      'query.filters.children',
      updatedChildren,
      updatedReport,
    );
    dispatch(setReportAction(newReport));
  };

export const addExposureAdFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.adDetails,
    ) as RuleFilter[];

    await dispatch(setExposureAdFiltersAction([...filters, ruleFilter]));
  };

export const replaceExposureAdFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.adDetails,
    ) as RuleFilter[];

    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );

    filters[index] = ruleFilter;

    await dispatch(setExposureAdFiltersAction(filters));
  };

export const deleteExposureAdFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.adDetails,
    ) as RuleFilter[];

    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );

    await dispatch(
      setExposureAdFiltersAction(filters.filter(filterNotIndex(index))),
    );
  };

export const setExposureAdFiltersAction =
  (ruleFilterList: RuleFilter[]) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(
      setFilterAction(ruleFilterList, AttributionFilterType.adDetails),
    );
  };

export const addOutcomeViewershipFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.viewership,
    ) as RuleFilter[];

    await dispatch(setOutcomeViewershipFiltersAction([...filters, ruleFilter]));
  };

export const replaceOutcomeViewershipFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.viewership,
    ) as RuleFilter[];

    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );
    filters[index] = ruleFilter;

    await dispatch(setOutcomeViewershipFiltersAction(filters));
  };

export const deleteOutcomeViewershipFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.viewership,
    ) as RuleFilter[];
    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );
    await dispatch(
      setOutcomeViewershipFiltersAction(filters.filter(filterNotIndex(index))),
    );
  };

export const setOutcomeViewershipFiltersAction =
  (ruleFilterList: RuleFilter[]) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(
      setFilterAction(ruleFilterList, AttributionFilterType.viewership),
    );
  };

export const addOutcomeBrandLiftFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.brandLift,
    ) as RuleFilter[];

    await dispatch(setOutcomeBrandLiftFiltersAction([...filters, ruleFilter]));
  };

export const replaceOutcomeBrandLiftFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.brandLift,
    ) as RuleFilter[];

    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );

    filters[index] = ruleFilter;

    await dispatch(setOutcomeBrandLiftFiltersAction(filters));
  };

export const deleteOutcomeBrandLiftFiltersAction =
  (ruleFilter: RuleFilter) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const filters = getAttributionFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
      AttributionFilterType.brandLift,
    ) as RuleFilter[];

    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );

    await dispatch(
      setOutcomeBrandLiftFiltersAction(filters.filter(filterNotIndex(index))),
    );
  };

export const setOutcomeBrandLiftFiltersAction =
  (ruleFilterList: RuleFilter[]) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(
      setFilterAction(ruleFilterList, AttributionFilterType.brandLift),
    );
  };

export const addQueryFilterAction =
  (filter: RuleFilter) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const filters = getFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
    );

    dispatch(setQueryFiltersAction([...filters, filter]));
  };

export const handleExperianBreakoutsChange =
  (filterValues: ITextValue[]) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const availableRulesDimensions: IDimension[] =
      businessDataSelectors.getAvailableRulesDimensions(getState()) ?? [];
    const hydratedFilters = compact(
      filterValues.map((f) => {
        const dimension = availableRulesDimensions.find(
          (d) => d.id === f.value,
        );
        if (!dimension) return;
        return dimensionToFilter(dimension, 'EXPERIAN_BREAKOUTS');
      }) ?? [],
    );
    const breakoutCategories: RuleFilter[] =
      reportSelectors.getBreakoutCategoriesFilters(getState()) ?? [];

    const filteredBreakoutCategories = breakoutCategories.filter(
      (f) => f.type !== 'EXPERIAN_BREAKOUTS',
    );

    dispatch(
      setBreakoutCategoriesFilters([
        ...filteredBreakoutCategories,
        ...hydratedFilters,
      ]),
    );
  };

export const handleExperianPIQChange =
  (
    value: (ITextValue | string) | (ITextValue | string)[],
    field: string,
    key?: keyof RuleFilter,
  ) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    const experianPIQFilters: RuleFilter[] = flow(
      reportSelectors.getExperianPIQFilterChildren,
      cloneDeep,
    )(getState());
    const breakoutCategories = flow(
      reportSelectors.getBreakoutCategories,
      get('children'),
      cloneDeep,
    )(getState());
    const filterIndex: number = findIndex(
      (filter) => filter.field === field,
      experianPIQFilters,
    );
    const filter = experianPIQFilters?.splice(filterIndex, 1)[0];
    let newFilter: RuleFilter = set(key as string, value, filter);

    experianPIQFilters?.splice(filterIndex, 0, newFilter);

    const PIQIndex = findIndex(
      flow(get('field'), isEqual('PLACEIQ')),
      breakoutCategories,
    );

    const newBreakoutFilters = set(
      [PIQIndex, 'children'],
      experianPIQFilters,
      breakoutCategories,
    );
    dispatch(setBreakoutCategoriesFilters(newBreakoutFilters));
  };
export const replaceQueryFilterAction =
  (filter: RuleFilter) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    let updateRuleFilter = filter;
    const filters = getFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
    );

    const report = reportSelectors.getReport(getState());
    // For some filters there needs to be a type specific default
    if (isTarget(report)) {
      updateRuleFilter = getDefaultTargetSpecificValues(filter);
    }

    const index = filters.findIndex(
      (f) => f.filterId === updateRuleFilter.filterId,
    );

    filters[index] = updateRuleFilter;

    dispatch(setQueryFiltersAction(filters));
  };

export const deleteQueryFilterAction =
  (ruleFilter: RuleFilter) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const filters = getFiltersFromQueryFilters(
      reportSelectors.getFiltersChildren(getState()),
    );
    const index = filters.findIndex(
      (filter) => filter.filterId === ruleFilter.filterId,
    );
    dispatch(deleteMessagesForID(filters[index]['filterId']));
    dispatch(setQueryFiltersAction(filters.filter(filterNotIndex(index))));
  };

export const setQueryFiltersAction =
  (payload: RuleFilter[]) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    const report = reportSelectors.getReport(getState());
    if (!report) return;
    const { query } = report;
    const metrics = reportReducer.getMetrics(report) as unknown as string[];
    const { filters: currentFilters } = query;
    const newFilters = getQueryFiltersWithNewRules(payload, currentFilters);
    removeEmptyTreeValues(newFilters);
    let newReport = reportReducer.setQueryFilters(newFilters, report);
    const hasPlatformVODSelected = reportFiltersHasVOD(newReport);
    const isAverageAudienceMetricSelected = metrics.includes('AVG_AUDIENCE');
    const isRatingMetricSelected = metrics.includes('RATING');
    if (
      hasPlatformVODSelected &&
      (isAverageAudienceMetricSelected || isRatingMetricSelected)
    ) {
      newReport = reportReducer.setMetrics(
        difference(metrics, ['AVG_AUDIENCE', 'RATING']),
        newReport,
      );
      newReport = reportReducer.setBaseReportSaved(false, newReport);
      document.dispatchEvent(eventDropdownShouldClose);
      const confirmDialog = {
        confirmationDialogMessage: LOCALE_INVALID_VOD_METRICS_MESSAGE,
        confirmationDialogHeader: LOCALE_CONFIRMATION_DIALOG_TITLE,
        confirmationDialogOK: LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL,
      };
      dispatch(setUpdatedReport(newReport));
      dispatch(setShowConfirmationDialog(confirmDialog));
    } else {
      dispatch(setAvailableMetrics(newReport));
      dispatch(setQueryFilters(newFilters));
      dispatch(setBaseReportNotSaved());
    }
  };

export const setQueryModeAction =
  (dataset: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(resetReportDynamicBreakouts());
    dispatch(resetRelativeDateOffsetByReportType());
    let datasetCopy = dataset;

    let state = getState();
    const report = reportSelectors.getReport(state);
    if (!report) return;
    const realDataset = datasetCopy;
    let newReport = reportReducer.setQueryMode(datasetCopy, report);
    newReport = reportReducer.setRealQueryMode(realDataset, newReport);

    const domainsDateRanges = domainsSelectors.getDomainsDateRanges(state);

    if (isReport(newReport) || isTarget(newReport)) {
      if (dataset === Modes.customAdverts || dataset === Modes.adverts) {
        newReport.query.group = newReport.query.group.filter(
          (e: string) => !['SOURCE_NAME', 'SOURCE_TYPE'].includes(e),
        );
        newReport.query.filters.children =
          newReport.query.filters.children.filter(
            (item: RuleFilter) =>
              !['SOURCE_NAME', 'SOURCE_TYPE'].includes(item.field as string),
          );
      }
    }
    newReport = reportReducer.setQueryDatasets([], newReport);
    setQueryModeDecision(
      datasetCopy as Mode,
      realDataset as Mode,
      dispatch,
      newReport,
      domainsDateRanges,
    );

    state = getState();
    const filtersChildren = reportSelectors.getFiltersChildren(state);
    let attributionFilters = getAttributionFiltersFromQueryFilters(
      filtersChildren,
      AttributionFilterType.adDetails,
    ) as RuleFilter[];
    // If its a generic events dataset we want to remove the viewing threshold rule
    if (realDataset === 'MODE_GENERIC_EVENTS') {
      let genericEventsAttributionFilters = attributionFilters.filter(
        (child) => {
          return (
            child.id !== 'AD_TUNING_DURATION' &&
            child.id !== 'SHARED_NETWORK_OWNER'
          );
        },
      ) as RuleFilter[];

      // replace CREATIVE_NAME_GROUP with CREATIVE_LABEL when switching to Custom Exposures
      genericEventsAttributionFilters = genericEventsAttributionFilters.map(
        (child) => {
          if (child.id !== 'CREATIVE_NAME_GROUP') {
            return child;
          } else {
            return {
              ...child,
              id: 'CREATIVE_LABEL',
              field: 'CREATIVE_LABEL',
            };
          }
        },
      ) as RuleFilter[];

      await dispatch(
        setExposureAdFiltersAction(genericEventsAttributionFilters),
      );
      dispatch(setEqMetric(false));
      dispatch(handleChangeGenericEventsDataset([]));
      return;
    }

    // replace CREATIVE_LABEL with CREATIVE_NAME_GROUP when switching from Custom Exposures
    attributionFilters = attributionFilters.map((child) => {
      if (child.id !== 'CREATIVE_LABEL') {
        return child;
      } else {
        return {
          ...child,
          ...newFilter(),
          id: 'CREATIVE_NAME_GROUP',
          field: 'CREATIVE_NAME_GROUP',
        };
      }
    }) as RuleFilter[];

    // Add back in the viewing threshold rule if it is missing
    const filtersChildrenHaveExposureAds = filtersChildren?.some(
      (filter: { [name: string]: string }) =>
        filter['name'] === AttributionFilterType.adDetails,
    );
    const attrFiltersHaveAdTuning = attributionFilters.some(
      (filteredRule) => filteredRule.id === 'AD_TUNING_DURATION',
    );

    if (filtersChildrenHaveExposureAds && !attrFiltersHaveAdTuning) {
      const adTuningDurationRule = {
        ...newFilter(),
        field: 'AD_TUNING_DURATION',
        id: 'AD_TUNING_DURATION',
        type: FilterType.SIMPLE,
        operator: 'GT' as Operator,
        value: '2',
        operator_type: 'DURATION_COMPARISON',
      } as RuleFilter;
      const revertedExposureAdFilterChildren = [
        adTuningDurationRule,
        ...attributionFilters,
      ];
      await dispatch(
        setExposureAdFiltersAction(revertedExposureAdFilterChildren),
      );
    }

    dispatch(deleteMessagesForID('dataset_value'));
  };

export const setInterval = (payload: string): Action<IIdName> => {
  const availableIntervals = getAvailableIntervals(store.getState());
  let curatedInterval = (availableIntervals ?? []).find(
    (selInterval: ITextValue) => selInterval.value === payload,
  );
  curatedInterval = {
    id: curatedInterval?.value,
    name: curatedInterval?.text,
  };
  return {
    type: ActionType.REPORT_SET_INTERVAL,
    payload: curatedInterval,
  };
};

export const setSorting = (sorting: IReportSorting): Action<ISetSorting> => {
  const state = store.getState();
  return {
    type: ActionType.REPORT_SET_SORTING,
    payload: {
      businessData: get('businessData', state),
      sorting,
    },
  };
};
export const setSortOrder = (
  sortOrder: IReportQueryInterval,
): Action<IReportQueryInterval> => ({
  type: ActionType.REPORT_SET_SORT_ORDER,
  payload: sortOrder,
});
export const setSortField = (sortField: ITextValue): Action<ITextValue> => ({
  type: ActionType.REPORT_SET_SORT_FIELD,
  payload: sortField,
});
export const setQuerySorting = (
  sorting: IReportSorting,
): Action<IReportSorting> => ({
  type: ActionType.REPORT_SET_QUERY_SORTING,
  payload: sorting,
});

export const setEqMetric = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_EQ_METRIC,
  payload,
});

export const setCumulativeGroup = (
  payload: string | undefined,
): Action<string | undefined> => ({
  type: ActionType.REPORT_SET_CUMULATIVE_GROUP,
  payload,
});

export const setIntervalAction =
  (payload: string) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const report = reportSelectors.getReport(getState());
    if (!report) return;
    const queryIsCume = isCumulative(get('query', report));
    dispatch(setInterval(payload));
    if (queryIsCume) {
      dispatch(setCumulativeGroup(payload));
      dispatch(
        setSorting({
          order: 'ASC',
          field: payload,
        }),
      );
    }
    dispatch(setBaseReportNotSaved());
  };

export const setReportRulesetEvents = (
  payload: string[],
): Action<string[]> => ({
  type: ActionType.REPORT_SET_RULESET_EVENTS,
  payload,
});

export const setMetrics = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_METRICS,
  payload,
});

export const setMetricsAction =
  (selectedMetrics: ITextValue[]) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const cleanedMetrics = selectedMetrics.map(
      (metric) => metric.value,
    ) as string[];
    dispatch(setMetrics(cleanedMetrics));
    dispatch(setAvailableOrderFields(reportSelectors.getReport(getState())));

    const metrics = reportReducer.getMetrics(
      reportSelectors.getReport(getState()),
    );
    const sorting = reportReducer.getQuerySorting(
      reportSelectors.getReport(getState()),
    );
    const queryIsCumulative = isCumulative(
      reportSelectors.getReport(getState()).query,
    );

    if (!queryIsCumulative && !metrics.includes(sorting.field)) {
      dispatch(
        setSorting({
          order: sorting.order,
          field: metrics[0],
        }),
      );
    }
    dispatch(setBaseReportNotSaved());
  };

export const setDimensions = (payload: string[]): Action<string[]> => ({
  type: ActionType.REPORT_SET_DIMENSIONS,
  payload,
});

export const setDimensionsAction =
  (payload: ITextValue[]) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const dimensionValues = payload.map(
      (dimension) => dimension.value,
    ) as string[];
    dispatch(setDimensions(dimensionValues));

    // order matters: must get state after updated dimensions are set!
    const state = getState();
    const report = reportSelectors.getReport(state);

    dispatch(setAvailableOrderFields(report));
    const metrics = reportReducer.getMetrics(report);
    const dimensions = reportReducer.getDimensions(report);
    const sorting = reportReducer.getQuerySorting(report);
    const queryIsCumulative = isCumulative(report.query);

    if (
      !queryIsCumulative &&
      ![...metrics, ...dimensions].includes(sorting.field)
    ) {
      dispatch(
        setSorting({
          order: sorting.order,
          field: metrics.length ? metrics[0] : dimensions[0],
        }),
      );
    }
    dispatch(setBaseReportNotSaved());
  };

export const setMetricTypeAction =
  (checked: boolean) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const defaultInterval = { id: 'DAY', name: 'Day' };
    const metricType = checked ? MetricTypes.cumulative : MetricTypes.normal;
    const state = getState();
    let newReport = reportSelectors.getReport(state);
    let interval = reportReducer.getInterval(newReport);
    const defaultSort: IReportQueryInterval = {
      id: 'ASC',
      name: reportSortingOrder.ASC,
    }; // todo, pick from a common space

    const cumulativeGroup =
      metricType === MetricTypes.cumulative
        ? interval?.id ?? defaultInterval.id
        : '';

    dispatch(setCumulativeGroup(cumulativeGroup));
    if (metricType === 'cumulative') {
      if (interval?.id === 'ALL_VIEWING') {
        dispatch(setCumulativeGroup(defaultInterval.id));
        dispatch(setInterval(defaultInterval.id));
        interval = defaultInterval;
      }

      const sorting: IReportSorting = {
        field: interval?.id,
        order: defaultSort.id as ReportSortingOrder,
      };
      dispatch(setSortField(interval));
      dispatch(setSortOrder(defaultSort));
      dispatch(setQuerySorting(sorting));
    } else {
      newReport = reportSelectors.getReport(getState());

      const availableOrderFields = getAvailableOrderFields(newReport);
      // set first option as choice
      const firstResult = availableOrderFields[0];
      const sorting: IReportSorting = {
        field: firstResult.value as string,
        order: defaultSort.id as ReportSortingOrder,
      };
      dispatch(setSortField(firstResult));
      dispatch(setSortOrder(defaultSort));
      dispatch(setQuerySorting(sorting));
    }
    newReport = reportSelectors.getReport(getState());
    setMetricTypeDecision(dispatch, newReport);
  };

export const setTableVisibleAction =
  (payload: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch(setTableVisible(payload));
    dispatch(setBaseReportNotSaved());
  };

export const setTableVisible = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_TABLE_VISIBLE,
  payload,
});

export const setChartVisibleAction =
  (payload: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch(setChartVisible(payload));
    dispatch(setBaseReportNotSaved());
  };

export const setChartVisible = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_CHART_VISIBLE,
  payload,
});

export const setPersistedReport = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_PERSISTED_REPORT,
  payload,
});

export const handlePersistedReportChange =
  (payload: boolean) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state);
    dispatch(
      processRules({
        isBaseReportSaving: true,
      }),
    );
    const reportData = await fetchApi({
      endpoint: `${Index.SEGMENT_REPORTS}`,
      method: FetchMethod.PATCH,
      payload: {
        id: report.id,
        persistedReport: payload,
      },
    });
    if (reportData.error) {
      showErrorToast(reportData.error?.message ?? 'Unknown error');
      dispatch(processRules({ isBaseReportSaving: false }));
      return;
    }
    dispatch(processRules({ isBaseReportSaving: false }));
    showSuccessToast('Report updated');
    dispatch(setPersistedReport(payload));
  };

export const showReportPayload =
  () =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(executionShowReportPayload());
  };

export const runCachedQuery =
  () =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(executionReportInitialize(cacheMode.always));
  };

export const runQuery =
  (cache: cacheMode) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(executionReportInitialize(cache));
  };

export const cancelQuery =
  (doConfirm?: boolean) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    if (doConfirm) {
      dispatch(
        processRules({
          isBaseReportRefreshing: false,
          isBaseReportRunning: false,
          isAccordionQueryOpen: true,
          isAccordionResultsOpen: false,
          isBaseReportCanceling: true,
        }),
      );
      return;
    }
    const confirmDialog = {
      confirmationDialogHeader: LOCALE_DELETE_STOP_QUERY_HEADER,
      confirmationDialogMessage: LOCALE_DELETE_STOP_QUERY_TEXT,
      confirmationDialogType: confirmDialogType.cancelReport,
      confirmationDialogOK: LOCALE_CANCEL_REPORT_RUN,
      confirmationDialogCancel: LOCALE_GO_BACK,
    };
    dispatch(setShowConfirmationDialog(confirmDialog));
  };

export const runDownloadQuery =
  (cache: cacheMode) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(executionReportInitialize(cache, false, true, false));
  };

export const runFileUploadQuery =
  (cache: cacheMode) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    await dispatch(executionReportInitialize(cache, false, true, true));
  };

export const loadBusinessData =
  (selectedClient: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    // Load business data
    const businessData = await fetchApi({
      endpoint: `${Index.SEGMENT_REPORTS}/${Index.SEGMENT_BUSINESS_DATA}?clientId=${selectedClient}`,
      method: FetchMethod.GET,
    });
    await dispatch(addBusinessData(businessData.data[0] as IBusinessData));
  };

export const handleToggleReadOnly =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(setReadOnlyReportLoadStarted());
    const state = getState();
    const report = reportSelectors.getReport(state);
    const reportId = report.id;
    const requestResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/${Actions.SEGMENT_TOGGLE_IS_READ_ONLY}/${reportId}`,
      method: FetchMethod.PATCH,
    });
    const data = requestResponse.meta?.changed as IReport;
    const isReadOnly = data?.isReadOnly;
    dispatch(setReport({ ...report, isReadOnly }));
    dispatch(setReadOnlyReportLoadEnded());
  };

export const loadReport =
  (reportId: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<IReport> => {
    const state = getState();
    const selectedClient = getSelectedClient(state);
    const reportData = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/${reportId}`,
      payload: {
        clientId: selectedClient,
      },
      method: FetchMethod.POST,
    });
    if (reportData.error) {
      throw new Error(reportData.error?.message ?? 'Unknown error');
    }
    const fetchedReport = reportData.data[0] as IReport;

    const report = {
      ...fetchedReport,
      notPersistedProps: {
        ...newNotPersistedProps(fetchedReport),
      },
    };
    // Load datasets if needed
    const { datasets, mode } = report.query;
    if (mode !== Modes.linear && datasets?.length) {
      const isCustomExposure = mode === Modes.genericEvents;
      const updatedReport = (
        isCustomExposure
          ? await dispatch(
              setReportGenericEventsDatasetsAction(datasets[0], report, true),
            )
          : await dispatch(setReportQueryDatasetsAction(datasets, report, true))
      ) as IReport;
      // temporal tweak while we don't have a specific mode for customAds on mongo
      if (updatedReport?.notPersistedProps) {
        updatedReport.notPersistedProps.mode = isCustomExposure
          ? Modes.genericEvents
          : Modes.customAdverts;
      }
      dispatch(setSavedReport(updatedReport));
      return updatedReport;
    }

    await dispatch(processRulesetThunk(report));
    dispatch(setReportAction(report));
    dispatch(setDaypartDatasetIdToReport());
    dispatch(setSavedReport(report));
    dispatch(loadExperianBreakoutsAction());

    return report;
  };

export const loadExperianBreakoutsAction =
  () =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): IReport => {
    const report = reportSelectors.getReport(getState());
    if (isTarget(report)) {
      const availableExperianBreakouts: IDimension[] =
        getTargetAvailableBreakouts(getState());

      const experianBreakouts: RuleFilter[] = flow(
        reportSelectors.getExperianBreakoutFilters,
        filter((breakout: RuleFilter) =>
          some(availableExperianBreakouts, (dim) => dim.id === breakout.field),
        ),
      )({ report });
      const PIQBreakouts: RuleFilter = reportSelectors.getExperianPIQFilter({
        report,
      });

      dispatch(
        setBreakoutCategoriesFilters([PIQBreakouts, ...experianBreakouts]),
      );
    }
    return reportSelectors.getReport(getState());
  };

export const loadEvents =
  (report: IReport) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const eventsData = await fetchApi({
      endpoint: `/${Index.SEGMENT_EVENTS}/${report.id}`,
      method: FetchMethod.GET,
    });
    const events = eventsData.data as IEvent[];
    dispatch(setEvents(events));
  };

export const setReportLoaded = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_LOADED,
  payload,
});

export const setReportLoading = (payload: boolean): Action<boolean> => ({
  type: ActionType.REPORT_SET_LOADING,
  payload,
});

export const setBaseReportFullyLoaded = (
  payload: boolean,
): Action<boolean> => ({
  type: ActionType.REPORT_SET_FULLY_LOADED,
  payload,
});

export const loadNewAttributionReport =
  (subType: string) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const state = store.getState();
    const selectedClient = getSelectedClient(state);
    const userId = getId(state);
    const messageList = messageListSelectors.getMessageList(state);
    if (messageList.length) await dispatch(initializeMessageList());
    await dispatch(setReportLoaded(false));
    await dispatch(setReportLoading(true));
    await dispatch(loadBusinessData(selectedClient));
    await dispatch(fetchTargetsAction());
    const blankReport = getNewAttributionReport(
      subType,
      domainsSelectors.getDomainsDateRanges(state),
      userId,
      Modes.adverts,
      selectedClient,
    );
    await dispatch(setReportAction(blankReport));
    await dispatch(processRulesetThunk(blankReport));
    await dispatch(setSavedReport(blankReport));
    await dispatch(resetAttributionReportResult());
    dispatch(setDaypartDatasetIdToReport());
    await dispatch(setReportLoaded(true));
    await dispatch(setReportLoading(false));
    await dispatch(setBaseReportFullyLoaded(true));
  };

export const loadNewReport =
  (isTarget: boolean, subType?: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = store.getState();
    const selectedClient = getSelectedClient(state);
    const userId = getId(state);
    const messageList = messageListSelectors.getMessageList(state);
    if (messageList.length) await dispatch(initializeMessageList());
    await dispatch(setReportLoaded(false));
    await dispatch(setReportLoading(true));
    await dispatch(loadBusinessData(selectedClient));
    await dispatch(fetchTargetsAction());
    const domainsDateRanges = domainsSelectors.getDomainsDateRanges(state);
    let blankReport = isTarget
      ? getNewTarget(domainsDateRanges, userId, selectedClient)
      : getNewReport(domainsDateRanges, userId, selectedClient, subType);

    await dispatch(setReportAction(blankReport));
    dispatch(setDaypartDatasetIdToReport());
    await dispatch(processRulesetThunk(blankReport));
    await dispatch(setSavedReport(blankReport));
    await dispatch(setReportLoaded(true));
    await dispatch(setReportLoading(false));
    await dispatch(setBaseReportFullyLoaded(true));
    if (isTarget) {
      const availableRulesDimensions = getTargetAvailableBreakouts(getState());

      const defaultsExperianBreakouts: RuleFilter[] = compact(
        DEFAULT_EXPERIAN_VALUES.map((value: string) => {
          const dimension = availableRulesDimensions.find(
            (d: IDimension) => d.id === value,
          );
          if (!dimension) return;
          return dimensionToFilter(dimension, 'EXPERIAN_BREAKOUTS');
        }),
      );
      const experianPIQFilter: RuleFilter =
        reportSelectors.getExperianPIQFilter(getState());

      dispatch(
        setBreakoutCategoriesFilters([
          experianPIQFilter,
          ...defaultsExperianBreakouts,
        ]),
      );
    }
  };

export const setDaypartDatasetIdToReport =
  () =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const state = getState();
    const daypartDatasets = getDaypartDatasets(state);
    const standardDaypartDatasetId =
      getStandartDaypartDatasetId(daypartDatasets);

    const daypartDatasetId = flow(
      domainsSelectors.getClients,
      find(matchesProperty('id', userSelectors.getSelectedClient(state))),
      get('dataset_rights'),
      intersection(map(get('id'), daypartDatasets)),
      getOr(standardDaypartDatasetId, 0),
    )(state);

    cond([
      [
        flow(getQueryDaypartDatasetId, isEmpty),
        () => dispatch(setReportQueryDaypartDatasetId(daypartDatasetId)),
      ],
    ])(state);
  };

export const loadReportFull =
  (reportId: string, isTarget: boolean) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    try {
      const state = getState();
      const selectedClient = getSelectedClient(state);
      const messageList = messageListSelectors.getMessageList(state);
      if (messageList.length) await dispatch(initializeMessageList());
      await dispatch(setReportLoaded(false));
      await dispatch(setReportLoading(true));
      await dispatch(loadBusinessData(selectedClient));
      await dispatch(fetchTargetsAction());
      const loadedReport = await dispatch(loadReport(reportId));
      const userPermissionList = get('user.permissions', state);
      const type = get('type', loadedReport);
      const userCanAudit = checkPermissionTree(
        `${reportTypeEquivalences(type)}::audit`,
        userPermissionList,
      );
      if (userCanAudit) {
        await dispatch(loadEvents(loadedReport));
      }
      if (isTarget) {
        dispatch(setAccordionQueryOpen());
      }
      await dispatch(setReportLoaded(true));
      await dispatch(setReportLoading(false));
      await dispatch(
        processRules({
          ...loadedReport?.notPersistedProps,
          isBaseReportFullyLoaded: true,
        }),
      );
    } catch (error) {
      const confirmDialog = {
        confirmationDialogHeader: LOCALE_NOTIFICATION_HEADER,
        confirmationDialogMessage: (error as Error)?.message ?? 'Unknown Error',
        confirmationDialogOK: LOCALE_BACK_TO_REPORT_LIST,
        confirmationDialogType: confirmDialogType.errorReport,
      };
      dispatch(
        setShowConfirmationDialog(confirmDialog as unknown as IConfirmDialog),
      );
    }
  };

export const setUserConfirmation = (
  payload: IReport[] | undefined,
): Action<IReport[] | undefined> => ({
  type: payload
    ? ActionType.REPORT_ACCEPT_CONFIRMATION_DIALOG
    : ActionType.REPORT_CANCEL_CONFIRMATION_DIALOG,
  payload,
});

export const setUserConfirmationAction =
  (confirm: boolean) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    const report = reportReducer.getReport(getState());
    const updatedReport = reportReducer.getUpdatedReport(getState());
    const confirmationType = reportReducer.getConfirmationDialogType(report);
    switch (confirmationType) {
      case confirmDialogType.cancelReport:
        if (confirm) {
          dispatch(cancelQuery(true));
          dispatch(toolbarCancel(report));
          dispatch(resultActions.cancelExecutionJob(true));
        }
        dispatch(setUserConfirmation(confirm && report));
        break;

      case confirmDialogType.noResults:
        if (confirm) {
          dispatch(executionReportInitialize(cacheMode.default, true));
        } else {
          dispatch(executionReportReset());
        }
        dispatch(setUserConfirmation(confirm && report));

        break;

      case confirmDialogType.confirmLongQuery:
        if (confirm) {
          dispatch(executionReportInitialize(cacheMode.default, true));
        } else {
          dispatch(executionReportReset());
        }
        dispatch(setUserConfirmation(confirm && report));

        break;

      default: {
        dispatch(setUserConfirmation(confirm && updatedReport));

        if (!confirm) break;
        if (updatedReport.query.mode !== Modes.customAdverts) {
          dispatch(deleteMessagesForID('dataset_value'));
        }
        dispatch(setReportAction(updatedReport));
      }
    }
  };

export const handleQueryBuilderDatePickerChange =
  (
    selectedStartDate: string,
    selectedEndDate: string,
    relativeDateOffset?: string,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(setReportQueryFilterStartDate(selectedStartDate));
    dispatch(setReportQueryFilterEndDate(selectedEndDate));
    dispatch(setReportQueryFilterRelativeDateOffset(relativeDateOffset));
    dispatch(setBaseReportNotSaved());
  };

export const handleAttrReportsAdFlightDateChange =
  (
    selectedStartDate: string,
    selectedEndDate: string,
    relativeDateOffset?: string,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(setExposureFlightDateStartDate(selectedStartDate));
    dispatch(setExposureFlightDateEndDate(selectedEndDate));
    dispatch(setExposureFlightDateRelativeOffset(relativeDateOffset));
  };

export const handleAttrReportsViewershipDetailsDateChange =
  (
    selectedStartDate: string,
    selectedEndDate: string,
    relativeDateOffset?: string,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(setOutcomeViewershipStartDate(selectedStartDate));
    dispatch(setOutcomeViewershipEndDate(selectedEndDate));
    dispatch(setOutcomeViewershipRelativeDateOffset(relativeDateOffset));
  };

export const handleQueryBuilderOrderChange =
  (newSort: IReportSorting) =>
  (dispatch: Dispatch): void => {
    dispatch(setSorting(newSort));
    dispatch(setBaseReportNotSaved());
  };

export const handleQueryBuilderEqMetricChange =
  (newEqMetric: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch(setEqMetric(newEqMetric));
    dispatch(setBaseReportNotSaved());
  };

export const handleQueryBuilderTargetChange =
  (selectedTargets: ITextValue[]) =>
  (dispatch: Dispatch): void => {
    dispatch(
      setQueryTargets(selectedTargets.map((targ) => targ.value) as string[]),
    );
    dispatch(setBaseReportNotSaved());
  };

export const handleChangeScheduleDataset =
  (selectedDatasets: ITextValue[]) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    const datasetValues = selectedDatasets.map(
      (dataset: ITextValue) => dataset.value,
    ) as string[];
    dispatch(setReportQueryDatasetsAction(datasetValues));
  };

export const handleChangeGenericEventsDataset =
  (selectedDatasets: ITextValue[]) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    const datasetValues = selectedDatasets.map(
      (dataset: ITextValue) => dataset.value,
    ) as string[];
    dispatch(setReportQueryDatasets(datasetValues));
    dispatch(setReportGenericEventsDatasetsAction(datasetValues[0]));
  };

export const resetRelativeDateOffsetByReportType =
  () =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const state = getState();
    const report = reportSelectors.getReport(state);
    const type = report.type as ReportType;
    if (type === 'attribution-report') {
      dispatch(setExposureFlightDateRelativeOffset(undefined));
      dispatch(setOutcomeViewershipRelativeDateOffset(undefined));
      return;
    }
    dispatch(setReportQueryFilterRelativeDateOffset(undefined));
  };
