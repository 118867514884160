import * as constants from 'features/targets/constants';
import { get, flow } from 'lodash/fp';

export type State = {
  selectedMetric: string;
};

export const ALLOWED_METRICS = [
  {
    text: constants.LOCAL_UNIVERSE_METRICS,
    value: constants.LOCAL_UNIVERSE_METRICS,
    tooltip:
      '% Of Universe is the percent of households in the audience universe',
  },
  {
    text: constants.LOCAL_POPULATION_INDEX_METRICS,
    value: constants.LOCAL_POPULATION_INDEX_METRICS,
    tooltip:
      'Population index is the ratio of % of audience divided by the % of all households in the universe, multiplied by 100',
  },
];

export const getTargetData = get('targetResult.targetData');
export const getTarget = get('target');
export const getGenPop = get('genpop');
export const getUniverse = get('universe[0].universe');
export const getUniverseLabel = flow(getTargetData, get('universeLabel'));
export const getTargetUniverse = flow(getTargetData, getTarget, getUniverse);
const getGenPopUniverse = flow(getTargetData, getGenPop, getUniverse);
export const getTargetPercentage = (state: State): number => {
  const targetUniverse: number = getTargetUniverse(state);
  const genPopUniverse: number = getGenPopUniverse(state);
  return +((targetUniverse / genPopUniverse) * 100).toFixed(1);
};
export const getExportConfigOptions = get('targetResult.exportConfigOptions');
