import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

export const getToolbar = get('toolbar');

export const getCancelIsDisabled = flow(getToolbar, get('isCancelRunDisabled'));
export const getRefreshIsDisabled = flow(getToolbar, get('isRefreshDisabled'));
export const getRunIsDisabled = flow(getToolbar, get('isRunDisabled'));
export const getSaveIsDisabled = flow(getToolbar, get('isSaveDisabled'));
export const getSaveAsIsDisabled = flow(
  getToolbar,
  get('isSaveAsCopyDisabled'),
);
export const getLastExecutedAtCacheDate = flow(
  getToolbar,
  get('lastCachedDate'),
);

export const getRefreshLaunched = flow(getToolbar, get('isRefreshLaunched'));
export const getRunLaunched = flow(getToolbar, get('isRunLaunched'));
export const getProgress = flow(getToolbar, get('progress'));
export const getQueryProgress = flow(getToolbar, get('queryProgress'));
export const getType = flow(getToolbar, get('reportType'));
export const getReportNameDisabled = flow(
  getToolbar,
  get('isReportNameDisabled'),
);
export const getShowRefreshButton = flow(getToolbar, get('showRefreshButton'));
export const getExportIsDisabled = flow(getToolbar, get('isExportDisabled'));
export const getExportIsRunning = flow(getToolbar, get('isExportRunning'));
export const getIsLoadingReadOnlyToggle = flow(
  getToolbar,
  get('isLoadingReadOnlyToggle'),
);
