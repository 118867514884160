import Dropdown from 'components/Dropdown';
import DropdownSearch, {
  DropdownSearchQueryProvider,
} from 'components/DropdownSearch';
import Flex, { Horizontal } from 'components/Flex';
import { Type } from 'components/Input';
import SelectedChips from 'components/SelectedChips';
import { getClass, getTestId } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import { removeEmptyTreeValues } from 'helpers/removeEmptyTreeValues';
import { isEqual, noop } from 'lodash';
import isNil from 'lodash/isNil';
import React, { FocusEventHandler, ReactElement, useCallback } from 'react';
import { RuleFilter } from 'types/filter';
import ITextValue from 'types/textValue';

export const componentName = 'multiple-dropdown-select';

const SELECTOR_DOM_KEY = 'selector';
const SELECTED_CHIPS_WRAPPER_DOM_KEY = 'selected-chips-wrapper';

type Props = {
  customClass?: string;
  options?: ITextValue[];
  selected?: ITextValue[];
  onChange?: (selected: ITextValue[]) => void;
  testId?: string;
  trackingId?: string;
  trackingRuleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  };
  disabled?: boolean;
  placeholder?: string;
  queryProvider?: DropdownSearchQueryProvider;
  loading?: boolean;
  ignoreGrouping?: boolean;
  minimumChipsSelected?: number;
  maximumChipsSelected?: number;
  noPlaceholderValue?: boolean;
  withSearch?: boolean;
  canReorder?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  errorText?: string;
  type?: Type.text | Type.search;
  isReportInvalid?: boolean;
  portalContainerClass?: string;
  enableHighlight?: boolean;
  fetchedOptions?: boolean;
  pageSize?: number;
};

const MAXIMUM_CHIPS_UNLIMITED = -1;

const MultipleDropdownSelect = (props: Props): ReactElement => {
  const {
    customClass,
    options = [],
    selected = [],
    onChange,
    onBlur,
    testId,
    trackingId,
    trackingRuleContext,
    disabled = false,
    placeholder,
    queryProvider,
    loading = false,
    ignoreGrouping,
    minimumChipsSelected = 0,
    maximumChipsSelected = MAXIMUM_CHIPS_UNLIMITED,
    noPlaceholderValue,
    withSearch,
    type = Type.text,
    canReorder = true,
    errorText = '',
    isReportInvalid,
    portalContainerClass,
    enableHighlight = false,
    name,
    fetchedOptions = false,
    pageSize = 10,
  } = props;

  const componentTestId = getTestId(componentName, testId);
  const componentClass = getClass(componentName, { add: [customClass] });
  const componentSelectorClass = getClass(componentName, {
    concat: [SELECTOR_DOM_KEY],
  });
  const componentSelectedChipsWrapperClass = getClass(componentName, {
    concat: [SELECTED_CHIPS_WRAPPER_DOM_KEY],
  });

  const handleDropdownChange = (option: string | number | boolean): void => {
    if (isNil(option) || !onChange) return;
    const foundItemIndex: number = selected.findIndex(
      (item) => item.value?.toString() === option?.toString(),
    );
    if (foundItemIndex !== -1 && !selected[foundItemIndex].fixed) {
      if (selected.length - 1 < minimumChipsSelected) {
        onChange(selected);
      } else {
        const newList = [
          ...selected.slice(0, foundItemIndex),
          ...selected.slice(foundItemIndex + 1),
        ];
        const removedItem = selected.filter(
          (selectedValue) =>
            !newList.some(
              (newSelectedValue) => selectedValue === newSelectedValue,
            ),
        );
        if (trackingId)
          trackReportInputChange(
            trackingId,
            removedItem[0].value,
            removedItem[0].text,
            'removed',
            trackingRuleContext,
          );
        onChange(newList);
      }
    } else if (foundItemIndex === -1) {
      const newValue = options.filter(
        (item) => item.value.toString() === option.toString(),
      );
      if (newValue.length && !newValue[0].text) return;
      if (trackingId)
        trackReportInputChange(
          trackingId,
          newValue[0].value,
          newValue[0].text,
          'added',
          trackingRuleContext,
        );
      onChange([...selected, ...newValue]);
    }
  };

  const canSelectMoreOptions =
    selected.length < maximumChipsSelected ||
    maximumChipsSelected === MAXIMUM_CHIPS_UNLIMITED;

  const findIn: DropdownSearchQueryProvider = useCallback(
    (q, lineBegin, lineEnd): Promise<ITextValue[]> =>
      new Promise((resolve) => {
        let newData: ITextValue[] = options;
        if (q) {
          newData = options.filter(
            ({ text }) => text.toLowerCase().search(q.toLowerCase()) > -1,
          );
        }
        resolve(newData.slice(lineBegin, lineEnd));
      }),
    [options],
  );

  const handleChangeSelected = (newSelected: (string | ITextValue)[]): void => {
    removeEmptyTreeValues(newSelected);

    const removedItem = selected.filter(
      (selectedValue) =>
        !newSelected?.some((newSelectedValue) =>
          isEqual(selectedValue, newSelectedValue),
        ),
    );
    if (trackingId && removedItem?.length)
      trackReportInputChange(
        trackingId,
        removedItem[0]?.value,
        removedItem[0]?.text,
        'removed',
        trackingRuleContext,
      );

    if (onChange) onChange(newSelected as ITextValue[]);
  };

  if (queryProvider || withSearch) {
    return (
      <div data-testid={componentTestId} className={componentClass}>
        <Flex horizontal={Horizontal.left}>
          <div className={componentSelectorClass}>
            {selected.length > 0 && (
              <div className={componentSelectedChipsWrapperClass}>
                <SelectedChips
                  testId={componentTestId}
                  canReorder={canReorder}
                  onChange={handleChangeSelected || noop}
                  selected={selected}
                  disabled={disabled}
                  minimumChipsSelected={minimumChipsSelected}
                />
              </div>
            )}
            {canSelectMoreOptions ? (
              <>
                {queryProvider ? (
                  <DropdownSearch
                    options={options}
                    name={name}
                    onBlur={onBlur}
                    selectedOptions={selected}
                    queryProvider={queryProvider}
                    fetchedOptions={fetchedOptions}
                    disabled={loading || disabled || !canSelectMoreOptions}
                    testId="multiple-select"
                    onChange={handleDropdownChange}
                    pageSize={pageSize ?? 10}
                    placeholder={placeholder}
                    cancelTracking
                    stickToBottom={false}
                    type={Type.search}
                    errorText={errorText}
                    isReportInvalid={isReportInvalid}
                    portalContainerClass={portalContainerClass}
                    enableHighlight={enableHighlight}
                    clearOnSelect
                  />
                ) : (
                  <DropdownSearch
                    options={options}
                    name={name}
                    onBlur={onBlur}
                    selectedOptions={selected}
                    queryProvider={findIn}
                    disabled={loading || disabled || !canSelectMoreOptions}
                    testId="multiple-select"
                    onChange={handleDropdownChange}
                    placeholder={placeholder}
                    pageSize={pageSize ?? 10}
                    type={type}
                    cancelTracking
                    stickToBottom={false}
                    errorText={errorText}
                    isReportInvalid={isReportInvalid}
                    portalContainerClass={portalContainerClass}
                    enableHighlight={enableHighlight}
                  />
                )}
              </>
            ) : (
              []
            )}
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <div data-testid={componentTestId} className={componentClass}>
      <Flex horizontal={Horizontal.left}>
        <div className={componentSelectorClass}>
          {selected.length > 0 && (
            <div className={componentSelectedChipsWrapperClass}>
              <SelectedChips
                testId={componentTestId}
                canReorder={canReorder}
                onChange={handleChangeSelected || noop}
                selected={selected}
                disabled={disabled}
                minimumChipsSelected={minimumChipsSelected}
              />
            </div>
          )}
          {canSelectMoreOptions ? (
            <>
              <Dropdown
                noPlaceholderValue={noPlaceholderValue}
                selectedOptions={selected}
                onBlur={onBlur}
                name={name}
                ignoreGrouping={ignoreGrouping}
                disabled={loading || disabled || !canSelectMoreOptions}
                testId={componentTestId}
                onChange={handleDropdownChange}
                options={options}
                keepOpenOnItemSelect={
                  maximumChipsSelected === MAXIMUM_CHIPS_UNLIMITED
                }
                placeholder={placeholder}
                cancelTracking
                stickToBottom={false}
                errorText={errorText}
                isReportInvalid
                portalContainerClass={portalContainerClass}
              />
            </>
          ) : (
            []
          )}
        </div>
      </Flex>
    </div>
  );
};

export default MultipleDropdownSelect;
