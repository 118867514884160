import Flex from 'components/Flex';
import H1 from 'components/H1';
import Loading from 'components/Loading';
import ReportsList from 'components/ReportsList';
import IReport, { reportSubType, reportType } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions, getUrl, Index } from 'routes';
import * as rootActions from 'store/actions/root';
import * as userSelectors from 'store/selectors/user';
import IError from 'types/error';
import State from 'types/state';
import ButtonDropdown from 'components/ButtonDropdown';

export const listComponentName = 'reports-list';

const LOCALE_REPORT_SEARCH_PLACEHOLDER = 'Search for report';

type ContentProps = {
  createReport: (arg0: string) => void;
  deleteReport: (arg0: IReport) => void;
  editReport: (arg0: IReport) => void;
  loading: boolean;
  setReportList: (arg0: IReport[] | null) => void;
  testId?: string;
  title: string;
  userId: string;
  handleCloneReport: (
    payload: IReport,
    action: Actions.SEGMENT_COPY | Actions.SEGMENT_CLONE,
  ) => void;
};

const List: FunctionComponent<ContentProps> = (props): ReactElement => {
  const {
    createReport,
    deleteReport,
    editReport,
    loading,
    setReportList,
    testId,
    title,
    userId,
    handleCloneReport,
  } = props;
  const resetList = (): void => {
    setReportList(null);
  };

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete } = useItemAdmin<IReport>({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
  });

  const handleUpdateClick = (report: IReport): void => {
    const updateUrl = getUrl([Actions.SEGMENT_EDIT, report.id || '']);
    editReport({ ...report, type: reportType.report });
    navigate(updateUrl);
  };

  const handleDeleteClick = (report: IReport): void => {
    doDelete({
      item: report,
      onSuccess: (): void => {
        deleteReport({ ...report, type: reportType.report });
        resetList();
        doSuccessToast('Report deleted');
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const handleCloneClick = (report: IReport): void => {
    if (!userId) {
      doErrorToast('Cannot get currentUser');
      return;
    }

    handleCloneReport(
      { ...report, createdBy: userId, type: reportType.report },
      Actions.SEGMENT_CLONE,
    );
  };

  const listTestId = getTestId(listComponentName, testId);
  const listClass = getClass(listComponentName);

  const createNewReportOptions = [
    ...(checkPermissions('reports::create')
      ? [
          {
            text: reportSubType.platform.name,
            handler: () => {
              createReport(reportType.report);
              navigate(
                `${Actions.SEGMENT_CREATE}/${reportSubType.platform.segment}`,
              );
            },
          },
        ]
      : []),
    ...(checkPermissions('platf0rm_reports.audience_insights.module::view')
      ? [
          {
            text: reportSubType.audienceInsights.name,
            handler: () => {
              createReport(reportType.report);
              navigate(
                `${Actions.SEGMENT_CREATE}/${reportSubType.audienceInsights.segment}`,
              );
            },
          },
        ]
      : []),
  ];

  return (
    <div className={listClass} data-testid={listTestId}>
      <header className={`${listClass}-header`}>
        <Flex>
          <H1>{title}</H1>
          {createNewReportOptions.length > 0 && (
            <ButtonDropdown options={createNewReportOptions} />
          )}
        </Flex>
      </header>
      <section>
        {!loading ? (
          <ReportsList
            onUpdateClick={handleUpdateClick}
            onDeleteClick={handleDeleteClick}
            onCloneClick={handleCloneClick}
            placeholder={LOCALE_REPORT_SEARCH_PLACEHOLDER}
            type="report"
          />
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state: State): { userId: string } => ({
  userId: userSelectors.getId(state),
});

const mapDispatchToProps = {
  cloneReport: rootActions.cloneReport,
  createReport: rootActions.createReport,
  deleteReport: rootActions.deleteReport,
  editReport: rootActions.editReport,
  setReportList: rootActions.setReportList,
  handleCloneReport: rootActions.handleCloneReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
