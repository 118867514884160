/*
    original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
*/
import IDimension from 'domains/dimensions/types';
import { CATEGORY_MENU_ROOT_PATH } from 'features/admin/uploads/constants';
import { fetchApi } from 'helpers/fetching';
import { Index } from 'routes';
import FetchMethod from 'types/fetchMethod';
import ITextValue from 'types/textValue';
import { IUploaderCallback } from 'types/uploads';
import { uniq } from 'lodash';

interface IUploader {
  fileName: string | undefined;
  file: File;
  fileExt: string | undefined;
  name: string | undefined;
  version: number;
  chunkSize?: number;
  threadsQuantity?: number;
}

type TInitializeMultipartUpload = {
  fileId: string;
  fileKey: string;
};

type TPart = {
  PartNumber: number;
  signedUrl: string;
};

type TMultipartPreSignedUrls = {
  parts: TPart[];
};

type TProgressCache = {
  [key: number]: number;
};

type TActiveConnections = {
  [key: number]: XMLHttpRequest;
};

type TUploadedPart = {
  ETag: string;
  PartNumber: number;
};

export class Uploader {
  chunkSize: number;
  threadsQuantity: number;
  file: File;
  fileExt: string | undefined;
  name: string | undefined;
  version: number;
  aborted: boolean;
  uploadedSize: number;
  progressCache: TProgressCache;
  activeConnections: TActiveConnections;
  parts: TPart[];
  uploadedParts: TUploadedPart[];
  fileId: string;
  fileKey: string;
  onProgressFn: (callback: IUploaderCallback) => void;
  onErrorFn: (error?: unknown) => void;
  onSuccessFn: () => void;

  constructor(options: IUploader) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize ?? 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity ?? 5, 15);
    this.file = options.file;
    this.fileExt = options.fileExt;
    this.name = options.name;
    this.version = options.version;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = '';
    this.fileKey = '';
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onSuccessFn = () => {};
  }

  // starting the multipart upload request
  async start(): Promise<void> {
    await this.initialize();
  }

  async initialize(): Promise<void> {
    try {
      // initializing the multipart request
      const initializationUploadInput = {
        name: this.name,
        fileExt: this.fileExt,
        version: this.version,
      };

      const initializeReponse = await fetchApi({
        endpoint: `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_INITIALIZE_MULTIPART_UPLOAD}`,
        method: FetchMethod.POST,
        payload: initializationUploadInput,
      });

      const AWSFileDataOutput: TInitializeMultipartUpload =
        initializeReponse.data as unknown as TInitializeMultipartUpload;

      this.fileId = AWSFileDataOutput.fileId;
      this.fileKey = AWSFileDataOutput.fileKey;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const AWSMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfparts,
      };

      const urlsResponse = await fetchApi({
        endpoint: `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_GET_MULTIPART_PRE_SIGNED_URLS}`,
        method: FetchMethod.POST,
        payload: AWSMultipartFileDataInput,
      });

      const { parts } = urlsResponse.data as unknown as TMultipartPreSignedUrls;
      this.parts.push(...parts);
      this.sendNext();
    } catch (error: unknown) {
      console.warn(error);
      await this.complete(error);
    }
  }

  sendNext(): void {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = (): void => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: unknown): Promise<void> {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
      this.onSuccessFn();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest(): Promise<void> {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      await fetchApi({
        endpoint: `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_FINALIZE_MULTIPART_UPLOAD}`,
        method: FetchMethod.POST,
        payload: videoFinalizationMultiPartInput,
      });
    }
  }

  sendChunk(
    chunk: Blob,
    part: TPart,
    sendChunkStarted: () => void,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: number, event: ProgressEvent): void {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo: number, id) => {
          let newMemo = memo;
          return (newMemo += this.progressCache[id]);
        }, 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent,
        total,
        percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(
    file: Blob,
    part: TPart,
    sendChunkStarted: () => void,
  ): Promise<number> {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new window.XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1,
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart: TUploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress: (callback: IUploaderCallback) => void): Uploader {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: (error: unknown) => void): Uploader {
    this.onErrorFn = onError;
    return this;
  }

  onSuccess(onSuccess: () => void): Uploader {
    this.onSuccessFn = onSuccess;
    return this;
  }

  abort(): void {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}

export const convertValuestoTextValuePairs = (
  values: (string | number)[] | undefined,
): ITextValue[] =>
  values?.length
    ? values.map(
        (value) =>
          ({
            text: `${value}`,
            value,
          }) as ITextValue,
      )
    : [];

export const getGroupsForMenuCategoryOptions = (
  dimensions: IDimension[],
  dataset_path?: string,
): (string | undefined)[] => {
  const basePath = dataset_path?.split('|')[0] ?? CATEGORY_MENU_ROOT_PATH;
  const uploadGroups = dimensions.map((dimension) => dimension.group);
  const selectedGroups = uploadGroups.filter(
    (group) => group?.split('|')[0] === basePath,
  );

  // If dataset's dataset_path has no other Rule in its client scope,
  // add its basePath as the default top-level group
  if (selectedGroups.length === 0) {
    selectedGroups.push(basePath);
  }

  return selectedGroups;
};

export const getGroupsUserDimensions = (dimensions: IDimension[]): string[] => {
  const basePaths = dimensions
    .filter(({ user_dimension_source }) => user_dimension_source)
    .map(({ group }) => group) as string[];
  const uploadGroups = dimensions
    .filter(({ user_dimension_source }) => user_dimension_source)
    .map(({ group }) => group) as string[];
  return uniq([...basePaths, ...uploadGroups]);
};
