import { useAuth0 } from '@auth0/auth0-react';
import Button, { Kind } from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Flex, { Direction } from 'components/Flex';
import Header from 'components/Header';
import { Type } from 'components/Icon';
import Loading from 'components/Loading';
import Main from 'components/Main';
import ReportFooter from 'components/ReportFooter';
import Sidebar from 'components/Sidebar';
import { SidebarProvider } from 'components/Sidebar/sidebar.context';
import { permissionStrToTree } from 'domains/permissions/helpers';
import { IProcessing } from 'domains/processing/types';
import { confirmDialogType } from 'domains/reports/locales/general';
import { IConfirmDialog } from 'domains/reports/types';
import Clients from 'features/clients';
import Dashboards from 'features/dashboards';
import Reports from 'features/reports';
import Roles from 'features/roles';
import Targets from 'features/targets';
import { getClass, getComponentUuid } from 'helpers/components';
import useClients from 'hooks/useClients';
import useCurrentUser from 'hooks/useCurrentUser';
import useFetch from 'hooks/useFetch';
import useFetchDomains from 'hooks/useFetchDomains';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import { get, head } from 'lodash/fp';
import React, {
  FunctionComponent,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  Routes as Switch,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Index as Routes, Actions } from 'routes';
import * as domainsActions from 'store/actions/domains';
import * as featuresReportsActions from 'store/actions/features/reports';
import * as rootActions from 'store/actions/root';
import * as userActions from 'store/actions/user';
import * as processingSelectors from 'store/selectors/processing';
import * as reportSelectors from 'store/selectors/report';
import { Action } from 'types/action';
import IError from 'types/error';
import State from 'types/state';
import ILoggedUserState from 'types/userState';
import { IClient } from '../domains/clients/types';
import useSelectedClient from '../domains/clients/useSelectedClient';
import useLogout from '../hooks/useLogout';
import Admin from './admin';
import AttributionReports from './attributionReports';
import ProcessingOverlay from './ProcessingOverlay';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const featureComponentName = 'feature';
export const featureComponent = getComponentUuid(featureComponentName);
export const mainPerfectScrollbar = 'main-perfect-scrollbar';

export const DOM_KEY_FEATURE_WRAPPER = 'wrapper';
export const DOM_KEY_FEATURE_CONTENT = 'content';
export const DOM_KEY_FEATURE_RIGHTSIDE = 'right-side';
export const DOM_KEY_FEATURE_RIGHTSIDE_CONTENT = 'right-side-content';

const featureRightSideClass = getClass(featureComponentName, {
  concat: [DOM_KEY_FEATURE_RIGHTSIDE],
});

const featureRightSideContentClass = getClass(featureComponentName, {
  concat: [DOM_KEY_FEATURE_RIGHTSIDE_CONTENT],
});

export interface IFeatureProp {
  path: string;
}

const getDefaultClient = (
  selectedClient: IClient,
  currentUser: ILoggedUserState,
): IClient =>
  get('id', selectedClient)
    ? selectedClient
    : (head(get('clients', currentUser)) as IClient);

interface OwnProps {
  loginFail: () => Record<string, unknown>;
  loginSuccess: (arg0: ILoggedUserState) => Record<string, unknown>;
  setClients: (payload: IClient[]) => Action<IClient[]>;
  setUserSelectedClient: (payload: IClient) => Action<string | undefined>;
  setUserMinDate: (payload: string) => Action<string>;
  handleSelectClient: (
    client: IClient,
    setClientCookieHook: (arg0: IClient) => void,
    setStateNotLoadedHook: () => void,
  ) => void;
  processing: IProcessing;
  confirmationDialog: IConfirmDialog;
}

const Feature: FunctionComponent<OwnProps> = (ownProps: OwnProps) => {
  const {
    loginFail,
    loginSuccess,
    setClients,
    setUserSelectedClient,
    handleSelectClient,
    processing,
    confirmationDialog,
    setUserMinDate,
  } = ownProps;
  const params = useParams();
  const { doErrorToast } = useToast();
  const { hasStateLoaded } = useFetchDomains({
    onError: (error: IError) => {
      doErrorToast(error?.message ?? 'There has been an error.');
    },
  });
  const {
    email,
    isLogged,
    setCurrentUser,
    setStateNotLoaded,
    userName,
    userId,
    userClients,
    permissions,
  } = useCurrentUser();
  const { checkPermissions } = useUserPermissions();
  const { loginWithRedirect, isLoading } = useAuth0();
  const { logout } = useLogout();
  const [loadingUserState, setLoadingUserState] = useState(false);
  const { doFetch } = useFetch<ILoggedUserState>();
  const { clients } = useClients();

  const { selectedClient, setClientCookie } = useSelectedClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged && !email && !loadingUserState) {
      setLoadingUserState(true);
      doFetch({
        endpoint: `/${Routes.SEGMENT_USERS}/${Actions.SEGMENT_LOGIN}`,
        payload: {},
        onSuccess: (res) => {
          const { allClients = [], ...currentUser } = res[0];
          const defaultClient = getDefaultClient(selectedClient, currentUser);
          const permissionMappedUser = {
            ...currentUser,
            permissions: permissionStrToTree(
              currentUser.permissions as unknown as string[],
            ),
          };
          setCurrentUser(permissionMappedUser);
          setClients(allClients);
          loginSuccess(permissionMappedUser);
          setClientCookie(defaultClient);
          setUserSelectedClient(defaultClient);
          // setSelectedClient(defaultClient);
          // setSelectedExtID(defaultClient);
        },
        onError: () => {
          loginFail();
          logout();
        },
      });
    }
  }, [
    doErrorToast,
    doFetch,
    email,
    isLogged,
    loadingUserState,
    loginFail,
    loginSuccess,
    logout,
    selectedClient,
    setClients,
    setCurrentUser,
    setClientCookie,
    // setSelectedClient,
    // setSelectedExtID,
    setUserSelectedClient,
  ]);

  useEffect(() => {
    if (!isLogged && !isLoading) loginWithRedirect();
  }, [isLoading, isLogged, loginWithRedirect]);

  const canAccessPlatf0rmReports = checkPermissions(
    'platf0rm_reports.module::view',
  );
  const canAccessAttributionTVTuneIn = checkPermissions(
    'attribution_reports.tv_tune_in.module::view',
  );
  const canAccessAttributionOutcome = checkPermissions(
    'attribution_reports.outcome.module::view',
  );
  const canAccessRoles = checkPermissions('roles::view');
  const canAccessClients = checkPermissions('clients::view');
  const canAccessTargets = checkPermissions('targets.module::view');
  const canAccessDashboards = checkPermissions('dashboards::view');
  const canAccessAdminPanel = checkPermissions('admin_panel::view');

  const hasDashboards = selectedClient.defaultDash !== '';

  const firstSidebarRoute = (): string => {
    // This logic follows the same order that the sidebar has.
    const routes = {
      [Routes.SEGMENT_DASHBOARDS]: canAccessDashboards && hasDashboards,
      [Routes.SEGMENT_REPORTS]: canAccessPlatf0rmReports,
      [Routes.SEGMENT_ATTRIBUTION_REPORTS]:
        canAccessAttributionTVTuneIn || canAccessAttributionOutcome,
      [Routes.SEGMENT_TARGETS]: canAccessTargets,
      [Routes.SEGMENT_ROLES]: canAccessRoles,
      [Routes.SEGMENT_ADMIN]: canAccessAdminPanel,
      [Routes.SEGMENT_CLIENTS]: canAccessClients,
    };
    return (
      Object.entries(routes).find(([, permission]) => permission)?.[0] ??
      Routes.SEGMENT_DASHBOARDS
    );
  };

  const content = hasStateLoaded ? (
    <Switch>
      <Route
        path={`/${Routes.SEGMENT_REPORTS_V1}/:id`}
        element={
          <Navigate
            to={`/${Routes.SEGMENT_REPORTS}/${Actions.SEGMENT_EDIT}/${params.id}`}
          />
        }
      />
      <Route
        path={`/${Routes.SEGMENT_TARGETS_V1}/:id`}
        element={
          <Navigate
            to={`/${Routes.SEGMENT_TARGETS}/${Actions.SEGMENT_EDIT}/${params.id}`}
          />
        }
      />
      <Route
        path={`/${Routes.SEGMENT_ATTRIBUTION_REPORTS_V1}/:id`}
        element={
          <Navigate
            to={`/${Routes.SEGMENT_ATTRIBUTION_REPORTS}/${Actions.SEGMENT_EDIT}/${params.id}`}
          />
        }
      />
      {canAccessRoles && (
        <Route path={`${Routes.SEGMENT_ROLES}/*`} element={<Roles />} />
      )}
      {canAccessAdminPanel && (
        <Route
          path={`${Routes.SEGMENT_ADMIN}/*`}
          element={<Admin path={`/${Routes.SEGMENT_ADMIN}`} />}
        />
      )}
      {canAccessPlatf0rmReports && (
        <Route
          path={`${Routes.SEGMENT_REPORTS}/*`}
          element={<Reports path={`/${Routes.SEGMENT_REPORTS}`} />}
        />
      )}
      {(canAccessAttributionTVTuneIn || canAccessAttributionOutcome) && (
        <Route
          path={`${Routes.SEGMENT_ATTRIBUTION_REPORTS}/*`}
          element={
            <AttributionReports
              path={`${Routes.SEGMENT_ATTRIBUTION_REPORTS}`}
            />
          }
        />
      )}
      {canAccessDashboards && hasDashboards && (
        <Route
          path={`${Routes.SEGMENT_DASHBOARDS}/*`}
          element={<Dashboards />}
        />
      )}
      {canAccessClients && (
        <Route path={`${Routes.SEGMENT_CLIENTS}/*`} element={<Clients />} />
      )}
      {canAccessTargets && (
        <Route
          path={`${Routes.SEGMENT_TARGETS}/*`}
          element={<Targets path={`/${Routes.SEGMENT_TARGETS}`} />}
        />
      )}
      <Route path="*" element={<Navigate to={`/${firstSidebarRoute()}`} />} />
    </Switch>
  ) : (
    <Loading />
  );

  const featureClass = useMemo(() => getClass(featureComponentName), []);
  const featureWrapperClass = useMemo(
    () => getClass(featureComponentName, { concat: [DOM_KEY_FEATURE_WRAPPER] }),
    [],
  );
  const featureContentClass = useMemo(
    () => getClass(featureComponentName, { concat: [DOM_KEY_FEATURE_CONTENT] }),
    [],
  );

  const { label, running, percentage } = processing;

  const handleConfirmationDialogOnBack = useCallback((): void => {
    const url = window.location.pathname.split('/')[1];
    navigate(url);
  }, [navigate]);

  return (
    <>
      <ProcessingOverlay
        label={label}
        running={running}
        percentage={percentage}
      />
      <div className={featureClass}>
        <SidebarProvider>
          <Flex direction={Direction.column}>
            <Header
              clients={clients}
              onSelectClient={(client: IClient) =>
                handleSelectClient(client, setClientCookie, setStateNotLoaded)
              }
              selectedClient={selectedClient}
              userId={userId}
              userName={userName}
              userClients={userClients}
            />
            <Main>
              <div className={featureWrapperClass}>
                <Sidebar
                  canAccessAdminPanel={canAccessAdminPanel}
                  canAccessReports={canAccessPlatf0rmReports}
                  canAccessAttributionReports={
                    canAccessAttributionTVTuneIn || canAccessAttributionOutcome
                  }
                  canAccessRoles={canAccessRoles}
                  canAccessDashboards={canAccessDashboards}
                  hasDashboards={hasDashboards}
                  canAccessClients={canAccessClients}
                  canAccessTargets={canAccessTargets}
                />
                <div className={featureRightSideClass}>
                  <div className={featureRightSideContentClass}>
                    <section className={featureContentClass}>{content}</section>
                    <ReportFooter background />
                  </div>
                </div>
                {confirmationDialog?.confirmationDialogType ===
                  confirmDialogType.errorReport && (
                  <ConfirmationDialog
                    isOpen={!!confirmationDialog.confirmationDialogMessage}
                    header={confirmationDialog.confirmationDialogHeader}
                    confirmButtonText={confirmationDialog.confirmationDialogOK}
                    onConfirm={handleConfirmationDialogOnBack}
                  >
                    <p>{confirmationDialog.confirmationDialogMessage}</p>
                  </ConfirmationDialog>
                )}
              </div>
            </Main>
          </Flex>
        </SidebarProvider>
      </div>
      <ToastContainer
        closeButton={<Button kind={Kind.icon} iconType={Type.close} />}
      />
    </>
  );
};

const mapDispatchToProps = {
  loginFail: userActions.loginFail,
  loginSuccess: userActions.loginSuccess,
  setClients: domainsActions.setClients,
  setUserSelectedClient: userActions.setUserSelectedClient,
  setAttributionReportList: rootActions.setAttributionReportList,
  setReportList: rootActions.setReportList,
  setTargetList: rootActions.setTargetList,
  setUserMinDate: userActions.setUserMinDate,
  handleSelectClient: featuresReportsActions.handleSelectClient,
};

const mapStateToProps = (
  state: State,
): {
  processing: IProcessing;
  confirmationDialog: IConfirmDialog;
} => ({
  processing: processingSelectors.getProcessing(state),
  confirmationDialog: reportSelectors.getConfirmationDialog(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feature);
