import { getClass, getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  memo,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { StickerTrigger } from './StickerTrigger';
import { StickerWrapper, stickerWrapperComponentName } from './StickerWrapper';

export enum Style {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  imp4ct = 'imp4ct',
}

export enum Type {
  tooltip = 'tooltip',
  popover = 'popover',
}

export enum TriggerEvent {
  none = 'none',
  click = 'click',
  rightClick = 'right-click',
  hover = 'hover',
  focus = 'focus',
}

export enum Placement {
  autoStart = 'auto-start',
  auto = 'auto',
  autoEnd = 'auto-end',
  topStart = 'top-start',
  top = 'top',
  topEnd = 'top-end',
  rightStart = 'right-start',
  right = 'right',
  rightEnd = 'right-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  bottomEnd = 'bottom-end',
  leftStart = 'left-start',
  left = 'left',
  leftEnd = 'left-end',
}

export interface IStickerProps {
  content: ReactNode;
  type: Type;
  style: Style;
  children?: ReactNode | undefined;
  placement?: Placement;
  testId?: string;
  showTooltip?: boolean;
  tabIndex?: number;
  showTriggerPointer?: boolean;
  showArrow?: boolean;
  stickToBottom?: boolean;
  customStickerClass?: string;
  customStickerWrapperClass?: string;
  customPortalContainer?: string | HTMLElement;
  color?: string;
  matchTriggerWidth: boolean;
  controlled: boolean;
  visible?: boolean;
}

export const stickerComponentName = 'sticker';

const defaultPlacement = Placement.auto;

function MemoizedSticker({
  content,
  children,
  placement = defaultPlacement,
  showTooltip,
  tabIndex,
  showTriggerPointer = true,
  type,
  style,
  testId,
  showArrow = true,
  stickToBottom = false,
  customStickerClass = '',
  customStickerWrapperClass = '',
  customPortalContainer,
  matchTriggerWidth,
  controlled,
  visible: visibleProp,
  color,
}: IStickerProps): ReactElement {
  const stickerTestId = getTestId(stickerComponentName, testId);
  const portal = useRef<HTMLElement>();

  useEffect(() => {
    if (customPortalContainer) {
      portal.current =
        typeof customPortalContainer === 'string'
          ? (document.querySelector(customPortalContainer) as HTMLElement)
          : customPortalContainer;
    }

    if (!portal.current) {
      portal.current = document.querySelector('body') as HTMLElement;
    }
  }, [customPortalContainer]);

  const { visible, getTooltipProps, setTooltipRef, setTriggerRef, triggerRef } =
    usePopperTooltip(
      {
        closeOnOutsideClick: true,
        closeOnTriggerHidden: true,
        defaultVisible: showTooltip ?? false,
        delayHide: type === Type.popover ? 200 : 0,
        followCursor: false,
        interactive: type === Type.popover,
        placement,
        trigger:
          type === Type.popover ? TriggerEvent.click : TriggerEvent.hover,
        ...(controlled ? { visible: visibleProp } : null),
      },
      {
        modifiers: [
          {
            name: 'flip',
            options: {
              behavior: stickToBottom ? ['bottom'] : 'flip',
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundariesElement: 'viewport',
              escapeWithReference: stickToBottom,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      },
    );
  const stickerWrapperClass = getClass(stickerWrapperComponentName, {
    add: [
      customStickerWrapperClass,
      'tooltip-container',
      type,
      style,
      customStickerClass,
    ],
  });
  const contentClass = getClass(stickerComponentName, {
    boolean: [
      {
        state: !showArrow,
        class: 'no-arrow',
      },
    ],
    add: [type, style, customStickerClass, stickerWrapperClass],
  });
  const Content = (
    <StickerWrapper
      ref={setTooltipRef}
      tooltipProps={getTooltipProps({
        className: contentClass,
        style: {
          ...(matchTriggerWidth
            ? { width: triggerRef?.getBoundingClientRect()?.width }
            : null),
          borderColor: color,
        },
      })}
      visible={visible}
      customStickerClass={customStickerClass}
      showArrow={showArrow}
      style={style}
      type={type}
      testId={stickerTestId}
    >
      {content}
    </StickerWrapper>
  );
  return (
    <StickerTrigger
      ref={setTriggerRef}
      tabIndex={tabIndex}
      data-testid={stickerTestId}
      showTriggerPointer={showTriggerPointer}
    >
      {children}
      {visible &&
        (!customPortalContainer || typeof customPortalContainer === 'string'
          ? portal.current && createPortal(Content, portal.current)
          : Content)}
    </StickerTrigger>
  );
}

export const Sticker: FunctionComponent<IStickerProps> = memo(MemoizedSticker);
