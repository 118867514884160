import Button, { Kind } from 'components/Button';
import Field from 'components/Field';
import H4 from 'components/H4';
import { Type as InputType } from 'components/Input';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { IClient } from 'domains/clients/types';
import IDimension from 'domains/dimensions/types';
import { toTextValuePairs } from 'helpers/types';
import useUserPermissions from 'hooks/useUserPermissions';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { getUrl, Index } from 'routes';
import ITextValue from 'types/textValue';
import { IFormUpdatedPayload, IUploadForm, UploadStatus } from 'types/uploads';
import { v4 as uuid } from 'uuid';
import { IDataset } from 'domains/datasets/types';
import { ForUseInImpact } from './ForUseInImpact';
import { FileDetails } from './FileDetails';

export interface UploadEditFormProps {
  uploadFormChanged: boolean;
  uploadForm: IUploadForm;
  dataProviderOptions: ITextValue[];
  fileTypeOptions: ITextValue[];
  validationErrors: string[];
  userEmail: string;
  selectedClient: string;
  uploadPercentage: number | null | undefined;
  uploadFormUpdated: (arg0: IFormUpdatedPayload) => void;
  handleValidation: (
    form: IUploadForm,
    ingestType: string,
    version?: number,
    skipPathValidation?: boolean,
  ) => void;
  handleFileUpload: (
    arg0: string | undefined,
    arg1: File | undefined,
    arg2: IUploadForm,
  ) => void;
  uploadFormReset: () => void;
  fetchUploadsMetadata: (id?: string) => Promise<IDataset>;
  clients: IClient[];
  dimensions: IDimension[];
  startProcessing: (label?: string) => void;
  updatePercentage: (percentage: number) => void;
  finishProcessing: () => void;
  handleUploadSubmit: (navigate: NavigateFunction) => void;
  handleSaveAction: (id: string, navigate: NavigateFunction) => void;
  isLoadingUpdate: boolean;
  datasetLoading: boolean;
  crosswalkSources: ITextValue[];
}

const uploadsComponentName = 'uploads';
export const datasetIdFieldTestId = 'upload-dataset-id-field';

export const UploadEditForm: FunctionComponent<
  PropsWithChildren<UploadEditFormProps>
> = ({
  uploadFormChanged,
  uploadForm,
  uploadFormUpdated,
  fileTypeOptions,
  dataProviderOptions,
  handleValidation,
  handleFileUpload,
  uploadFormReset,
  validationErrors,
  selectedClient,
  userEmail,
  clients,
  dimensions,
  handleSaveAction,
  crosswalkSources,
  children,
  handleUploadSubmit,
  isLoadingUpdate,
}) => {
  const {
    status,
    s3Path,
    s3Source,
    display_name: displayName,
    dataset_id: datasetId,
    crosswalk_combination: crosswalkCombination,
    fileType,
    clientsWithAccess,
    dataset_metadata: datasetMetadata,
  } = uploadForm;

  const {
    attributes,
    vendor_name: vendorName,
    fallback,
    subType,
    conversion_type: conversionType,
    performance_metric_groups: performanceMetricGroups,
  } = datasetMetadata;
  const [file, setFile] = useState<File | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const { checkPermissions } = useUserPermissions();

  const { id } = useParams();

  const editing = id !== undefined;
  const menuSelected = !!(fileType === 'SEGMENT'
    ? attributes[0]?.path
    : datasetMetadata?.dataset_path);
  const allAttributesFilled = !attributes.some((attr) => !attr.name);
  const isConversionEvent =
    fileType === 'GENERIC_EVENTS' && subType === 'conversions';
  const allFieldsFilled = Boolean(
    fileType && displayName && (editing || vendorName) && (editing || s3Path),
  );

  const ableToUpload = validationErrors.length === 0 && allFieldsFilled;
  const ableToEdit = !editing || checkPermissions('datasets::update');

  const fileTypeSelected = Boolean(fileType);

  const navigate = useNavigate();

  const cancelAction = useCallback((): void => {
    const createUrl = getUrl([Index.SEGMENT_ADMIN, Index.SEGMENT_UPLOADS]);
    uploadFormReset();
    navigate(`/${createUrl}`);
  }, [navigate, uploadFormReset]);

  const uploadAction = (): void => {
    if (id) {
      handleSaveAction(id, navigate);
      return;
    }
    handleUploadSubmit(navigate);
  };

  const showFileDetails =
    (!editing && fileTypeSelected && attributes?.length > 0) ||
    (editing && ['SEGMENT', 'GENERIC_EVENTS'].includes(fileType));

  useEffect(() => {
    if (id && status !== '' && status !== UploadStatus.completed) {
      cancelAction();
    }
  }, [status, id, cancelAction]);

  return (
    <form>
      <div className="form-container">
        <div className="inline-field-container">
          <div className="label-container">
            <H4>File Type</H4>
          </div>
          <div className="field-container">
            <Field
              type={InputType.select}
              id={`${uploadsComponentName}-file-type`}
              name={`${uploadsComponentName}-file-type`}
              onChange={(value) =>
                uploadFormUpdated({ key: 'fileType', value })
              }
              options={fileTypeOptions}
              placeholder="Select file type..."
              value={fileType}
              disabled={editing || fileTypeSelected}
            />
          </div>
        </div>

        {(fileTypeSelected || editing) && (
          <>
            <div className="inline-field-container">
              <div className="label-container">
                <H4>Dataset Name</H4>
              </div>
              <div className="field-container">
                <Field
                  type={InputType.text}
                  id={`${uploadsComponentName}-dataset-name`}
                  name={`${uploadsComponentName}-dataset-name`}
                  onChange={(value) => {
                    uploadFormUpdated({ key: 'display_name', value });
                  }}
                  placeholder="Dataset Name"
                  value={displayName}
                  disabled={!ableToEdit}
                  testId={`${uploadsComponentName}-dataset-name`}
                />
              </div>
            </div>

            {checkPermissions('datasets.admin::view') && (
              <div className="inline-field-container">
                <div className="label-container">
                  <H4>Dataset ID</H4>
                </div>
                <div
                  className="field-container dataset-id-field"
                  data-testid={datasetIdFieldTestId}
                >
                  <Field
                    type={InputType.text}
                    id={`${uploadsComponentName}-dataset-id`}
                    name={`${uploadsComponentName}-dataset-id`}
                    placeholder="Dataset Id"
                    value={datasetId}
                  />
                </div>
              </div>
            )}

            {fileType === 'SEGMENT' && (
              <div className="inline-field-container">
                <div className="label-container">
                  <H4>Data Provider</H4>
                </div>
                <div className="field-container">
                  <Field
                    type={InputType.select}
                    id={`${uploadsComponentName}-data-provider`}
                    name={`${uploadsComponentName}-data-provider`}
                    onChange={(value) =>
                      uploadFormUpdated({
                        key: 'dataset_metadata.vendor_name',
                        value,
                      })
                    }
                    options={dataProviderOptions}
                    placeholder="Select data provider..."
                    value={vendorName}
                    disabled={!ableToEdit}
                    testId={`${uploadsComponentName}-data-provider`}
                  />
                </div>
              </div>
            )}
            {editing && checkPermissions('datasets.admin::view') && (
              <>
                {fileType === 'DAYPART_TIMETABLE' && (
                  <div className="inline-alert-container">
                    <InlineAlert
                      message="Custom Dayparts can only be assigned via the Client Edit screen."
                      mode={AlertTypes.omission}
                      hideClose
                    />
                  </div>
                )}
                {fileType !== 'DAYPART_TIMETABLE' && (
                  <div className="inline-field-container">
                    <div className="label-container">
                      <H4>Clients with Access</H4>
                    </div>
                    <div className="field-container">
                      <MultipleDropdownSelect
                        noPlaceholderValue
                        selected={clientsWithAccess}
                        options={toTextValuePairs(clients)}
                        onChange={(value) =>
                          uploadFormUpdated({
                            key: 'clientsWithAccess',
                            value,
                          })
                        }
                        placeholder="Client with access"
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {!editing && (
              <div className="inline-field-container">
                <div className="label-container">
                  <H4>Select File</H4>
                </div>
                <div className="field-container">
                  <div>
                    <H4>S3 Source</H4>
                  </div>
                  <div className="s3-inline-container">
                    <ToggleSwitch
                      name={`${uploadsComponentName}-s3-source`}
                      onChange={(value) =>
                        uploadFormUpdated({ key: 's3Source', value })
                      }
                      checked={s3Source}
                    />
                  </div>
                  {s3Source && (
                    <>
                      <div className="s3-inline-container input">
                        <Field
                          type={InputType.text}
                          id={`${uploadsComponentName}-s3-path`}
                          name={`${uploadsComponentName}-s3-path`}
                          onChange={(value) =>
                            uploadFormUpdated({ key: 's3Path', value })
                          }
                          placeholder="s3://path-to-parquet-file"
                          value={s3Path}
                        />
                      </div>
                      <div className="s3-inline-container">
                        <Button
                          disabled={!displayName || !vendorName}
                          kind={Kind.outline}
                          onClick={() => {
                            handleValidation(uploadForm, 'validation', 1, true);
                          }}
                        >
                          Validate
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            {!s3Source && !editing && (
              <div className="browse-container">
                <div className="label-container" />
                <div>
                  <div className="browse-input-container">
                    <input
                      type="file"
                      accept=".txt, .csv"
                      multiple={false}
                      onClick={() => {
                        setFile(undefined);
                        const fileInputElement = inputRef.current;
                        if (fileInputElement) fileInputElement.value = '';
                      }}
                      onChange={(event) => {
                        const uploadedFile = event.target.files?.[0];
                        setFile(uploadedFile);
                        handleFileUpload(datasetId, uploadedFile, uploadForm);
                      }}
                      ref={inputRef}
                      className="hidden-input"
                    />
                    <Button
                      disabled={
                        !displayName || (!vendorName && fileType === 'SEGMENT')
                      }
                      kind={Kind.outline}
                      onClick={() => inputRef.current?.click()}
                      className="spaced-btn"
                    >
                      Browse
                    </Button>
                    <span className="file-name">{file?.name}</span>
                  </div>
                  <div className="browse-text-container">
                    <span>Having trouble uploading your file?</span>
                    <a
                      href="https://605tv.atlassian.net/servicedesk/customer/portal/15"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Support.
                    </a>
                  </div>
                  <div className="browse-text-container">
                    <span>Having trouble formatting your file?</span>
                    <a
                      href="/605/docs/faq.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Use these templates.
                    </a>
                  </div>
                </div>
              </div>
            )}

            {(validationErrors ?? []).length > 0 && (
              <div className="inline-error-container">
                <div className="label-container" />
                <div className="error-container">
                  <ol>
                    {validationErrors.map((validationErrorMessage) => (
                      <li
                        className="error-line"
                        key={`validation-error-message-${uuid()}`}
                      >
                        <span>{validationErrorMessage}</span>
                      </li>
                    ))}
                  </ol>
                  <p>
                    Refer to our{' '}
                    <a
                      href="/605/docs/faq.pdf"
                      target="_blank"
                      className="faq-link"
                    >
                      FAQ
                    </a>{' '}
                    for help with file formatting.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {showFileDetails && (
        <>
          <FileDetails
            fileType={fileType}
            dimensions={dimensions}
            {...{ attributes, uploadFormUpdated }}
            subType={subType}
            dataset_path={
              datasetMetadata?.dataset_path ??
              datasetMetadata.attributes[0]?.path
            }
            conversionType={conversionType as string}
            performanceMetricGroups={performanceMetricGroups as string[]}
          >
            {checkPermissions('datasets.advanced::view') &&
              (isConversionEvent || fileType === 'SEGMENT') && (
                <ForUseInImpact
                  uploadFormUpdated={uploadFormUpdated}
                  forUseInImpact={fallback?.dataset === 'piq'}
                  selectedClient={selectedClient}
                  userEmail={userEmail}
                  PIQ_CATEGORY={fallback?.category ?? []}
                  PIQ_CHAIN={fallback?.chain ?? []}
                />
              )}

            {checkPermissions('datasets.admin::view') &&
              fileType === 'SEGMENT' && (
                <div className="inline-field-container">
                  <Label text="Crosswalk" />
                  <div
                    className="field-container"
                    data-testid={`${uploadsComponentName}-crosswalk-sources`}
                  >
                    <MultipleDropdownSelect
                      noPlaceholderValue
                      selected={crosswalkCombination}
                      options={crosswalkSources}
                      onChange={(value) =>
                        uploadFormUpdated({
                          key: 'crosswalk_combination',
                          value,
                        })
                      }
                      placeholder="Select a crosswalk combination"
                    />
                  </div>
                </div>
              )}
          </FileDetails>

          {children}
        </>
      )}
      {((!editing && fileTypeSelected) || editing) && (
        <div className="actions-container">
          <Button onClick={cancelAction} kind={Kind.outline}>
            Cancel
          </Button>
          <Button
            kind={Kind.primary}
            onClick={uploadAction}
            disabled={
              !ableToUpload ||
              !ableToEdit ||
              (editing && !uploadFormChanged) ||
              (!editing && (!menuSelected || !allAttributesFilled)) ||
              isLoadingUpdate
            }
          >
            {editing ? 'Save' : 'Upload'}
          </Button>
        </div>
      )}
    </form>
  );
};
