export enum Type {
  audit = 'audit',
  barChart = 'barChart',
  calendar = 'calendar',
  carrot = 'carrot',
  chartTypeAreaDefault = 'chartTypeAreaDefault',
  chartTypeAreaSpline = 'chartTypeAreaSpline',
  chartTypeAreaStacked = 'chartTypeAreaStacked',
  chartTypeAreaStackedPercentage = 'chartTypeAreaStackedPercentage',
  chartTypeBar = 'chartTypeBar',
  chartTypeBarStacked = 'chartTypeBarStacked',
  chartTypeBarStackedPercentage = 'chartTypeBarStackedPercentage',
  chartTypeBubble = 'chartTypeBubble',
  chartTypeColumn = 'chartTypeColumn',
  chartTypeColumnPercentageStacked = 'chartTypeColumnPercentageStacked',
  chartTypeColumnStacked = 'chartTypeColumnStacked',
  chartTypeLine = 'chartTypeLine',
  chartTypeLineBold = 'chartTypeLineBold',
  chartTypeLineSpline = 'chartTypeLineSpline',
  chartTypePie = 'chartTypePie',
  chartTypeSplineStacked = 'chartTypeSplineStacked',
  chartTypeSplineStackedPercentage = 'chartTypeSplineStackedPercentage',
  checkmark = 'checkmark',
  chevron = 'chevron',
  close = 'close',
  close2 = 'close2',
  cog = 'cog',
  copy = 'copy',
  dashboard = 'dashboard',
  cumulativeLegend = 'cumulativeLegend',
  database = 'database',
  door = 'door',
  download = 'download',
  edit = 'edit',
  editInactive = 'editInactive',
  eye = 'eye',
  folder = 'folder',
  helpCircle = 'helpCircle',
  imp4ct = 'imp4ct',
  info = 'info',
  loading = 'loading',
  logout = 'logout',
  link = 'link',
  moreVertical = 'moreVertical',
  play = 'play',
  plus = 'plus',
  questionMark = 'questionMark',
  rightArrow = 'rightArrow',
  save = 'save',
  saveAs = 'saveAs',
  search = 'search',
  settings = 'settings',
  rectangle = 'rectangle',
  rectangleFilled = 'rectangleFilled',
  share = 'share',
  standardLegend = 'standardLegend',
  target = 'target',
  trash = 'trash',
  user = 'user',
  userRoles = 'userRoles',
  users = 'users',
  public = 'public',
  private = 'private',
  genre = 'genre',
  network = 'network',
  houseMoney = 'houseMoney',
  warning = 'warning',
  tv = 'tv',
  creative = 'creative',
  devices = 'devices',
  daypart = 'daypart',
  adDuration = 'adDuration',
  tvAd = 'tvAd',
  clickToScrollDown = 'clickToScrollDown',
  bubbleLegend = 'bubbleLegend',
  alertTriangle = 'alertTriangle',
  multipleOptions = 'multipleOptions',
  wrench = 'wrench',
  reportDetails = 'reportDetails',
  funnel = 'funnel',
  funnel2 = 'funnel2',
  money = 'money',
  calendar2 = 'calendar2',
  boxArrowUpRight = 'boxArrowUpRight',
}

export enum Color {
  dark = 'dark',
  light = 'light',
  gray = 'gray',
  gray200 = 'gray200',
  orange = 'orange',
  primary = 'primary',
  secondary = 'secondary',
  red = 'red',
}

export enum Size {
  ultraLarge = 'ultra-large',
  large = 'large',
  medium = 'medium',
  small = 'small',
  micro = 'micro',
}

export interface IIconProps {
  testId?: string;
  type: Type;
  color?: Color;
  size?: Size;
  customClass?: string;
}
