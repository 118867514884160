import IDimension from '../dimensions/types';
import ITextValue from '../../types/textValue';

export const datasetSelectionAccessItem = 'dataset_selection';

export const DatasetTypes = {
  category3Segment: 'CAT3_SEGMENT',
  schedule: 'SCHEDULE',
  segment: 'SEGMENT',
  vendorSource: 'VENDOR_SOURCE',
  crosswalk: 'CROSSWALK',
  genericEvents: 'GENERIC_EVENTS',
  daypartTimetable: 'DAYPART_TIMETABLE',
  userDimension: 'USER_DIMENSION',
  crosswalkCombination: 'CROSSWALK_COMBINATION',
};

export const DatasetSubTypes = {
  exposures: 'exposures',
  conversions: 'conversions',
};

export type UniverseLabel = 'All US TV Households' | 'All US Households';
export type DatasetType = (typeof DatasetTypes)[keyof typeof DatasetTypes];
export type DatasetSubType =
  (typeof DatasetSubTypes)[keyof typeof DatasetSubTypes];

export interface IDataset {
  id: string;
  dataset_id: string;
  name: string;
  type: DatasetType;
  subType?: DatasetSubType;
  display_name: string;
  status?: string;
  extended_metadata?: {
    crosswalk_combinations?: string[][];
    description?: string;
    universe_label?: UniverseLabel;
  };
}

export interface IBaseUserDimension {
  custom_values: IUserDimensionValue[];
  dataset_id: string;
  description: string;
  path: string;
  source_dimension: string;
  user_dimension_name: string;
  user_dimension_id?: string;
}

export interface IUserDimensionDetails extends IBaseUserDimension {
  id?: string;
  type: string;
  fallback_value: string;
  clients: ITextValue[];
}

export interface ICrosswalkCombinationDetails
  extends Omit<IDataset, 'id' | 'display_name'> {
  id?: string;
  clients: ITextValue[] | undefined;
  crosswalk_combinations: ITextValue[];
  universe_label: { text: UniverseLabel; value: UniverseLabel }[];
  description?: string;
}

export interface IUserDimensionAAPIPayload extends IBaseUserDimension {
  custom_values: IUserDimensionAAPIPayloadValue[];
}

export interface IUserDimensionAAPIResponse extends IBaseUserDimension {}

export interface IUserDimensionAAPIPayloadValue {
  mapped_value: string;
  source_values: string[];
}
export interface IDatasetDetails {
  min_date: string;
  max_date: string;
  dimensions?: IDimension[];
  performance_metric_groups?: string[];
  conversion_type?: string;
}

export interface IUserDimensionValue {
  id?: string;
  source_values: (string | ITextValue)[];
  mapped_value: string;
}
