import Dropdown from 'components/Dropdown';
import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import FieldWrapper from 'components/Field/Wrapper';
import Label from 'components/Label';
import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import {
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL,
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL_TOOLTIP,
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER,
} from 'domains/reports/locales/datasets';
import { handleDropdownSearchSyncFilter } from 'helpers/utils';
import { get, isEmpty, size } from 'lodash/fp';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { GENPOP_TARGET_ID } from 'store/actions/businessData';
import { SubTypeKeys } from 'domains/reports/types';
import { getDatasetDefaultDateRange } from 'domains/reports/adapters/date';
import { validateAudienceSelection } from 'helpers/reports';
import { useDatasets } from '../../domains/datasets/hooks';
import { isCumulative } from '../../domains/reports/adapters/general';
import { getClass, getId, getTestId } from '../../helpers/components';
import { toTextValuePairs } from '../../helpers/types';
import {
  getQueryMode,
  getQueryScheduleDatasets,
} from '../../store/reducers/report';
import {
  ALL_VIEWING_INTERVAL,
  DAY_INTERVAL,
  Mode,
  Modes,
} from '../../types/query';
import ITextValue from '../../types/textValue';
import DatePicker from '../DatePicker';
import MultipleDropdownSelect from '../MultipleDropdownSelect';
import OrderBySimple from '../OrderBySimple';
import RulesBuilder from '../RulesBuilder';
import SegmentedControl from '../SegmentedControl';
import { ToggleSwitch } from '../ToggleSwitch';
import {
  getFiltersFromQueryFilters,
  getParametersFromQuery,
  getTextValueArrayFromArray,
  getTextValueDatasets,
  injectDatasetControl,
  isCumulativeDisabled,
} from './adapters';
import QueryBuilderItem, {
  LabelAlignments,
} from './components/QueryBuilderItem';
import {
  DOM_KEY_BODY,
  DOM_KEY_FOOTER,
  DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN,
  DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL,
  TARGET_SELECT_LIMIT,
  TOGGLE_VIEW_CHART,
  TOGGLE_VIEW_CHART_TOOLTIP,
  TOGGLE_VIEW_TABLE,
  TOGGLE_VIEW_TABLE_TOOLTIP,
} from './constants';
import {
  LOCALE_DATASETS_LABEL,
  LOCALE_DATASETS_TOOLTIP,
  LOCALE_DATE_RANGE_LABEL,
  LOCALE_DATE_RANGE_LABEL_TOOLTIP,
  LOCALE_DIMENSIONS_LABEL,
  LOCALE_DIMENSIONS_LABEL_TOOLTIP,
  LOCALE_DIMENSIONS_PLACEHOLDER,
  LOCALE_INTERVAL_LABEL,
  LOCALE_INTERVAL_LABEL_TOOLTIP,
  LOCALE_METRIC_TYPE_LABEL,
  LOCALE_METRIC_TYPE_LABEL_TOOLTIP,
  LOCALE_METRICS_LABEL,
  LOCALE_METRICS_LABEL_TOOLTIP,
  LOCALE_METRICS_PLACEHOLDER,
  LOCALE_ORDER_BY_LABEL,
  LOCALE_ORDER_BY_LABEL_TOOLTIP,
  LOCALE_RULES_LABEL,
  LOCALE_RULES_LABEL_TOOLTIP,
  LOCALE_TARGET,
  LOCALE_TARGET_SELECT_TARGET,
  LOCALE_TARGET_TOOLTIP,
} from './locale';
import { IOwnProps } from './types';

export const componentName = 'query-builder';

export const QueryBuilder: FunctionComponent<IOwnProps> = (
  props: IOwnProps,
): ReactElement => {
  const {
    availableDimensions,
    availableIntervals,
    availableMetrics,
    availableOrderFields,
    availableRulesDimensions,
    availableTargets,
    businessData,
    cumulativeGroup,
    dimensions,
    id,
    metrics,
    report,
    setDimensionsAction,
    setIntervalAction,
    setMetricTypeAction,
    setMetricsAction,
    addQueryFilterAction,
    replaceQueryFilterAction,
    deleteQueryFilterAction,
    setQueryModeAction,
    setReportQueryDatasetsAction,
    setTableVisibleAction,
    setChartVisibleAction,
    handleQueryBuilderDatePickerChange,
    handleQueryBuilderOrderChange,
    handleQueryBuilderTargetChange,
    testId,
    toolbar,
    disabled,
    messageList,
    isReportInvalid,
    addWarningMessage,
    deleteWarningMessage,
    domainsDateRanges,
  } = props;

  const isAudienceInsightsReport =
    report?.subType === SubTypeKeys.AUDIENCE_INSIGHTS;
  const ruleIdsToHide = isAudienceInsightsReport
    ? ['VIEWING_TYPE', 'CONSECUTIVE_TELECAST_VIEWING_HH']
    : [];
  const { isRunLaunched } = toolbar;

  const { query } = report;

  const { datasets, interval, sorting, targets } = query;

  const { getScheduleDatasetsForSelectedClient } = useDatasets();
  const availableScheduleDatasets = useMemo(
    getScheduleDatasetsForSelectedClient,
    [getScheduleDatasetsForSelectedClient],
  );
  const curatedScheduleDatasets = useMemo(
    () => getTextValueDatasets(availableScheduleDatasets),
    [availableScheduleDatasets],
  );

  const {
    startDate: queryStartDate,
    endDate: queryEndDate,
    tableVisible: queryTableVisible,
    chartVisible: queryChartVisible,
    relativeDateOffset,
  } = getParametersFromQuery(query);

  const componentId = useMemo(() => getId(componentName, id), [id]);
  const componentTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );
  const componentClass = useMemo(() => getClass(componentName), []);
  const componentBodyClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_BODY] }),
    [],
  );
  const componentFooterClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_FOOTER] }),
    [],
  );

  const handleChangeScheduleDataset = (
    selectedDatasets: ITextValue[],
  ): void => {
    const datasetValues = selectedDatasets.map(
      (dataset: ITextValue) => dataset.value,
    ) as string[];
    setReportQueryDatasetsAction(datasetValues);
  };

  const queryIsCumulative = isCumulative(report.query);
  const realMode: Mode = getQueryMode(report);
  const blockDimension = queryIsCumulative && size(dimensions) > 0;
  const availableDimensionsTextValue = toTextValuePairs(availableDimensions);
  const availableMetricsTextValue = toTextValuePairs(
    availableMetrics,
    queryIsCumulative,
  );

  const defaultInterval = queryIsCumulative
    ? DAY_INTERVAL
    : ALL_VIEWING_INTERVAL;

  const getDefaultInterval = (): string =>
    availableIntervals
      ?.find((intervalIter) => intervalIter.value === defaultInterval.id)
      ?.value.toString() ?? defaultInterval.id;

  const curatedDimensions = getTextValueArrayFromArray(
    dimensions,
    availableDimensionsTextValue,
  );

  const queryBuilderDatasetsComponentName = 'query-builder-datasets';

  const queryBuilderDatasetsSegmentedControlClass = useMemo(
    () =>
      getClass(queryBuilderDatasetsComponentName, {
        concat: [DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL],
      }),
    [],
  );

  const queryBuilderVisibleItemsComponentName = 'query-builder-visible-items';
  const queryBuilderVisibleItemsSegmentedControlClass = useMemo(
    () =>
      getClass(queryBuilderVisibleItemsComponentName, {
        concat: [DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL],
      }),
    [],
  );

  const defaultCumulativeSortOptionList = [
    ...curatedDimensions,
    {
      text: get('name', interval),
      value: get('id', interval),
    },
  ] as unknown as ITextValue[];

  const orderByOptionList = queryIsCumulative
    ? defaultCumulativeSortOptionList
    : availableOrderFields;

  const curatedAvailableTargets = availableTargets.map((target) => ({
    text: target.name ?? target.id,
    value: target.id,
  }));
  const selectedMetrics = useMemo(
    () => getTextValueArrayFromArray(metrics, availableMetricsTextValue),
    [metrics, availableMetricsTextValue],
  );
  const validateMetricsDropdown = useMemo((): string => {
    let message = '';
    const metricValue = getTextValueArrayFromArray(
      metrics,
      availableMetricsTextValue,
    );
    if (!metricValue.length) {
      message = 'Reports need at least one Metric.';
      addWarningMessage({
        id: 'metric_value',
        message,
        field: 'Metrics',
      });
    } else {
      message = '';
      const valueIsInError = messageList.filter(
        (m) => m.id === 'metric_value' && m.field === 'Metrics',
      );
      if (valueIsInError.length) {
        deleteWarningMessage({
          id: 'metric_value',
          field: 'Metrics',
        });
      }
    }
    return message;
  }, [
    selectedMetrics,
    addWarningMessage,
    deleteWarningMessage,
    messageList,
    realMode,
  ]);

  const validateCustomAdsDropdown = useMemo((): string => {
    let message = '';
    if (realMode === Modes.customAdverts) {
      const customAdsDatasetValue = getTextValueArrayFromArray(
        getQueryScheduleDatasets(report),
        curatedScheduleDatasets,
      );
      if (!customAdsDatasetValue.length) {
        message = 'Custom Ads requires at least one dataset.';
        addWarningMessage({
          id: 'dataset_value',
          message,
          field: realMode,
        });
      } else {
        message = '';
        const valueIsInError = messageList.filter(
          (m) => m.id === 'dataset_value' && m.field === realMode,
        );
        if (valueIsInError.length) {
          deleteWarningMessage({
            id: 'dataset_value',
            field: realMode,
          });
        }
      }
    }
    return message;
  }, [
    getTextValueArrayFromArray(
      getQueryScheduleDatasets(report),
      curatedScheduleDatasets,
    ),
    addWarningMessage,
    deleteWarningMessage,
    messageList,
    realMode,
  ]);

  const movingDates = realMode !== Modes.customAdverts;

  const filterDatasetsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, curatedScheduleDatasets),
    [curatedScheduleDatasets],
  );

  const filterMetricsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, availableMetricsTextValue),
    [availableMetricsTextValue],
  );

  const filterDimensionsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, availableDimensionsTextValue),
    [availableDimensionsTextValue],
  );

  const datasetOptions = useMemo(
    () => injectDatasetControl(businessData, report),
    [businessData],
  );

  const { dateMin, dateMax } = getDatasetDefaultDateRange(
    domainsDateRanges,
    realMode,
  );

  return (
    <div
      id={componentId}
      data-testid={componentTestId}
      className={componentClass}
    >
      <section className={componentBodyClass}>
        {!isAudienceInsightsReport && (
          <FieldWrapper>
            <Label
              text={LOCALE_DATASETS_LABEL}
              tooltip={LOCALE_DATASETS_TOOLTIP}
            />
            <div className={queryBuilderDatasetsSegmentedControlClass}>
              <SegmentedControl
                options={datasetOptions}
                onSelect={setQueryModeAction}
                trackingId="Datasets"
                selected={realMode}
                disabled={disabled}
              />
            </div>
          </FieldWrapper>
        )}

        {realMode === Modes.customAdverts && (
          <QueryBuilderItem
            label={
              LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL
            }
            tooltip={
              LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL_TOOLTIP
            }
          >
            <MultipleDropdownSelect
              ignoreGrouping
              noPlaceholderValue
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              options={curatedScheduleDatasets}
              errorText={validateCustomAdsDropdown}
              isReportInvalid={isReportInvalid}
              onChange={handleChangeScheduleDataset}
              trackingId="custom_ads"
              selected={getTextValueArrayFromArray(
                getQueryScheduleDatasets(report),
                curatedScheduleDatasets,
              )}
              testId={DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN}
              placeholder={
                LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER
              }
              queryProvider={filterDatasetsOptions}
            />
          </QueryBuilderItem>
        )}
        {!isAudienceInsightsReport && (
          <QueryBuilderItem
            label={LOCALE_METRIC_TYPE_LABEL}
            className="large"
            tooltip={LOCALE_METRIC_TYPE_LABEL_TOOLTIP}
          >
            <ToggleSwitch
              name="Metric type"
              checked={!!cumulativeGroup}
              onChange={setMetricTypeAction}
              disabled={isCumulativeDisabled()}
              trackingId="Cumulative"
            />
          </QueryBuilderItem>
        )}

        <QueryBuilderItem
          label={LOCALE_DATE_RANGE_LABEL}
          className="large"
          tooltip={LOCALE_DATE_RANGE_LABEL_TOOLTIP}
        >
          <DatePicker
            disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
            startDate={queryStartDate}
            endDate={queryEndDate}
            trackingId="Report"
            handleChange={handleQueryBuilderDatePickerChange}
            limitToRange={!!(datasets?.length && datasets.length > 0)}
            maxDate={dateMax}
            minDate={dateMin}
            showNumOfDays
            movingDates={movingDates}
            relativeDateOffset={relativeDateOffset}
          />
        </QueryBuilderItem>

        {!isAudienceInsightsReport && (
          <div>
            <QueryBuilderItem
              label={LOCALE_METRICS_LABEL}
              tooltip={LOCALE_METRICS_LABEL_TOOLTIP}
            >
              <MultipleDropdownSelect
                ignoreGrouping
                noPlaceholderValue
                disabled={
                  isRunLaunched || isBaseReportSaving(report) || disabled
                }
                options={availableMetricsTextValue}
                isReportInvalid={isReportInvalid}
                onChange={setMetricsAction}
                errorText={validateMetricsDropdown}
                selected={getTextValueArrayFromArray(
                  metrics,
                  availableMetricsTextValue,
                )}
                testId="metrics"
                trackingId="metric"
                placeholder={LOCALE_METRICS_PLACEHOLDER}
                minimumChipsSelected={1}
                queryProvider={filterMetricsOptions}
              />
            </QueryBuilderItem>

            <QueryBuilderItem
              label={LOCALE_DIMENSIONS_LABEL}
              tooltip={LOCALE_DIMENSIONS_LABEL_TOOLTIP}
            >
              <MultipleDropdownSelect
                disabled={
                  isRunLaunched || isBaseReportSaving(report) || disabled
                }
                maximumChipsSelected={blockDimension ? 1 : -1}
                noPlaceholderValue
                options={availableDimensionsTextValue}
                onChange={setDimensionsAction}
                selected={getTextValueArrayFromArray(
                  dimensions,
                  availableDimensionsTextValue,
                )}
                testId="dimensions"
                trackingId="dimension"
                placeholder={LOCALE_DIMENSIONS_PLACEHOLDER}
                queryProvider={filterDimensionsOptions}
              />
            </QueryBuilderItem>

            <QueryBuilderItem
              label={LOCALE_INTERVAL_LABEL}
              className="medium"
              tooltip={LOCALE_INTERVAL_LABEL_TOOLTIP}
            >
              <Dropdown
                noPlaceholderValue
                options={availableIntervals}
                onChange={setIntervalAction}
                value={interval?.id ?? getDefaultInterval() ?? ''}
                disabled={
                  isRunLaunched || isBaseReportSaving(report) || disabled
                }
                testId="interval"
                trackingId="interval"
              />
            </QueryBuilderItem>

            {!isEmpty(orderByOptionList) && get('sorting.field', query) && (
              <QueryBuilderItem
                label={LOCALE_ORDER_BY_LABEL}
                className="order-by"
                tooltip={LOCALE_ORDER_BY_LABEL_TOOLTIP}
              >
                <OrderBySimple
                  options={orderByOptionList}
                  sorting={sorting}
                  onChange={handleQueryBuilderOrderChange}
                  disabled={
                    isRunLaunched ||
                    isBaseReportSaving(report) ||
                    queryIsCumulative ||
                    disabled
                  }
                />
              </QueryBuilderItem>
            )}
          </div>
        )}

        <QueryBuilderItem label={LOCALE_TARGET} tooltip={LOCALE_TARGET_TOOLTIP}>
          <MultipleDropdownSelect
            noPlaceholderValue
            options={curatedAvailableTargets}
            withSearch
            placeholder={LOCALE_TARGET_SELECT_TARGET}
            onChange={handleQueryBuilderTargetChange}
            selected={getTextValueArrayFromArray(
              targets ?? [GENPOP_TARGET_ID],
              curatedAvailableTargets,
            )}
            disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
            testId="target"
            trackingId="target"
            maximumChipsSelected={
              isAudienceInsightsReport ? 1 : TARGET_SELECT_LIMIT
            }
            minimumChipsSelected={isAudienceInsightsReport ? 0 : 1}
            errorText={validateAudienceSelection(
              report,
              messageList,
              addWarningMessage,
              deleteWarningMessage,
            )}
            isReportInvalid={isReportInvalid}
          />
        </QueryBuilderItem>
      </section>

      <footer className={componentFooterClass}>
        <QueryBuilderItem
          label={LOCALE_RULES_LABEL}
          labelAlignment={LabelAlignments.top}
          tooltip={LOCALE_RULES_LABEL_TOOLTIP}
        >
          <RulesBuilder
            datasets={datasets}
            dimensions={availableRulesDimensions}
            disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
            filters={getFiltersFromQueryFilters(query.filters.children)}
            addItem={addQueryFilterAction}
            replaceItem={replaceQueryFilterAction}
            deleteItem={deleteQueryFilterAction}
            maxDate={queryEndDate}
            minDate={queryStartDate}
            canDeleteRules={!isAudienceInsightsReport}
            canAddRules={!isAudienceInsightsReport}
            ruleIdsToHide={ruleIdsToHide}
            fixed={isAudienceInsightsReport}
          />
        </QueryBuilderItem>
        {!isAudienceInsightsReport && (
          <div className={queryBuilderVisibleItemsSegmentedControlClass}>
            <QueryBuilderItem
              label={TOGGLE_VIEW_CHART}
              tooltip={TOGGLE_VIEW_CHART_TOOLTIP}
            >
              <ToggleSwitch
                yesLabel="On"
                noLabel="Off"
                onChange={setChartVisibleAction}
                checked={queryChartVisible}
                name={TOGGLE_VIEW_CHART}
                trackingId="Chart Visible"
                disabled={disabled}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={TOGGLE_VIEW_TABLE}
              tooltip={TOGGLE_VIEW_TABLE_TOOLTIP}
            >
              <ToggleSwitch
                yesLabel="On"
                noLabel="Off"
                onChange={setTableVisibleAction}
                checked={queryTableVisible}
                name={TOGGLE_VIEW_TABLE}
                trackingId="Table Visible"
                disabled={disabled}
              />
            </QueryBuilderItem>
          </div>
        )}
      </footer>
    </div>
  );
};
