import Flex, { Horizontal, Vertical } from 'components/Flex';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import IReport, { ResultBase } from 'domains/reports/types';
import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { getClass, getTestId } from 'helpers/components';
import { trackFiltersChange } from 'helpers/mixpanel';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import React from 'react';
import { connect } from 'react-redux';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import { IOption } from 'types/textValue';
import { getPercentile } from 'helpers/visualization';
import { ChartHelper } from '../../../components/ChartHelper';
import { CAMPAIGN_EXPOSURE_EXPOSURE_TO_CONVERSATION_LATENCY_TOOLTIP_TEXT } from '../../constants/tooltipText';
import { ExposureConversionLatencyChart } from './ExposureConversionLatencyChart';

export interface IExposureConversionLatencyProps {
  targets: IOption[];
  chartData: ResultBase;
  sectionFiltersSelection: IResultSelectionFilters['averageLatency'];
  lastValidReport: IReport | null;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
}

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});

export const exposureConversionLatencyComponentName =
  'exposure-conversion-latency';
const componentSubtitleClass = getClass(
  exposureConversionLatencyComponentName,
  {
    concat: ['subtitle'],
  },
);
const componentSubtitleBoldClass = getClass(
  exposureConversionLatencyComponentName,
  {
    concat: ['subtitle-bold'],
  },
);

const filtersId = getTestId(
  exposureConversionLatencyComponentName,
  'filters-id',
);

const sectionName = 'Ad Exposure to Conversion Latency';

const ExposureConversionLatency: React.FC<IExposureConversionLatencyProps> = ({
  targets,
  chartData,
  sectionFiltersSelection,
  lastValidReport,
  updateResultSelectionFilters,
}) => {
  const [selectedTarget, setSelectedTarget] = React.useState(() =>
    getPersistedTargets(targets, [sectionFiltersSelection?.target ?? 'target']),
  );

  const onTargetChange = (selectedOptions: IOption[]): void => {
    trackFiltersChange({
      lastValidReport,
      actionType: 'CHANGE_TARGET',
      sectionName,
      selection: selectedOptions.map((target) => target.text),
    });

    setSelectedTarget(selectedOptions);

    updateResultSelectionFilters({
      averageLatency: {
        target: selectedOptions[0].value as string,
      },
    });
  };
  const percentileItem = getPercentile(
    chartData.exposed_converted?.AD_LATENCY,
    'AD_LATENCY_50TH_PERCENTILE',
    selectedTarget[0],
  );

  const notConvertedIn25Days = (
    <div className={`${containerClass} ${componentSubtitleClass} pt-0 pb-0`}>
      Less than <span className={componentSubtitleBoldClass}>50%</span> of{' '}
      <span className={componentSubtitleBoldClass}>
        exposed converted households
      </span>{' '}
      converted by <span className={componentSubtitleBoldClass}>day 25</span>.
    </div>
  );

  const convertedIn25Days = (
    <div className={`${containerClass} ${componentSubtitleClass} pt-0 pb-0`}>
      <span className={componentSubtitleBoldClass}>50%</span> of{' '}
      <span className={componentSubtitleBoldClass}>
        exposed converted households
      </span>{' '}
      converted within{' '}
      <span className={componentSubtitleBoldClass}>
        {percentileItem.VALUE} {percentileItem.VALUE === 1 ? 'day' : 'days'}
      </span>{' '}
      of viewing an ad.
    </div>
  );

  return (
    <section className={chartFiltersClass}>
      <Flex vertical={Vertical.between} className={`${containerClass} pb-1`}>
        <Flex vertical={Vertical.top} horizontal={Horizontal.between}>
          <header style={{ minHeight: 54 }}>
            <h3 className={headerClass}>{sectionName}</h3>
          </header>
          <ChartHelper
            tooltip={
              CAMPAIGN_EXPOSURE_EXPOSURE_TO_CONVERSATION_LATENCY_TOOLTIP_TEXT
            }
          />
        </Flex>
      </Flex>
      {percentileItem.percentileIndex === -1
        ? notConvertedIn25Days
        : convertedIn25Days}

      <div className={`${containerClass} pt-0 pb-1`}>
        <ExposureConversionLatencyChart
          chartData={chartData}
          target={selectedTarget}
          percentileIndex={percentileItem.percentileIndex}
        />
      </div>
      <div className={filtersClass} data-testid={filtersId}>
        <MultiSelectDropdown
          options={targets}
          selected={selectedTarget}
          placeholder="Choose audience"
          showLegend
          onChange={onTargetChange}
          minimumChipsSelected={1}
        />
      </div>
    </section>
  );
};

const mapDispatchToProps = {
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
};

export default connect(null, mapDispatchToProps)(ExposureConversionLatency);
