import Button, { Kind } from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import Icon, { Color, Size, Type } from 'components/Icon';
import { getClass } from 'helpers/components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'store/actions/user';
import useLogout from '../../hooks/useLogout';

export const logoutButtonComponentName = 'logout-button';

const USER_MENU_LOGOUT = 'Log Out';

const logoutClasses = getClass(logoutButtonComponentName);

const LogoutButton: FunctionComponent = () => {
  const { logout } = useLogout();
  const handleLogout = (): void => {
    logout();
  };

  return (
    <div className={logoutClasses}>
      <Button onClick={handleLogout} kind={Kind.text}>
        <Flex horizontal={Horizontal.left}>
          <Icon type={Type.logout} size={Size.small} color={Color.dark} />
          <span>{USER_MENU_LOGOUT}</span>
        </Flex>
      </Button>
    </div>
  );
};

export default connect(null, userActions)(LogoutButton);
