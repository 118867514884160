import Flex, { Horizontal } from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Table, { alignments, textOverflows } from 'components/Table';
import { useDatasets } from 'domains/datasets/hooks';
import { DatasetTypes, IDataset } from 'domains/datasets/types';
import { getClass, getComponentUuid, getTestId } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import { kebabCase } from 'lodash';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Actions, getUrl, Index } from 'routes';
import IError from 'types/error';
import { UploadStatus } from 'types/uploads';
import { capitalize } from 'lodash/fp';
import startCase from 'lodash/startCase';
import ButtonDropdown from 'components/ButtonDropdown';
import { DELETE_CROSSWALK_COMBINATION_WARNING_MESSAGE } from '../uploads/components/CrosswalkCombination/locale';

export const datasetsListComponentName = 'datasets-list';
export const datasetsListComponent = getComponentUuid(
  datasetsListComponentName,
);

export const LOCALE_DATASETS_DELETE_USER_DIMENSION_CONFIRMATION_BODY =
  'Deleting the user dimension will delete associated custom values and affect any report or advanced audience that is using this user dimension. Do you want to proceed?';
const LOCALE_DATASETS_LIST_TITLE = 'Uploads';
const LOCALE_DATASETS_LIST_TABLE_FILTER_PLACEHOLDER = 'Search for datasets';
const LOCALE_DATASETS_LIST_TABLE_FILTER_NO_RESULTS_MESSAGE =
  'No Datasets Found';
const LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_1 = 'Dataset name';
const LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_2 = 'Dataset ID';
const LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_3 = 'FileType';
const LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_4 = 'Status';
const LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_5 = 'Actions';
const LOCALE_DATASETS_DELETE_SUCCESS = 'Dataset deleted';
const LOCALE_DATASETS_DELETE_ERROR = 'There has been an error.';
const LOCALE_DATASETS_DELETE_CONFIRMATION_BUTTON_TEXT = 'Yes';
const LOCALE_DATASETS_DELETE_CANCEL_BUTTON_TEXT = 'No';

const DOM_KEY_DATASETS_LIST_HEADER = 'header';

const ADMIN_LIST_PAGE_SIZE = 10;

type ContentProps = {
  datasets: IDataset[];
  testId?: string;
};

const DatasetsList: FunctionComponent<ContentProps> = (props): ReactElement => {
  const { datasets, testId } = props;

  const navigate = useNavigate();
  const { setDatasets } = useDatasets();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete, loading } = useItemAdmin<IDataset>({
    endpoint: getUrl([Index.SEGMENT_DATASETS]),
  });

  const handleUpdateClick = (dataset: IDataset | undefined): void => {
    if (dataset?.status === UploadStatus.completed) {
      const updateUrl = getUrl([Actions.SEGMENT_EDIT, dataset?.id ?? '']);
      navigate(updateUrl);
    }
  };

  const handleDeleteClick = (dataset: IDataset): void => {
    doDelete({
      item: dataset,
      onSuccess: (): void => {
        const updatedDatasetList = datasets.filter(
          (listItem: IDataset) => listItem.id !== dataset.id,
        );
        setDatasets(updatedDatasetList);
        doSuccessToast(LOCALE_DATASETS_DELETE_SUCCESS);
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? LOCALE_DATASETS_DELETE_ERROR);
      },
    });
  };

  const setDeleteConfirmationMessage = (
    datasetType: string,
  ): string | undefined => {
    if (datasetType === DatasetTypes.userDimension) {
      return LOCALE_DATASETS_DELETE_USER_DIMENSION_CONFIRMATION_BODY;
    }
    if (datasetType === DatasetTypes.crosswalkCombination) {
      return DELETE_CROSSWALK_COMBINATION_WARNING_MESSAGE;
    }
    return undefined;
  };

  const NameCell = ({ value }: { value: string }): JSX.Element => (
    <div className="name-cell">{value}</div>
  );

  const datasetsListTestId = getTestId(datasetsListComponentName, testId);
  const datasetsListClass = getClass(datasetsListComponentName);
  const datasetsListHeaderClass = getClass(datasetsListComponentName, {
    concat: [DOM_KEY_DATASETS_LIST_HEADER],
  });

  return (
    <div className={datasetsListClass} data-testid={datasetsListTestId}>
      <header className={datasetsListHeaderClass}>
        <Flex horizontal={Horizontal.between}>
          <H1>{LOCALE_DATASETS_LIST_TITLE}</H1>
          {checkPermissions('datasets::create') && (
            <ButtonDropdown
              options={[
                {
                  text: capitalize(Index.SEGMENT_SEGMENT),
                  handler: () =>
                    navigate(
                      `${Actions.SEGMENT_CREATE}/${Index.SEGMENT_SEGMENT}`,
                    ),
                },
                {
                  text: startCase(
                    Index.SEGMENT_USER_DIMENSION.replace('-', ' '),
                  ),
                  handler: () =>
                    navigate(
                      `${Actions.SEGMENT_CREATE}/${Index.SEGMENT_USER_DIMENSION}`,
                    ),
                },
                {
                  text: startCase(
                    Index.SEGMENT_CROSSWALK_COMBINATION.replace('-', ' '),
                  ),
                  handler: () =>
                    navigate(
                      `${Actions.SEGMENT_CREATE}/${Index.SEGMENT_CROSSWALK_COMBINATION}`,
                    ),
                },
              ]}
            />
          )}
        </Flex>
      </header>
      <section>
        <Table<IDataset>
          filter
          initialState={{ sortBy: [{ id: 'dataset_id' }] }}
          filterPlaceholder={LOCALE_DATASETS_LIST_TABLE_FILTER_PLACEHOLDER}
          onRowClick={(itemId?: string) => {
            const dataset = datasets.find((d) => d.id === itemId);
            handleUpdateClick(dataset);
          }}
          noResultsMessage={
            LOCALE_DATASETS_LIST_TABLE_FILTER_NO_RESULTS_MESSAGE
          }
          textOverflow={textOverflows.ellipsis}
          editItemSegment={Actions.SEGMENT_EDIT}
          alignment={[
            alignments.left,
            alignments.left,
            alignments.left,
            alignments.left,
            alignments.right,
          ]}
          columns={[
            {
              Header: LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_1,
              accessor: 'display_name',
              width: '45%',
              Cell: NameCell,
            },
            {
              Header: LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_2,
              accessor: 'dataset_id',
              width: '20%',
            },
            {
              Header: LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_3,
              accessor: 'type',
              width: '15%',
            },
            {
              Header: LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_4,
              accessor: 'status',
              width: '10%',
              Cell: function renderStatusLabel({
                row: { original: dataset },
              }: CellProps<IDataset>): JSX.Element {
                const status =
                  dataset.status === UploadStatus.validated
                    ? UploadStatus.inProgress
                    : dataset.status;
                return (
                  <div className="status-label">
                    <span className={kebabCase(status)}>{status}</span>
                  </div>
                );
              },
            },
            {
              Header: LOCALE_DATASETS_LIST_TABLE_HEADER_COLUMN_5,
              id: 'Actions',
              width: '10%',
              Cell: function DatasetListTableActionsCell({
                row: { original: dataset },
              }: CellProps<IDataset>): JSX.Element {
                return (
                  <ListActions<IDataset>
                    loading={loading}
                    item={dataset}
                    canUpdate={
                      checkPermissions('datasets::update') &&
                      dataset.status === UploadStatus.completed
                    }
                    canDelete={
                      checkPermissions('datasets::delete') &&
                      dataset.status !== UploadStatus.inProgress
                    }
                    onDeleteClick={(): void => handleDeleteClick(dataset)}
                    onUpdateClick={(): void => handleUpdateClick(dataset)}
                    testId={datasetsListTestId}
                    id={dataset.id}
                    deleteConfirmationMessage={setDeleteConfirmationMessage(
                      dataset.type,
                    )}
                    confirmButtonText={
                      dataset.type === DatasetTypes.userDimension ||
                      dataset.type === DatasetTypes.crosswalkCombination
                        ? LOCALE_DATASETS_DELETE_CONFIRMATION_BUTTON_TEXT
                        : undefined
                    }
                    cancelButtonText={
                      dataset.type === DatasetTypes.userDimension ||
                      dataset.type === DatasetTypes.crosswalkCombination
                        ? LOCALE_DATASETS_DELETE_CANCEL_BUTTON_TEXT
                        : undefined
                    }
                  />
                );
              },
            },
          ]}
          data={datasets}
          pageSize={ADMIN_LIST_PAGE_SIZE}
        />
      </section>
    </div>
  );
};

export default DatasetsList;
