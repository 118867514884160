import { IDomainsDateRanges } from 'domains/reports/types';
import { get, map } from 'lodash';
import { RuleFilter } from 'types/filter';
import { isBefore, isAfter } from 'date-fns';
import { INITIAL_REPORTS_DATE } from 'domains/reports/constants';
import { getToday } from 'domains/reports/adapters/date';

export const verifyAndGetFilterRules = (
  existingFilters: RuleFilter[],
  index: number,
): Record<string, unknown>[] => {
  if (!existingFilters.length || existingFilters.length - 1 < 0) return [];

  const previousFilters = existingFilters.filter((f, i) => i < index);

  const currentFilter = existingFilters[index];

  const filterValues = previousFilters
    .filter((pf) => pf && pf !== null)
    .map((previousFilter) => {
      const { field, id, operator, value = [] } = previousFilter;
      if (!field) return {};
      return {
        field: field ?? currentFilter.field,
        id: id ?? currentFilter.id,
        type: 'SIMPLE',
        operator: operator ?? currentFilter.operator,
        value: map(
          value,
          (val: { text: string; value?: string }) => val.value ?? val,
        ),
      };
    });

  return filterValues.filter((fv) => fv);
};

export const getCompoundRuleMinDate = (
  dateRanges: IDomainsDateRanges,
  isPIQField: boolean,
): string => {
  if (!isPIQField) return INITIAL_REPORTS_DATE;
  return dateRanges.MODE_PLACEIQ.min_date;
};
export const getCompoundRuleMaxDate = (
  dateRanges: IDomainsDateRanges,
  isPIQField: boolean,
): string => {
  if (!isPIQField) return getToday();
  return dateRanges.MODE_PLACEIQ.max_date;
};

export const getCompoundRuleDate = (
  mainFilter: RuleFilter,
  dateRanges: IDomainsDateRanges,
  queryDate = '',
  datePath: 'start' | 'end',
  isPIQField: boolean,
): string => {
  const dateFilter = mainFilter?.children?.[0];
  const date = get(dateFilter, datePath, queryDate);
  if (!isPIQField) return date;
  const minPIQLimit = getCompoundRuleMinDate(dateRanges, true);
  const maxPIQLimit = getCompoundRuleMaxDate(dateRanges, true);
  if (isBefore(new Date(date), new Date(minPIQLimit))) return minPIQLimit;
  if (isAfter(new Date(date), new Date(maxPIQLimit))) return maxPIQLimit;
  return date;
};
