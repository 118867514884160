import { getTestId, getClass } from 'helpers/components';
import React, { forwardRef, PropsWithChildren } from 'react';
import { Placement, Style, Type } from '..';

export const stickerWrapperComponentName = 'sticker-wrapper';

interface IStickerWrapperProps extends PropsWithChildren {
  testId?: string;
  tooltipProps: {
    'data-popper-interactive': boolean | undefined;
    style: React.CSSProperties;
    'data-popper-placement'?: Placement;
  };

  visible: boolean;
  color?: string;
  showArrow: boolean;
  style: Style;
  type: Type;
  customStickerClass: string;
}

export const StickerWrapper = forwardRef<HTMLDivElement, IStickerWrapperProps>(
  (props, ref) => {
    const { testId, tooltipProps, visible, color, children, showArrow } = props;

    const stikerTestID = getTestId(stickerWrapperComponentName, testId);

    return visible ? (
      <div
        data-testid={stikerTestID}
        ref={ref}
        onWheel={(event) => event.stopPropagation()}
        id="scrollingSticker"
        {...tooltipProps}
      >
        {children}
      </div>
    ) : null;
  },
);
