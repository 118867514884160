import { IBusinessData } from 'domains/reports/types';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as messageListActions from 'store/actions/messageList';
import * as reportActions from 'store/actions/report';
import * as messageListSelectors from 'store/selectors/messageList';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as reportSelectors from 'store/selectors/report';
import * as toolbarSelectors from 'store/selectors/toolbar';
import * as domainSelectors from 'store/selectors/domains';
import State from 'types/state';
import { TargetBuilder } from './TargetBuilder.component';
import { IOwnProps } from './types';

const mapStateToProps = (
  state: State,
): Pick<
  IOwnProps,
  | 'availableRulesDimensions'
  | 'businessData'
  | 'dimensions'
  | 'report'
  | 'toolbar'
  | 'messageList'
  | 'isReportInvalid'
  | 'includeTargetBreakdown'
  | 'domainsDateRanges'
> => ({
  availableRulesDimensions:
    businessDataSelectors.getAvailableRulesDimensions(state),
  businessData: get('businessData', state) as IBusinessData,
  dimensions: reportSelectors.getDimensions(state),
  report: reportSelectors.getReport(state),
  toolbar: toolbarSelectors.getToolbar(state),
  messageList: messageListSelectors.getMessageList(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  includeTargetBreakdown: reportSelectors.getIncludeTargetBreakdown(state),
  domainsDateRanges: domainSelectors.getDomainsDateRanges(state),
});

const mapDispatchToProps: Pick<
  IOwnProps,
  | 'setQueryModeAction'
  | 'setReportQueryDatasets'
  | 'setReportQueryDatasetsAction'
  | 'setReportQueryFilterEndDate'
  | 'setReportQueryFilterStartDate'
  | 'setReportQueryFilterRelativeDateOffset'
  | 'addQueryFilterAction'
  | 'replaceQueryFilterAction'
  | 'deleteQueryFilterAction'
  | 'addWarningMessage'
  | 'deleteWarningMessage'
  | 'setQueryIncludeTargetBreakdown'
> = {
  setQueryModeAction: reportActions.setQueryModeAction,
  setReportQueryDatasets: reportActions.setReportQueryDatasets,
  setReportQueryDatasetsAction: reportActions.setReportQueryDatasetsAction,
  setReportQueryFilterEndDate: reportActions.setReportQueryFilterEndDate,
  setReportQueryFilterStartDate: reportActions.setReportQueryFilterStartDate,
  setReportQueryFilterRelativeDateOffset:
    reportActions.setReportQueryFilterRelativeDateOffset,
  addQueryFilterAction: reportActions.addQueryFilterAction,
  replaceQueryFilterAction: reportActions.replaceQueryFilterAction,
  deleteQueryFilterAction: reportActions.deleteQueryFilterAction,
  addWarningMessage: messageListActions.addMessage,
  deleteWarningMessage: messageListActions.deleteMessage,
  setQueryIncludeTargetBreakdown: reportActions.setQueryIncludeTargetBreakdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetBuilder);
