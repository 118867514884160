import Flex from 'components/Flex';
import H1 from 'components/H1';
import Loading from 'components/Loading';
import ListTable from 'components/ReportsList';
import IReport, { reportSubType, reportType } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions, getUrl, Index } from 'routes';
import * as rootActions from 'store/actions/root';
import * as userSelectors from 'store/selectors/user';
import State from 'types/state';
import ButtonDropdown from 'components/ButtonDropdown';

export const listComponentName = 'reports-list';

const LOCALE_REPORT_SEARCH_PLACEHOLDER = 'Search for report';

type ContentProps = {
  handleCloneReport: (
    payload: IReport,
    action: Actions.SEGMENT_COPY | Actions.SEGMENT_CLONE,
  ) => void;
  handleDeleteReport: (arg0: IReport) => void;
  createReport: (arg0: string) => void;
  deleteReport: (arg0: IReport) => void;
  editReport: (arg0: IReport) => void;
  loading: boolean;
  testId?: string;
  title: string;
  userId: string;
  path?: string;
};

const List: FunctionComponent<ContentProps> = (props): ReactElement => {
  const {
    createReport,
    handleCloneReport,
    handleDeleteReport,
    editReport,
    loading: loadingData,
    testId,
    title,
    userId,
  } = props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doErrorToast } = useToast();
  const { loading: loadingUpdate } = useItemAdmin<IReport>({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
  });

  const loaded = !loadingData && !loadingUpdate;

  const gotoCreate = (subType: string): void => {
    const createUrl = getUrl([Actions.SEGMENT_CREATE, subType]);
    createReport(reportType.attributionReport);
    navigate(createUrl);
  };

  const createNewReportOptions = [
    ...(checkPermissions('attribution_reports.tv_tune_in.module::view')
      ? [
          {
            text: reportSubType.tvTuneIn.name,
            handler: () => gotoCreate(reportSubType.tvTuneIn.segment),
          },
        ]
      : []),
    ...(checkPermissions('attribution_reports.outcome.module::view')
      ? [
          {
            text: reportSubType.outcome.name,
            handler: () => gotoCreate(reportSubType.outcome.segment),
          },
        ]
      : []),
  ];

  const handleUpdateClick = (report: IReport): void => {
    const updateUrl = getUrl([Actions.SEGMENT_EDIT, report.id || '']);
    editReport({ ...report, type: reportType.attributionReport });
    navigate(updateUrl);
  };

  const handleDeleteClick = (report: IReport): void => {
    handleDeleteReport(report);
  };

  const handleCloneClick = (report: IReport): void => {
    if (!userId) {
      doErrorToast('Cannot get currentUser');
      return;
    }
    handleCloneReport(
      { ...report, createdBy: userId, type: reportType.attributionReport },
      Actions.SEGMENT_CLONE,
    );
  };

  const listTestId = getTestId(listComponentName, testId);
  const listClass = getClass(listComponentName);

  return (
    <div className={listClass} data-testid={listTestId}>
      <header className={`${listClass}-header`}>
        <Flex>
          <H1>{title}</H1>
          {checkPermissions('reports::create') && (
            <div className={`${listClass}-dropdown-wrapper`}>
              <ButtonDropdown options={createNewReportOptions} />
            </div>
          )}
        </Flex>
      </header>
      <section>
        {loaded ? (
          <ListTable
            onUpdateClick={handleUpdateClick}
            onDeleteClick={handleDeleteClick}
            onCloneClick={handleCloneClick}
            loading={!loaded}
            placeholder={LOCALE_REPORT_SEARCH_PLACEHOLDER}
            type="attribution-report"
          />
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state: State): { userId: string } => ({
  userId: userSelectors.getId(state),
});

const mapDispatchToProps = {
  handleCloneReport: rootActions.handleCloneReport,
  handleDeleteReport: rootActions.handleDeleteReport,
  createReport: rootActions.createReport,
  deleteReport: rootActions.deleteReport,
  editReport: rootActions.editReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
